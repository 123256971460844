import { useSelector } from "react-redux";
import React from "react";
import PropTypes from "prop-types";
import RenderToggle from "./RenderToggle";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const PaperlessToggle = ({ account, userProfile }) => {
  const isEditableSession = useSelector(state =>
    sessionSelector.policyholderHasWriteAccess(state)
  );
  return (
    <div className="toggle-group">
      <RenderToggle
        account={account.paperless_bill.eligibility}
        toggle={account.paperless_bill.option}
        toggleType="paperless_bill"
        heading="Paperless Billing"
        number={account.number}
        disabled={!isEditableSession}
        userProfile={userProfile}
      />
      <RenderToggle
        account={account.bill_email_alert.eligibility}
        toggle={account.bill_email_alert.option}
        toggleType="bill_email_alert"
        heading="Payment Email Alerts"
        number={account.number}
        disabled={!isEditableSession}
        userProfile={userProfile}
      />
      <RenderToggle
        account={account.policy_email_alert.eligibility}
        toggle={account.policy_email_alert.option}
        toggleType="policy_email_alert"
        heading="Policy Email Alerts"
        number={account.number}
        disabled={!isEditableSession}
      />
    </div>
  );
};

PaperlessToggle.propTypes = {
  account: PropTypes.object.isRequired,
  userProfile: PropTypes.object
};

export default PaperlessToggle;
