import { connect } from "react-redux";
import PropTypes from "prop-types";
import AdSlider from "../components/AdSlider";
import { images } from "../imageSourceConstants";

const propTypes = {
  delay: PropTypes.number.isRequired
};

const mapStateToProps = ownProps => {
  return {
    delay: ownProps.delay || 0,
    ads: images
  };
};

const AdSliderContainer = connect(mapStateToProps)(AdSlider);
AdSliderContainer.propTypes = propTypes;

export default AdSliderContainer;
