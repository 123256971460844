import React from "react";
import { SidebarNav } from "../ui-kit";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";

const links = [
  {
    to: routes.MY_PROFILE_ROUTE,
    activeClassName: "nav-menu--active",
    text: "My Profile",
    iconClass: "fa fa-caret-right"
  },
  {
    to: routes.BILLING_AND_ALERTS_ROUTE,
    activeClassName: "nav-menu--active",
    text: Translations.payments_alerts.paperless_billing.title,
    iconClass: "fa fa-caret-right"
  },
  {
    to: routes.MY_PROFILE_ADD_ACCOUNT_ROUTE,
    activeClassName: "nav-menu--active",
    text: Translations.add_account.heading,
    iconClass: "fa fa-caret-right"
  }
];

const ProfileNav = () => {
  return <SidebarNav links={links} header="My Profile" />;
};
export default ProfileNav;
