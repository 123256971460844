import React, { useEffect, useMemo, useState, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import uniqBy from "lodash.uniqby";
import PolicyBalanceRemaining from "../components/billing-activity/PolicyBalanceRemaining";
import LoadingIndicator from "../components/loading-indicator/LoadingIndicator";
import { fetchPolicyBalanceRemainingDetails } from "../actions/policyBalanceRemainingActions";
import { fetchBillingActivity } from "../actions/billingActivityActions";
import DocumentLink from "../components/account-summary/DocumentLink";
import * as routes from "../constants/routes";
import { Translations } from "../components/translations";
import { AnalyticCategories, AnalyticActions } from "../constants/analytics";
import { logger } from "../logging";
import { DirectBillEmailLink } from "../components/email";
import {
  ContentHeader,
  SelectNonForm,
  FlexRow,
  Alert
} from "@ufginsurance/ui-kit";
import SmartPayLink from "../components/SmartPayLink";
import { isPayGo, getPaymentType } from "../utils";

const PolicyBalanceRemainingContainer = ({
  billingActivityData,
  policyBalanceRemainingData,
  fetchPolicyBalanceRemainingDetails,
  fetchBillingActivity,
  match,
  accountsList
}) => {
  const accountData = useMemo(() => {
    return policyBalanceRemainingData?.accountData;
  }, [policyBalanceRemainingData?.accountData]);

  const accounts = useMemo(() => {
    return billingActivityData?.accounts ?? [];
  }, [billingActivityData?.accounts]);

  const accountList = useMemo(() => {
    return accounts.map(account => account.number);
  }, [accounts]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [selectedPolicy, setSelectedPolicy] = useState("");
  const [paymentType, setPaymentType] = useState("");

  const policyOptions = useMemo(() => {
    const filteredAccounts =
      selectedAccount && accounts
        ? accounts.filter(
            a => a.number === selectedAccount && a.has_direct_bill_policies
          ) ?? []
        : [];

    if (filteredAccounts.length === 0) return [];
    const policyOptions = filteredAccounts[0].policies
      .filter(
        policy =>
          policy.section_group.includes("direct_bill_by_account") ||
          policy.section_group.includes("direct_bill_by_policy")
      )
      .map(policy => {
        if (!policy.nickname && policy.type === "unknown") {
          return { value: policy.number, label: policy.number };
        }

        const policyType =
          Translations.policy_types[policy.type] ?? policy.type;
        return {
          value: policy.number,
          label: `${policyType} - ${policy.number}${
            !policy?.nickname ? "" : ` - ${policy.nickname}`
          }`
        };
      });

    return uniqBy(orderBy(policyOptions, "value", "desc"), "value");
  }, [accounts, selectedAccount]);

  const trackAnalytics = details => {
    logger.event({
      category: AnalyticCategories.account,
      action: AnalyticActions.account.fetchPolicyBalanceRemaining,
      label: `Account: ${details}`
    });
  };

  const setActiveAccount = useCallback(
    account => {
      setSelectedAccount(account);
      setSelectedPolicy("");
      fetchPolicyBalanceRemainingDetails(account);
    },
    [fetchPolicyBalanceRemainingDetails]
  );

  const handleAccountChange = ({ value }) => {
    trackAnalytics(value);
    setActiveAccount(value);
    const tmpPaymentType = getPaymentType(billingActivityData, value);
    setPaymentType(tmpPaymentType);
  };

  const handlePolicy = ({ value }) => {
    setSelectedPolicy(value);
  };

  const renderAccount = () => {
    if (
      (!!selectedAccount && (!accountData || !accountData[selectedAccount])) ||
      policyBalanceRemainingData.error
    ) {
      return (
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.policy_balance_remaining_no_accounts,
            <DirectBillEmailLink />
          )}
        </div>
      );
    }

    if (
      selectedAccount &&
      accountData &&
      accountData[selectedAccount] &&
      accountData[selectedAccount].length === 0
    ) {
      return (
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.policy_balance_remaining_no_accounts,
            <DirectBillEmailLink />
          )}
        </div>
      );
    }

    if (
      accountData &&
      selectedAccount &&
      accountData[selectedAccount] &&
      accountData[selectedAccount].length > 0 &&
      !isPayGo(paymentType)
    ) {
      const currentAccount =
        accountsList && Array.isArray(accountsList.accounts)
          ? accountsList.accounts?.find(o => o.number === selectedAccount)
          : undefined;
      const is_service_center =
        currentAccount && currentAccount.is_service_center;
      return (
        <PolicyBalanceRemaining
          origin={accountData[selectedAccount][0].origin}
          is_service_center={!!is_service_center}
          policies={accountData[selectedAccount]}
          selectedPolicy={selectedPolicy}
        />
      );
    }

    return null;
  };

  const renderSection = () => {
    if (accountList.length === 1 && !accountData) {
      return null;
    }

    const hasPoliciesInFinalAudit =
      selectedAccount &&
      Array.isArray(accountData[selectedAccount]) &&
      accountData[selectedAccount].find(_ => _.final_audit === true);

    const accountOptions =
      accountList
        ?.map(account => ({
          value: account,
          label: account
        }))
        ?.sort((a, b) => b.value - a.value) ?? [];

    return (
      <div className="policy-balance-remaining__options">
        <div className="policy-balance-remaining__drop-downs">
          <FlexRow>
            <SelectNonForm
              id="accountNumber"
              name="accountNumber"
              label="Select Account"
              placeholder="Select Account"
              options={accountOptions}
              onChange={handleAccountChange}
              value={selectedAccount}
              isClearable={false}
              size="md"
            />
            <SelectNonForm
              id="policyNumber"
              name="policyNumber"
              label="Select Policy"
              placeholder="Select Policy"
              options={policyOptions}
              onChange={handlePolicy}
              value={
                policyOptions?.length === 1
                  ? policyOptions[0]?.value || ""
                  : selectedPolicy || ""
              }
              isClearable={false}
              disabled={!selectedAccount || policyOptions?.length === 1}
              size="lg"
            />
            {selectedAccount &&
              !hasPoliciesInFinalAudit &&
              policyOptions.length > 0 &&
              !policyBalanceRemainingData.error && (
                <div className="policy-balance-remaining__print align-right">
                  <DocumentLink
                    isPostRequest
                    className="installment-schedule__print-button"
                    color="primary"
                    pdfUri={`${window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI}${routes.POLICY_BALANCE_REMAINING_ROUTE_PDF}/${selectedAccount}`}
                    postPayload={
                      policyBalanceRemainingData.accountData[selectedAccount]
                    }
                    filename="Billing_Statement.pdf"
                  >
                    <span>PRINT</span>
                  </DocumentLink>
                </div>
              )}
          </FlexRow>
          {isPayGo(paymentType) && (
            <Alert dismissible={false} className="wc-smart-pay-alert">
              This Workers&apos; Compensation Policy is managed by SmartPay
              <SmartPayLink />
            </Alert>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (match?.params?.account) {
      setSelectedAccount(match.params.account);
    }

    setIsLoading(true);
    fetchBillingActivity().then(()=>{
      setIsLoading(false);
    });
  }, [fetchBillingActivity, match.params.account]);

  useEffect(() => {
    if (accountList && accountList.length === 1) {
      setSelectedAccount(accountList[0]);
      setActiveAccount(accountList[0]);
    }
  }, [accountList, setActiveAccount]);

  if (isLoading || billingActivityData.isLoading || policyBalanceRemainingData.isLoading) {
    return <LoadingIndicator />;
  }

  if (billingActivityData?.error?.response?.status === 404) {
    return (
      <div className="policy-balance-remaining">
        <h1>{Translations.billing_activity.policy_balance_remaining}</h1>
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.error_messages[308]
          )}
        </div>
      </div>
    );
  }

  if (accountList && accountList.length === 0) {
    return (
      <div className="policy-balance-remaining">
        <h1>{Translations.billing_activity.policy_balance_remaining}</h1>
        <div className="policy-balance-remaining__no-accounts">
          {Translations.formatString(
            Translations.billing_activity.policy_balance_remaining_no_accounts,
            <DirectBillEmailLink />
          )}
        </div>
      </div>
    );
  }

  return accounts ? (
    <div>
      <ContentHeader>
        {Translations.billing_activity.policy_balance_remaining}
      </ContentHeader>
      <div className="policy-balance-remaining">{renderSection()}</div>
      {renderAccount()}
    </div>
  ) : (
    <LoadingIndicator />
  );
};

PolicyBalanceRemainingContainer.propTypes = {
  billingActivityData: PropTypes.object,
  policyBalanceRemainingData: PropTypes.object,
  fetchPolicyBalanceRemainingDetails: PropTypes.func.isRequired,
  fetchBillingActivity: PropTypes.func.isRequired,
  match: PropTypes.object,
  accountsList: PropTypes.object
};

const mapStateToProps = state => ({
  billingActivityData: state.billingActivityData,
  policyBalanceRemainingData: state.policyBalanceRemainingData,
  accountsList: state.accountData
});

const mapDispatchToProps = {
  fetchBillingActivity,
  fetchPolicyBalanceRemainingDetails
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PolicyBalanceRemainingContainer);
