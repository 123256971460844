import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { formatDate, ButtonBar, Button } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import { AnalyticCategories } from "../../constants/analytics";
import { logger } from "../../logging";
import BillStatement from "./BillStatement.js";
import Currency from "../Currency";
import { selectAccountToEnroll } from "../../actions/automaticPaymentsAction";
import {
  isPayGo,
  getCurrentAutoPayAccount,
  getIsAutoPayEligible,
  getCanEditAutopay
} from "../../utils";
import AutopayModal from "../AutopayModal.js";

const StatementSummary = ({
  policy,
  account,
  selectAccountToEnroll,
  isLegacyInPendingCancel
}) => {
  const [showAutopayModal, setShowAutopayModal] = useState(false);

  const autopayData = useSelector(state => state.automaticPaymentsData);

  const handleCloseAutopayModal = () => {
    setShowAutopayModal(false);
  };

  const isPolicyBill =
    (!account?.billing && account?.policies?.length > 0) ||
    account?.policies?.some(_ => _.section_group === "direct_bill_by_policy");

  const billing = isPolicyBill ? policy?.billing : account.billing;

  const trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.account,
      action: "Pay bill Clicked"
    });
  };

  const hasLastPayment =
    (policy?.billing?.last_payment_amount || 0) > 0 &&
    !!policy?.billing?.last_payment_date;

  const canEnrollInAutoPay = getIsAutoPayEligible(
    getCurrentAutoPayAccount(autopayData, account)
  );

  const canEditAutopay = getCanEditAutopay(
    getCurrentAutoPayAccount(autopayData, account)
  );

  const handleEnrollInAutoPay = () => {
    selectAccountToEnroll({
      account: getCurrentAutoPayAccount(autopayData, account),
      isNewEnrollment: true
    });
    setShowAutopayModal(true);
  };

  const openSmartPayUrl = event => {
    event?.stopPropagation();
    trackAnalytics();
    window.open(
      window.location.hostname === "customers.ufginsurance.com"
        ? `https://www.smartpayllc.com/`
        : `https://qa.smartpayllc.com/`,
      "_blank"
    );
  };

  const openPaymentUrl = event => {
    event?.stopPropagation();
    trackAnalytics();
    window.location = `${
      billing?.online_payment?.payment_link
    }&CancellationUrl=${encodeURIComponent(window.location.href)}`;
  };

  const isPaymentPastDue = billing?.due_date && billing?.is_past_due;

  return (
    <>
      {billing?.final_audit ? (
        <em>
          {account?.is_service_center
            ? "We are currently completing an audit on your account. Up-to-date billing information will be available following the audit. If you have any questions, please contact UFG Service Center at 888-793-1481."
            : "We are currently completing an audit on your account. Up-to-date billing information will be available following the audit. If you have any questions, please contact your UFG agent."}
        </em>
      ) : isPayGo(billing?.payment_type) ? (
        <div className="paygo-notice">
          <p>This policy is managed by SmartPay.</p>
          <ButtonBar fullWidth>
            <Button
              variant="primary"
              onClick={openSmartPayUrl}
              data-js-id="smartpay-button"
            >
              SmartPay Login{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 512 512"
              >
                <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
              </svg>
            </Button>
          </ButtonBar>
        </div>
      ) : (
        <>
          <p className="panel-row">
            <span>Account Balance</span>
            <span>
              <Currency quantity={billing?.balance} currency="USD" />
            </span>
          </p>
          <p className="panel-row">
            <span>Minimum Payment Due</span>
            <span className={isPaymentPastDue ? "ufg-alert" : ""}>
              <Currency
                quantity={
                  billing?.account_minimum_due_with_fees || billing?.minimum_due
                }
              />
            </span>
          </p>
          <p className="detail">The amount due includes any applicable fees</p>
          <p className="panel-row">
            <span>Due Date</span>
            <span className={isPaymentPastDue ? "ufg-alert" : ""}>
              {billing?.due_date ? (
                formatDate(billing?.due_date, "MM/DD/YYYY")
              ) : (
                <span>N/A</span>
              )}
            </span>
          </p>
          {account?.origin === "ufg_core" && hasLastPayment && (
            <p className="detail">
              {Translations.formatString(
                "Last payment {0} received {1}",
                <Currency quantity={policy?.billing?.last_payment_amount} />,
                formatDate(policy?.billing?.last_payment_date, "MM/DD/YYYY")
              )}
            </p>
          )}
          <div className="payment-button-row">
            {billing?.last_statement_link && (
              <BillStatement billStatementLink={billing?.last_statement_link} />
            )}
            {!isLegacyInPendingCancel && canEnrollInAutoPay && (
              <Button variant="outline" onClick={handleEnrollInAutoPay}>
                Enroll in Autopay
              </Button>
            )}
            {!isLegacyInPendingCancel && canEditAutopay && (
              <Button variant="outline" onClick={handleEnrollInAutoPay}>
                Edit Autopay
              </Button>
            )}
            {billing?.online_payment && (
              <Button
                variant="primary"
                onClick={openPaymentUrl}
                data-js-id="pay-button"
              >
                Pay
              </Button>
            )}
          </div>
        </>
      )}
      {showAutopayModal && (
        <AutopayModal
          show={showAutopayModal}
          closeHandler={handleCloseAutopayModal}
          isEditing={canEditAutopay}
          isEnrolling={canEnrollInAutoPay}
          minimumDue={
            billing?.account_minimum_due_with_fees || billing?.minimum_due
          }
          accountNumber={account?.number}
        />
      )}
    </>
  );
};

StatementSummary.propTypes = {
  policy: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  selectAccountToEnroll: PropTypes.func.isRequired,
  isLegacyInPendingCancel: PropTypes.bool.isRequired
};

const mapDispatchToProps = {
  selectAccountToEnroll
};

export default withRouter(connect(null, mapDispatchToProps)(StatementSummary));
