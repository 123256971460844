import * as policyholderService from "../services/policyholderService";
import DocumentService from "../services/documentService";
import { LOADING_DOCUMENT_ROUTE } from "../constants/routes";
import * as types from "../constants/actionTypes";
import { rehydrateSession } from "@ufginsurance/sso-oidc-client-react/lib/actions/ssoActions";

export const fetchGuidewireConversionsStarted = () => ({
  type: types.FETCH_GW_CONVERSIONS_STARTED
});

export const fetchGuidewireConversionsSucceeded = data => ({
  type: types.FETCH_GW_CONVERSIONS_SUCCEEDED,
  payload: data
});

export const fetchGuidewireConversionsFailed = error => ({
  type: types.FETCH_GW_CONVERSIONS_FAILED,
  payload: error,
  error: true
});

export const fetchAccountDataStarted = () => ({
  type: types.FETCH_ACCOUNT_DATA_STARTED
});

export const fetchAccountDataSucceeded = data => ({
  type: types.FETCH_ACCOUNT_DATA_SUCCEEDED,
  payload: data
});

export const fetchAccountDataFailed = error => ({
  type: types.FETCH_ACCOUNT_DATA_FAILED,
  payload: error,
  error: true
});

export const fetchCancellationNoticeLoading = () => ({
  type: types.FETCH_CANCELLATION_NOTICE_LOADING
});

export const fetchCancellationNoticeSuccess = data => ({
  type: types.FETCH_CANCELLATION_NOTICE_SUCCESS,
  payload: data
});

export const fetchCancellationNoticeFailed = error => ({
  type: types.FETCH_CANCELLATION_NOTICE_FAILED,
  payload: error,
  error: true
});
export const postGWConfirmationStarted = () => ({
  type: types.POST_GW_CONFIRMATION_STARTED
});

export const postGWConfirmationSucceeded = data => ({
  type: types.POST_GW_CONFIRMATION_SUCCEEDED,
  payload: data
});

export const postGWConfirmationFailed = error => ({
  type: types.POST_GW_CONFIRMATION_FAILED,
  payload: error,
  error: true
});

// Async action creator using thunk middleware
export const fetchAccountData = shouldRevalidate => dispatch => {
  dispatch(fetchAccountDataStarted());

  return policyholderService.getAccountSummary(shouldRevalidate).then(
    response => {
      dispatch(fetchAccountDataSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchAccountDataFailed(error));
    }
  );
};

export const fetchCancellationNotice = policyNumber => dispatch => {
  dispatch(fetchCancellationNoticeLoading());
  const documentRenderer = new DocumentService();
  documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

  return policyholderService.fetchCancellationNotice(policyNumber).then(
    response => {
      documentRenderer.saveOrOpenDocument(response.data, "cancellationNotice");
      dispatch(fetchCancellationNoticeSuccess(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      documentRenderer.clearRenderer();
      dispatch(fetchCancellationNoticeFailed(error));
    }
  );
};

export const fetchGuidewireConversions = () => dispatch => {
  dispatch(fetchGuidewireConversionsStarted());
  return policyholderService.fetchGuidewireConversions().then(
    response => {
      dispatch(fetchGuidewireConversionsSucceeded(response.data));
    },
    error => {
      dispatch(fetchGuidewireConversionsFailed(error));
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
    }
  );
};

export const postGWConfirmation = () => dispatch => {
  dispatch(postGWConfirmationStarted());
  return policyholderService.postGWConfirmation().then(
    response => {
      dispatch(postGWConfirmationSucceeded(response.data));
      const returnPath = "/account?accountAddedSuccess=true";
      rehydrateSession(returnPath)(dispatch);
    },
    error => {
      dispatch(postGWConfirmationFailed(error));
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
    }
  );
};

export const hydrateAddedLinkedAccounts = accounts => dispatch => {
  dispatch(() => ({
    type: types.HYDRATE_ADDED_LINKED_ACCOUNTS
  }));
  const accountsArray = accounts
    .map(account => {
      return account.account_number;
    })
    .join(",");
  rehydrateSession(encodeURI(`/account?accountsLinked=${accountsArray}`))(
    dispatch
  );
};
