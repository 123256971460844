import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Panel, Button } from "@ufginsurance/ui-kit";
import AutomaticPaymentsPolicy from "./AutomaticPaymentsPolicy";
import {
  isSignedUpForAutomaticPayment,
  isPaymentTypeKnown,
  getCurrentAutoPayAccount
} from "../../utils";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import AutopayModal from "../AutopayModal";
import { logger } from "../../logging";
import { AnalyticCategories } from "../../constants/analytics";
import { selectAccountToEnroll } from "../../actions/automaticPaymentsAction";

const AutomaticPaymentsAccount = ({ account, selectAccountToEnroll }) => {
  const [showAutopayModal, setShowAutopayModal] = useState(false);

  const handleCloseAutopayModal = () => {
    setShowAutopayModal(false);
  };

  const isEditableSession = useSelector(state =>
    sessionSelector.policyholderHasWriteAccess(state)
  );

  const autopayData = useSelector(state => state?.automaticPaymentsData) || {};
  const accountData = useSelector(state => state?.accountData) || {};

  const getBilling = () => {
    const { accounts, error, isLoading } = accountData;
    if (error !== null || isLoading) return;
    if (!accounts?.length || !account) return;
    const activeAccount = accounts?.filter(
      _ => _.number === account?.number
    )[0];
    const isPolicyBill =
      (!activeAccount?.billing && activeAccount?.policies?.length > 0) ||
      activeAccount?.policies?.some(
        _ => _.section_group === "direct_bill_by_policy"
      );
    return isPolicyBill
      ? activeAccount?.policies[0]?.billing
      : activeAccount?.billing;
  };

  const canRenderAccountInfo =
    !account?.auto_enroll_held && !!account?.automatic_payment;

  const trackAnalytics = action => () => {
    logger.event({
      category: AnalyticCategories.account,
      action
    });
  };

  const clickHandler = isEdit => {
    trackAnalytics(
      canEditAutopay ? "Edit Auto Pay clicked" : "Enroll Auto Pay clicked",
      { account, isNewEnrollment: !isEdit }
    );
    selectAccountToEnroll({
      account: getCurrentAutoPayAccount(autopayData, account),
      isNewEnrollment: !isEdit
    });
    setShowAutopayModal(true);
  };

  const isDisabled = !!account?.auto_enroll_held || !isEditableSession;
  const canEditAutopay = isSignedUpForAutomaticPayment(
    account?.automatic_payment?.payment_type
  );
  const canEnrollInAutoPay = !isDisabled && !canEditAutopay;
  const minimumDue =
    getBilling()?.account_minimum_due_with_fees || getBilling()?.minimum_due;
  return (
    <Panel
      id={`autopay-${account?.number}`}
      isOpen={false}
      title={
        <div className="autopay-account">
          <div>
            <p className="autopay-account-title">{`Account #${account?.number}`}</p>
            <div className="autopay-account-enrollment-summary">
              {canRenderAccountInfo && (
                <div>
                  {!isPaymentTypeKnown(
                    account?.automatic_payment?.payment_type
                  ) ? (
                    <span>Payment Information Unavailable</span>
                  ) : (
                    <div>
                      {isSignedUpForAutomaticPayment(
                        account?.automatic_payment?.payment_type
                      )
                        ? "Enrolled in automatic payments"
                        : "Not enrolled in automatic payments"}
                    </div>
                  )}
                </div>
              )}
              {account?.auto_enroll_held === true && (
                <span>
                  Your Automatic Payments are being held. Please contact UFG
                  Billing Customer Service at 800-637-6309 between 7 a.m. and 6
                  p.m. CT Monday through Friday.
                </span>
              )}
            </div>
          </div>
          {(canEditAutopay || account?.eligible) && (
            <div className="auto-pay-enrollment">
              <Button
                onClick={() => clickHandler(canEditAutopay)}
                variant="primary"
                disabled={isDisabled}
                className="auto-pay-enrollment__option"
              >
                {canEditAutopay
                  ? "Edit Automatic Payments"
                  : "Enroll in Automatic Payments"}
              </Button>
            </div>
          )}
        </div>
      }
    >
      <AutomaticPaymentsPolicy policies={account?.policies} />
      {showAutopayModal && (
        <AutopayModal
          show={showAutopayModal}
          closeHandler={handleCloseAutopayModal}
          isEditing={canEditAutopay}
          isEnrolling={canEnrollInAutoPay}
          minimumDue={minimumDue}
          accountNumber={account?.number}
        />
      )}
    </Panel>
  );
};

AutomaticPaymentsAccount.propTypes = {
  account: PropTypes.object.isRequired,
  selectAccountToEnroll: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  selectAccountToEnroll
};

export default withRouter(
  connect(null, mapDispatchToProps)(AutomaticPaymentsAccount)
);
