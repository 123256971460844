import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "./loading-indicator";
import { getPdf, fetchPdfs } from "../services/policyholderService";
import DocumentService from "../services/documentService";
import InfoModal from "./modals/InfoModal";
import { Button } from "@ufginsurance/ui-kit";
import { LOADING_DOCUMENT_ROUTE } from "../constants/routes";
import { AnalyticCategories } from "../constants/analytics";
import { logger } from "../logging";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const DocumentButton = ({
  pdfUri,
  children,
  filename,
  isPostRequest,
  postPayload,
  isLink
}) => {
  const timeout = parseInt(window.env.REACT_APP_API_TIMEOUT, 10);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const trackAnalytics = (event, details) => {
    logger.event({
      category: AnalyticCategories.document,
      action: event.target.textContent,
      label: details
    });
  };

  const handleGetPdf = event => {
    event.persist();
    event.stopPropagation();

    const details = `filename: ${filename}, pdfUrl: ${pdfUri}`;
    trackAnalytics(event, details);
    setLoading(true);

    const documentRenderer = new DocumentService();
    documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

    const key = isPostRequest
      ? "post:" + pdfUri + JSON.stringify(postPayload.length)
      : "get:" + pdfUri;
    sessionStorage.setItem(key, Date.now());

    if (isPostRequest) {
      return fetchPdfs(pdfUri, postPayload).then(
        response => {
          logger.apitimes(key);
          documentRenderer.saveOrOpenDocument(response?.data, filename);
          setLoading(false);
        },
        () => {
          documentRenderer.clearRenderer();
          setLoading(false);
        }
      );
    }

    getPdf(pdfUri, { timeout }).then(
      response => {
        logger.apitimes(key);
        documentRenderer.saveOrOpenDocument(response.data, filename);
        setLoading(false);
      },
      e => {
        logger.error({
          description: e && e.response && e.response.statusText,
          details: `${event.target.textContent} ${details}`
        });
        documentRenderer.clearRenderer();
        setLoading(false);
        setError(e);
      }
    );
  };

  const renderErrorDisplay = () => {
    return (
      <InfoModal
        hideModal={() => setError(false)}
        title="Error"
        message={
          <HelpDeskMessage before="There was an error loading your document. For further assistance," />
        }
      />
    );
  };

  return (
    <>
      {loading && <LoadingIndicator message="Loading your document..." />}
      {error && renderErrorDisplay()}
      <Button
        onClick={handleGetPdf}
        disabled={loading}
        type="button"
        className="document-button-link"
        isLink={isLink}
        inline
      >
        {children}
      </Button>
    </>
  );
};

DocumentButton.propTypes = {
  pdfUri: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  filename: PropTypes.string.isRequired,
  isPostRequest: PropTypes.bool,
  postPayload: PropTypes.object,
  isLink: PropTypes.bool
};

export default DocumentButton;
