import React, { useEffect, useReducer, useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  ContentHeader,
  FlexRow,
  Form,
  Modal,
  useForm,
  Select
} from "@ufginsurance/ui-kit";
import { MY_PROFILE_ROUTE } from "../../constants/routes";
import {
  createFormValues,
  createUserProfile,
  securityQuestionValue
} from "./factory/UserProfileFactory";
import EditProfileForm from "../edit-profile/EditProfileForm";
import { isSignedUpForAutomaticPayment } from "../../utils";
import { logger } from "../../logging";
import { AnalyticActions, AnalyticCategories } from "../../constants/analytics";
import {
  submitEditProfile,
  clearEditProfileSubmission
} from "../../actions/submitEditProfileActions";
import { fetchPaymentVendorRoute } from "../../services/policyholderService";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

const getAutopayAccounts = accounts => {
  if (!accounts) return;
  return accounts?.filter(account =>
    isSignedUpForAutomaticPayment(account?.automatic_payment?.payment_type)
  );
};

const EditProfile = ({
  userProfile,
  securityQuestions,
  isEditableSession,
  automaticPaymentsData,
  submitEditProfile,
  submitProfileData,
  clearEditProfileSubmission
}) => {
  const [redirectToMyProfile, setRedirectToMyProfile] = useState(false);

  const [autopayState, updateAutopayState] = useReducer(
    (a, b) => ({ ...a, ...b }),
    {
      isEnrolledInAutopay: undefined,
      showAutopayEmailModal: false,
      autopayAccounts: [],
      isFetchingPaymentVendorRoute: false,
      paymentVendorRouteError: false
    }
  );

  const initialValues = createFormValues(userProfile);

  useEffect(() => {
    const { accounts } = automaticPaymentsData;
    const accountsEnrolledInAutopay = getAutopayAccounts(accounts) || [];
    updateAutopayState({
      isEnrolledInAutopay: !!accountsEnrolledInAutopay?.length,
      autopayAccounts: accountsEnrolledInAutopay
    });
  }, [automaticPaymentsData]);

  useEffect(() => {
    if (submitProfileData?.openAutopayEmailModal) {
      updateAutopayState({ showAutopayEmailModal: true });
    }
  }, [submitProfileData]);

  const handleCancel = () => setRedirectToMyProfile(true);

  const handleFormSubmit = values => {
    const editUserProfilePayload = createUserProfile(
      values,
      securityQuestionValue(userProfile.security_questions, 0, "id"),
      securityQuestionValue(userProfile.security_questions, 1, "id"),
      securityQuestionValue(userProfile.security_questions, 2, "id")
    );

    const hasEmailChanged = initialValues?.email !== values?.email;

    logger.event({
      category: AnalyticCategories.profile,
      action: AnalyticActions.profile.changed
    });

    submitEditProfile(editUserProfilePayload, {
      submissionSuccessMessage: "Profile successfully updated.",
      showAutopayEmailModalAfterSubmit:
        autopayState?.isEnrolledInAutopay && hasEmailChanged
    });
  };

  // Update Paymentus email modal.
  const handleUpdatePaymentusEmail = ({
    values: { autopayAccountToUpdate }
  }) => {
    // Reset error on retry.
    if (autopayState?.paymentVendorRouteError) {
      updateAutopayState({ paymentVendorRouteError: false });
    }

    const account =
      autopayState?.autopayAccounts?.length > 1
        ? autopayAccountToUpdate
        : autopayState?.autopayAccounts?.[0]?.number;

    updateAutopayState({ isFetchingPaymentVendorRoute: true });

    fetchPaymentVendorRoute(account, window.location.href)
      .then(({ data }) => {
        window.open(data.url, "_blank");
        updateAutopayState({ isFetchingPaymentVendorRoute: false });
      })
      .catch(() =>
        updateAutopayState({
          isFetchingPaymentVendorRoute: false,
          paymentVendorRouteError: true
        })
      );
  };

  const updateAutopayEmailForm = useForm({
    values: {
      autopayAccountToUpdate: ""
    },
    onSubmit: handleUpdatePaymentusEmail
  });

  const { values, handleOnChange, handleOnBlur, handleOnValidate } =
    updateAutopayEmailForm;

  const getAutopayAccountsOptions = () => {
    return !!autopayState?.autopayAccounts?.length
      ? autopayState?.autopayAccounts.map(({ number }) => ({
          label: number,
          value: number
        }))
      : [];
  };

  const handleCloseModal = () => {
    updateAutopayState({ showAutopayEmailModal: false });
    clearEditProfileSubmission();
    setRedirectToMyProfile(true);
  };

  return redirectToMyProfile ? (
    <Redirect to={MY_PROFILE_ROUTE} />
  ) : (
    <div className="row">
      <div className="col-xs-12 col-sm-10 col-md-8">
        <div className="row">
          <div className="col-xs-12">
            <ContentHeader>Edit Profile</ContentHeader>
          </div>
        </div>
        <EditProfileForm
          initialValues={initialValues}
          securityQuestions={securityQuestions}
          userSecurityQuestions={userProfile.security_questions}
          handleSubmit={handleFormSubmit}
          isEditableSession={isEditableSession}
          handleCancel={handleCancel}
          canEditUsername={false}
          isSubmitting={false}
        />

        <Modal
          title="Do you want to update your automatic payments email?"
          show={autopayState?.showAutopayEmailModal}
          onHide={handleCloseModal}
          body={
            <Form
              context={updateAutopayEmailForm}
              className="update-paymentus-email-modal"
            >
              {autopayState?.paymentVendorRouteError && (
                <Alert type="error">
                  <HelpDeskMessage />
                </Alert>
              )}
              {autopayState?.autopayAccounts?.length > 1 ? (
                <Select
                  id="autopayAccountToUpdate"
                  name="autopayAccountToUpdate"
                  options={
                    getAutopayAccountsOptions(autopayState?.autopayAccounts) ||
                    []
                  }
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleOnValidate}
                  value={values.autopayAccountToUpdate}
                  size="fill"
                  label="Select the account you would like to update"
                  isClearable={false}
                  disabled={autopayState?.isFetchingPaymentVendorRoute}
                />
              ) : (
                <div>
                  <p className="single-account-details">
                    You will be updating account #
                    <b>{autopayState?.autopayAccounts?.[0]?.number || "-"}</b>
                  </p>
                </div>
              )}

              <FlexRow align="right" className="action-buttons">
                <Button onClick={handleCloseModal}>No</Button>
                <Button
                  variant="primary"
                  type="submit"
                  spinner={autopayState?.isFetchingPaymentVendorRoute}
                  disabled={
                    (autopayState?.autopayAccounts?.length > 1 &&
                      !values.autopayAccountToUpdate) ||
                    autopayState?.isFetchingPaymentVendorRoute
                  }
                >
                  Yes
                </Button>
              </FlexRow>
            </Form>
          }
        />
      </div>
    </div>
  );
};

EditProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
  securityQuestions: PropTypes.array.isRequired,
  isEditableSession: PropTypes.bool.isRequired,
  automaticPaymentsData: PropTypes.object.isRequired,
  submitEditProfile: PropTypes.func.isRequired,
  submitProfileData: PropTypes.object.isRequired,
  clearEditProfileSubmission: PropTypes.func.isRequired
};

const mapState = state => ({
  automaticPaymentsData: state.automaticPaymentsData,
  submitProfileData: state.submitProfileData
});

const mapDispatch = {
  submitEditProfile,
  clearEditProfileSubmission
};

export default connect(mapState, mapDispatch)(EditProfile);
