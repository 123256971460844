import React, { useState } from "react";
import PropTypes from "prop-types";
import _equal from "lodash/isEqual";
import {
  useForm,
  Form,
  FormGroup,
  InputEmail,
  Input,
  Button
} from "@ufginsurance/ui-kit";
import EditSecurityQuestion from "./EditSecurityQuestion";

const EditProfileForm = ({
  initialValues,
  securityQuestions,
  userSecurityQuestions,
  handleSubmit,
  handleCancel,
  isSubmitting,
  isEditableSession,
  canEditUsername
}) => {
  const handleFormSubmission = ({ values }) => {
    handleSubmit(values);
  };

  const form = useForm({
    values: {
      ...initialValues,
      phone: (initialValues.phone || "").replace(/[^0-9.]/g, "")
    },
    onSubmit: handleFormSubmission
  });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const [securityQuestionSelections, setSecurityQuestionSelections] = useState(
    userSecurityQuestions || []
  );

  const handleSecurityQuestionChange = ({ field, value }) => {
    //update our dropdown options so the user can't pick the same question twice
    const updatedSelections = securityQuestionSelections.filter(
      _ => _.question !== values[field]
    );
    const newValue = securityQuestions.find(_ => _.question === value);
    if (!!newValue) updatedSelections.push(newValue);
    setSecurityQuestionSelections(updatedSelections);
    //clear associated answer
    updateForm({
      values: {
        ...values,
        [field]: value,
        [field.replace("Question", "Answer")]: ""
      }
    });
  };

  const updateErrors = (errors, error, errorIsValid) => {
    if (!errorIsValid) return (errors || []).filter(_ => _ !== error);
    if (!errors.includes(error)) return [...errors, error];
    return errors || [];
  };

  const handleEmailAddressValidation =
    (relatedField, relatedValue) =>
    ({ field, value }) => {
      let fieldErrors = Array.from(errors[field] || []);
      let relatedFieldErrors = Array.from(errors[relatedField] || []);

      // Is required field
      const requiredFieldError =
        (field !== "email" ? "Confirm " : "") + "Email is required.";
      const requiredFieldIsEmpty = !value;
      fieldErrors = updateErrors(
        fieldErrors,
        requiredFieldError,
        requiredFieldIsEmpty
      );

      // Is a valid email
      const emailIsInvalidError = "Not a valid email address.";
      const validEmailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const emailIsInvalid = !new RegExp(validEmailPattern, "g").test(
        value || ""
      );
      fieldErrors = updateErrors(
        fieldErrors,
        emailIsInvalidError,
        emailIsInvalid
      );

      // Mismatched values
      const relatedFieldMismatchError = "Email addresses must match.";
      const relatedFieldIsMismatched = relatedValue !== value;
      fieldErrors = updateErrors(
        fieldErrors,
        relatedFieldMismatchError,
        relatedFieldIsMismatched
      );
      relatedFieldErrors = updateErrors(
        relatedFieldErrors,
        relatedFieldMismatchError,
        relatedFieldIsMismatched
      );

      const hasErrorDiff = !_equal(errors[field] || [], fieldErrors);
      const hasRelatedErrorDiff = !_equal(
        errors[relatedField] || [],
        relatedFieldErrors
      );
      if (hasErrorDiff || hasRelatedErrorDiff) {
        updateForm({
          values,
          errors: {
            ...errors,
            [field]: hasErrorDiff ? fieldErrors : errors[field],
            [relatedField]: hasRelatedErrorDiff
              ? relatedFieldErrors
              : errors[relatedField]
          }
        });
      }

      return fieldErrors;
    };

  return (
    <Form context={form}>
      <FormGroup>
        <InputEmail
          id="email"
          name="email"
          label="Email Address"
          value={values.email}
          onBlur={handleEmailAddressValidation(
            "confirmEmail",
            values.confirmEmail
          )}
          onChange={handleOnChange}
          onValidate={handleOnValidate}
          size="full"
          required
        />
      </FormGroup>
      <FormGroup>
        <InputEmail
          id="confirmEmail"
          name="confirmEmail"
          label="Confirm Email Address"
          value={values.confirmEmail}
          onBlur={handleEmailAddressValidation("email", values.email)}
          onChange={handleOnChange}
          onValidate={handleOnValidate}
          size="full"
          required
        />
      </FormGroup>
      <FormGroup>
        <Input
          id="phone"
          name="phone"
          label="Preferred Phone Number"
          mask="phone"
          value={values.phone}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
          onValidate={handleOnValidate}
          size="full"
          stripMaskFromValue
          required
        />
      </FormGroup>
      <EditSecurityQuestion
        label="First Security Question"
        questions={securityQuestions}
        selections={securityQuestionSelections}
        questionId="firstQuestion"
        questionValue={values.firstQuestion || ""}
        answerId="firstAnswer"
        answerValue={values.firstAnswer}
        handleBlur={handleOnBlur}
        handleChange={handleOnChange}
        handleValidate={handleOnValidate}
        handleSecurityQuestionChange={handleSecurityQuestionChange}
      />
      <EditSecurityQuestion
        label="Second Security Question"
        questions={securityQuestions}
        selections={securityQuestionSelections}
        questionId="secondQuestion"
        questionValue={values.secondQuestion || ""}
        answerId="secondAnswer"
        answerValue={values.secondAnswer}
        handleBlur={handleOnBlur}
        handleChange={handleOnChange}
        handleValidate={handleOnValidate}
        handleSecurityQuestionChange={handleSecurityQuestionChange}
      />
      <EditSecurityQuestion
        label="Third Security Question"
        questions={securityQuestions}
        selections={securityQuestionSelections}
        questionId="thirdQuestion"
        questionValue={values.thirdQuestion || ""}
        answerId="thirdAnswer"
        answerValue={values.thirdAnswer}
        handleBlur={handleOnBlur}
        handleChange={handleOnChange}
        handleValidate={handleOnValidate}
        handleSecurityQuestionChange={handleSecurityQuestionChange}
      />
      {canEditUsername && (
        <FormGroup>
          <Input
            id="username"
            name="username"
            label="Username"
            value={values.username}
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            onValidate={handleOnValidate}
            size="full"
            required
          />
        </FormGroup>
      )}
      <div className="detail-table">
        <div className="detail-table__row row">
          <div className="col-xs-12">
            <Button
              type="submit"
              variant="primary"
              disabled={
                isSubmitting ||
                !isEditableSession ||
                Object.keys(form.errors).length > 0
              }
            >
              Save Changes
            </Button>
            &nbsp;
            <Button
              type="submit"
              onClick={handleCancel}
              disabled={isSubmitting || !isEditableSession}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

EditProfileForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  securityQuestions: PropTypes.array.isRequired,
  userSecurityQuestions: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditableSession: PropTypes.bool.isRequired,
  canEditUsername: PropTypes.bool.isRequired
};

export default EditProfileForm;
