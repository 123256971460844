import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const billingStatementsReducer = (
  state = initialState.billingStatementData,
  action
) => {
  switch (action.type) {
    case types.FETCH_BILLING_STATEMENT_DATA_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case types.FETCH_BILLING_STATEMENT_DATA_SUCCEEDED:
      return {
        ...state,
        statements: action.payload,
        isLoading: false,
        error: null
      };

    case types.FETCH_BILLING_STATEMENT_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default billingStatementsReducer;
