import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Translations } from "../translations";
import { POLICY_ROUTE } from "../../constants/routes";
import { isPayGo } from "../../utils";
import { Icon, ToolTip } from "@ufginsurance/ui-kit";
import "./_AccountList.scss";

const policyTypeMessage = (number, policy, isOasis) => {
  if (isPayGo(policy?.billing?.payment_type))
    return "Workers' Comp (Pay as you go)";
  if (isOasis) {
    return policy.type;
  }
  const types = Translations.policy_types;
  return !types.hasOwnProperty(policy.type) ? number : types[policy.type];
};

const PolicyLink = ({ number, policy, isOasis }) => {
  return (
    <Link to={`${POLICY_ROUTE}/${number}`} className="text-dark-blue">
      {!!policy.nickname
        ? policy.nickname
        : policyTypeMessage(number, policy, isOasis)}
      {policy.type === "UNI-PAK" || policy.type === "Commercial Package" ? (
        <ToolTip
          position="top"
          variant="white"
          type="hover"
          content={
            <div className="tooltip_hover">
              {policy.policy_lines.join(", ")}
            </div>
          }
          trigger={<Icon size="sm" icon="fasExclamationCircle" />}
        />
      ) : null}
    </Link>
  );
};

PolicyLink.propTypes = {
  number: PropTypes.string.isRequired,
  policy: PropTypes.object.isRequired,
  isOasis: PropTypes.bool.isRequired
};

export default PolicyLink;
