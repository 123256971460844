import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const changePasswordStarted = () => ({
  type: types.CHANGE_PASSWORD_STARTED
});

export const changePasswordSucceeded = data => ({
  type: types.CHANGE_PASSWORD_SUCCEEDED,
  payload: data
});

export const changePasswordFailed = error => ({
  type: types.CHANGE_PASSWORD_FAILED,
  payload: error,
  error: true
});

export const clearChangePasswordSubmission = () => ({
  type: types.CLEAR_CHANGE_PASSWORD_SUBMISSION
});

// Async action creator using thunk middleware
export function clearChangePasswordSubmissionNotification() {
  return dispatch => {
    dispatch(clearChangePasswordSubmission());
  };
}

export const clearRedirectToMyProfile = () => ({
  type: types.CLEAR_REDIRECT_TO_MYPROFILE
});

// Async action creator using thunk middleware
export function clearRedirectToMyProfileNotification() {
  return dispatch => {
    dispatch(clearRedirectToMyProfile());
  };
}

// Async action creator using thunk middleware
export function submitChangePassword(payload) {
  return dispatch => {
    dispatch(changePasswordStarted(payload));

    return policyholderService.submitChangePassword(payload).then(
      response => {
        dispatch(changePasswordSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(changePasswordFailed(error));
      }
    );
  };
}
