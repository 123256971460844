import React from "react";
import PropTypes from "prop-types";
import ErrorView from "./ErrorView";
import ErrorLevel from "../constants/errorLevel";
import { logger } from "../logging";

const logErrorToMyService = (error, errorInfo) => {
  logger.error({
    description: `Error: ${error}\nDetail: ${JSON.stringify(errorInfo)}`,
    fatal: false
  });
};

// This component will catch all rendering errors in its children as documentation below.
// https://reactjs.org/blog/2017/07/26/error-handling-in-react-16.html
class ErrorBoundary extends React.Component {
  static propTypes = {
    statusCode: PropTypes.number.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.element),
      PropTypes.element
    ]).isRequired
  };

  constructor(props) {
    super(props);
    this.state = { hasErrors: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasErrors: true });
    logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasErrors) {
      return (
        <ErrorView
          statusCode={this.props.statusCode}
          level={ErrorLevel.danger}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
