import React, { useState } from "react";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import {
  formatDate,
  Pagination,
  Panel,
  useDataSort
} from "@ufginsurance/ui-kit";
import ClaimsDetail from "./ClaimsDetail";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

const filterPolicies = (claims, policyNumber, activeTab) => {
  if (!claims) return [];
  const orderByDate = activeTab === "open" ? "reported_date" : "closed_date";
  const orderedClaims = orderBy(claims, c => c[orderByDate], "desc");

  return !policyNumber
    ? orderedClaims
    : orderedClaims.filter(claim => claim.policy_number === policyNumber);
};

const ClaimsList = ({ claims, policyNumber, activeTab }) => {
  const [pageSize, setPageSize] = useState(5);

  const filteredPolicies = filterPolicies(claims, policyNumber, activeTab);
  const policies = useDataSort({
    data: filteredPolicies,
    paginate: true,
    itemsPerPage: pageSize,
    sortBy: null
  });

  if (!claims || !claims?.length || !filteredPolicies?.length) {
    if (activeTab === "open") {
      return (
        <div>
          <HelpDeskMessage before="No open claims found. If you feel this is in error," />
        </div>
      );
    }
    return (
      <div>
        <HelpDeskMessage before="No closed claims found. If you feel this is in error," />
      </div>
    );
  }

  return (
    <div className="policies-pagination">
      <Pagination
        activePage={policies.activePage}
        goToPage={policies.goToPage}
        nextPage={policies.nextPage}
        prevPage={policies.prevPage}
        pages={policies.pages}
        pageSize={pageSize}
        defaultPageSize={5}
        totalRows={filteredPolicies.length}
        changePageSize={s => setPageSize(s)}
      />

      {!!policies?.data?.length &&
        policies.data.map(claim => (
          <Panel
            id={`claim-${claim.claim_number}`}
            key={claim.claim_number}
            collapsible
            isOpen={false}
            title={
              <div className="claims-list-title">
                <span className="claims-list-title__claim-number">
                  {`Claim Number: ${claim.claim_number} |`}
                </span>
                <span className="claims-list-title__dol">
                  {`Date of loss: ${formatDate(claim.loss_date, "MM/DD/YYYY")}`}
                </span>
              </div>
            }
          >
            <ClaimsDetail details={claim} />
          </Panel>
        ))}
    </div>
  );
};

ClaimsList.propTypes = {
  claims: PropTypes.arrayOf(PropTypes.object),
  policyNumber: PropTypes.string,
  activeTab: PropTypes.string
};

export default ClaimsList;
