import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchBillingActivityStarted = () => ({
  type: types.FETCH_BILLING_ACTIVITY_STARTED
});

export const fetchBillingActivitySucceeded = data => ({
  type: types.FETCH_BILLING_ACTIVITY_SUCCEEDED,
  payload: data
});

export const fetchBillingActivityFailed = error => ({
  type: types.FETCH_BILLING_ACTIVITY_FAILED,
  payload: error,
  error: true
});

export const fetchBillingActivityDetailsStarted = () => ({
  type: types.FETCH_BILLING_ACTIVITY_DETAILS_STARTED
});

export const fetchBillingActivityDetailsSucceeded = data => ({
  type: types.FETCH_BILLING_ACTIVITY_DETAILS_SUCCEEDED,
  payload: data
});

export const fetchBillingActivityDetailsFailed = error => ({
  type: types.FETCH_BILLING_ACTIVITY_DETAILS_FAILED,
  payload: error,
  error: true
});

export const fetchBillingActivity = () => dispatch => {
  dispatch(fetchBillingActivityStarted());

  return policyholderService.fetchBillingActivityAccounts().then(
    response => {
      dispatch(fetchBillingActivitySucceeded(response.data));
    },
    error => {
      dispatch(fetchBillingActivityFailed(error));
    }
  );
};

export function fetchBillingActivityAccountDetails(
  accountNumber,
  begin_date,
  end_date
) {
  return dispatch => {
    dispatch(fetchBillingActivityDetailsStarted());

    return policyholderService
      .fetchBillingActivityDetails(accountNumber, begin_date, end_date)
      .then(
        response => {
          dispatch(
            fetchBillingActivityDetailsSucceeded({
              data: response.data,
              accountNumber,
              activities: response?.data?.history || [],
              unapplied_amount: response?.data?.unapplied_amount
            })
          );
        },
        error => {
          dispatch(fetchBillingActivityDetailsFailed(error));
        }
      );
  };
}
