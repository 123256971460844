import React, { Component } from "react";
import { connect } from "react-redux";
import { Translations } from "../components/translations";
import PropTypes from "prop-types";
import { reregisterAccountConfirm } from "../actions/addAccountActions";
import CustomErrorView from "../components/CustomErrorView";
import LoadingIndicator from "../components/loading-indicator";
import AddPrevRegistered from "../components/prev-registered/AddPrevRegistered";
import { AnalyticCategories, AnalyticActions } from "../constants/analytics";
import { logger } from "../logging";

class AddPrevRegisteredContainer extends Component {
  static propTypes = {
    handleCancel: PropTypes.func.isRequired,
    reregisterAccountConfirm: PropTypes.func.isRequired,
    reregistrationData: PropTypes.object
  };

  trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.account,
      action: AnalyticActions.account.submitReregistration
    });
  };

  handleSubmit = () => {
    this.trackAnalytics();
    return this.props.reregisterAccountConfirm({
      register_token: this.props.reregistrationData.data.register_token,
      accepted_previous_elections: true
    });
  };

  render() {
    const { data, isLoading } = this.props.reregistrationData;
    const errors = [this.props.reregistrationData.error].filter(
      error => !!error
    );
    if (isLoading) {
      return <LoadingIndicator />;
    }
    return (
      <div className="form-controls">
        {errors && errors.length > 0 && (
          <CustomErrorView
            error={errors[0]}
            errorLocationInTranslation={Translations.reg_prev.errors}
          />
        )}
        <AddPrevRegistered
          data={data}
          handleCancel={this.props.handleCancel}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  reregistrationData: state.reregistrationData
});

const mapDispatchToProps = {
  reregisterAccountConfirm
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPrevRegisteredContainer);
