import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";
import { Alert } from "@ufginsurance/ui-kit";
import { ErrorCodes } from "../constants/errorCodes";
import { SYSTEMS_UNAVAILABLE_ROUTE } from "../constants/routes";
import { HelpDeskEmailLink } from "./email";

const getErrorMessage = statusCode => {
  const status = parseInt(statusCode, 10);

  let message = Translations.error_messages[`error_${status}`];

  // originally: message = message ? message : Translations.error_messages.error_520;
  message = message ? message : Translations.error_messages.error_503;

  return Translations.formatString(message, <HelpDeskEmailLink />);
};

const ErrorView = ({ level, statusCode }) => {
  // Possibly unnecessary, but including this check and redirect as catch-all
  if (
    statusCode === ErrorCodes.ServiceUnavailable ||
    statusCode === ErrorCodes.GatewayTimeout
  ) {
    window.location.replace(SYSTEMS_UNAVAILABLE_ROUTE);
    return null;
  }

  return (
    <Alert type={level} dismissible={false}>
      {getErrorMessage(statusCode)}
    </Alert>
  );
};

ErrorView.propTypes = {
  statusCode: PropTypes.number.isRequired,
  level: PropTypes.string.isRequired
};

export default ErrorView;
