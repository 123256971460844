import { connect } from "react-redux";
import FeedbackModal from "./FeedbackModal";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

const mapStateToProps = state => ({
  policyholderUsername: state.profileData.userProfile.username,
  policyholderEmail: sessionSelector.getEmail(state)
});

export default connect(mapStateToProps)(FeedbackModal);
