export const AnalyticCategories = {
  authentication: "Authentication",
  navigation: "Navigation",
  apiCall: "API Call",
  account: "Account",
  policy: "Policy",
  claim: "Claim",
  profile: "Profile",
  quickLinks: "Quick Links",
  automaticPayments: "Automatic Payments",
  document: "Document",
  termsAndConditions: "Terms and Conditions"
};

export const AnalyticActions = {
  authentication: {
    login: "Login",
    logout: "Logout",
    expired: "Expired"
  },
  account: {
    viewBill: "View Bill Clicked",
    payBill: "Pay Bill Clicked",
    autoId: "Auto ID Card Clicked",
    viewPolicy: "View Policy Clicked",
    installmentSchedule: "Installment Schedule Clicked",
    fetchInstallmentSchedule: "Get Installment Schedule",
    billingActivity: "Billing Activity Clicked",
    fetchPolicyBalanceRemaining: "Fetch Policy Balance Remaining",
    submitReregistration: "Submit Account Reregistration"
  },
  policy: {
    nickname: "Nickname Changed",
    view_all_forms: "View All Forms Clicked"
  },
  claims: {
    open: "Open Claims Clicked",
    history: "Claims History Clicked",
    fetchClaims: "Get Claims"
  },
  profile: {
    viewed: "Profile Viewed",
    changed: "Profile Changed",
    migration: {
      completed: "Profile Migrated"
    }
  },
  automaticPayments: {
    editEFT: "EFT Payment Updated",
    enrollEFT: "EFT Enrollment"
  },
  termsAndConditions: {
    acceptPaperlessBillingAndEmailAlerts:
      "Accept Paperless Billing and Email Alerts",
    acceptPolicyAlertChanges: "Accept Policy Alert Changes"
  }
};
