import { PaymentTypes } from "../constants/enumerations";

export const isSignedUpForAutomaticPayment = payment_type => {
  return (
    payment_type !== PaymentTypes.notEnrolled &&
    payment_type !== PaymentTypes.unknown
  );
};

export const isPaymentTypeKnown = payment_type => {
  return payment_type !== PaymentTypes.unknown;
};

/**
 * Finds the correlating auto-pay and summary accounts.
 * @param {Array} autoPayAccounts Accounts from /billing/automatic-payments
 * @param {Object} summaryAccount Single account from /accounts/summary
 * @returns {Object} The matching auto-pay account and summary account.
 */
export const getCurrentAutoPayAccount = (autoPayAccounts, summaryAccount) => {
  return autoPayAccounts?.accounts?.length && summaryAccount
    ? autoPayAccounts?.accounts?.find(
        acc => acc?.number === summaryAccount?.number
      )
    : null;
};

export const getIsAutoPayEligible = account => {
  return (
    !isSignedUpForAutomaticPayment(account?.automatic_payment?.payment_type) &&
    account?.eligible
  );
};

export const getCanEditAutopay = account => {
  return (
    isSignedUpForAutomaticPayment(account?.automatic_payment?.payment_type) &&
    account?.eligible
  );
};

export const isPayGo = paymentType => {
  if (!paymentType) return false;
  if (Array.isArray(paymentType)) {
    return paymentType.some(type => type === PaymentTypes.payGo);
  }
  return paymentType === PaymentTypes.payGo;
};

export const getPaymentType = (
  billingActivityData,
  accountNumber,
  policyNumber
) => {
  const { accounts } = billingActivityData;

  const targetAccount = accounts?.find(
    account => account.number === accountNumber
  );

  if (!targetAccount) {
    return [];
  }

  // If policy_number is not present, return an array of payment type (section_group) values from all policies
  if (!policyNumber) {
    return targetAccount.policies.map(policy => policy.section_group);
  }

  // Find the matching policy and return its payment type value
  const matchingPolicy = targetAccount.policies.find(
    policy => policy.number === policyNumber
  );

  return matchingPolicy ? [matchingPolicy.section_group] : [];
};
