import React from "react";
import PropTypes from "prop-types";
import { Panel } from "@ufginsurance/ui-kit";
import { Translations } from "../translations/Translations";
import PolicyActivityListLegacy from "./PolicyActivityListLegacy";
import PolicyActivityListOasis from "./PolicyActivityListOasis";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

const PolicyActivity = ({ policyActivityData, policyNumber, origin }) => {
  const { activity } = Translations.my_policy;
  const { activityData, isLoading, error } = policyActivityData;

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="lines-of-business__forms-list-container">
      {origin === "ufg_core" && (
        <Panel
          id={`policy-ufg_core-${policyNumber}`}
          title={activity.panel_title[origin]}
          collapsible
          isOpen={false}
        >
          {error ? (
            <HelpDeskMessage before="There is currently no Policy History available for your policy or account. If you feel this is a technical error," />
          ) : (
            <PolicyActivityListLegacy
              activityData={activityData}
              policyNumber={policyNumber}
              origin={origin}
            />
          )}
        </Panel>
      )}
      {origin === "oasis" && (
        <Panel
          id={`policy-oasis-${policyNumber}`}
          title={activity.panel_title[origin]}
          collapsible
          isOpen={false}
        >
          <PolicyActivityListOasis
            activityData={activityData}
            policyNumber={policyNumber}
            origin={origin}
          />
        </Panel>
      )}
    </div>
  );
};

PolicyActivity.propTypes = {
  policyActivityData: PropTypes.object.isRequired,
  policyNumber: PropTypes.string,
  origin: PropTypes.string
};

export default PolicyActivity;
