import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchPolicyBalanceRemainingDetailsStarted = () => ({
  type: types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_STARTED
});

export const fetchPolicyBalanceRemainingDetailsSucceeded = data => ({
  type: types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_SUCCEEDED,
  payload: data
});

export const fetchPolicyBalanceRemainingDetailsFailed = error => ({
  type: types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_FAILED,
  payload: error
});

export function fetchPolicyBalanceRemainingDetails(accountNumber) {
  return dispatch => {
    dispatch(fetchPolicyBalanceRemainingDetailsStarted());

    return policyholderService
      .fetchPolicyBalanceRemainingDetails(accountNumber)
      .then(
        response => {
          dispatch(
            fetchPolicyBalanceRemainingDetailsSucceeded({
              data: response.data,
              accountNumber
            })
          );
        },
        error => {
          dispatch(fetchPolicyBalanceRemainingDetailsFailed(error));
        }
      );
  };
}
