import { connect } from "react-redux";
import React, { Component } from "react";
import LoadingIndicator from "../components/loading-indicator";
import BillingStatements from "../components/BillingStatements";
import { fetchBillingStatementData } from "../actions/billingStatementsActions";
import PropTypes from "prop-types";

class BillingStatementsContainer extends Component {
  static propTypes = {
    billingStatementData: PropTypes.object.isRequired,
    fetchBillingStatementData: PropTypes.func.isRequired
  };

  componentDidMount() {
    return this.props.fetchBillingStatementData();
  }

  render() {
    const { isLoading, statements, error } = this.props.billingStatementData;
    return !isLoading ? (
      <BillingStatements billingStatements={statements} error={error} />
    ) : (
      <LoadingIndicator />
    );
  }
}

const mapStateToProps = state => ({
  billingStatementData: state.billingStatementData
});

const mapDispatchToProps = {
  fetchBillingStatementData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingStatementsContainer);
