import {
  SUBMIT_USER_MIGRATION_FAILED,
  SUBMIT_USER_MIGRATION_SUCCEEDED,
  SUBMIT_USER_MIGRATION_STARTED
} from "../constants/actionTypes";
import initialState from "./initialState";

const userMigrationReducer = (state = initialState.userMigration, action) => {
  switch (action.type) {
    case SUBMIT_USER_MIGRATION_STARTED:
    case SUBMIT_USER_MIGRATION_SUCCEEDED:
      return {
        error: null
      };
    case SUBMIT_USER_MIGRATION_FAILED:
      if (
        action.payload.response.data &&
        action.payload.response.status === 403
      ) {
        action.payload.response.data.error_code = 403;
      }
      return {
        error: action.payload
      };
    default:
      return state;
  }
};

export default userMigrationReducer;
