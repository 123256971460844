import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const addAccountStarted = () => ({
  type: types.ADD_ACCOUNT_STARTED
});

export const addAccountSucceeded = () => ({
  type: types.ADD_ACCOUNT_SUCCEEDED
});

export const addAccountFailed = error => ({
  type: types.ADD_ACCOUNT_FAILED,
  payload: error,
  error: true
});

export const reregisterAccountSucceeded = data => ({
  type: types.FETCH_REREGISTER_ACCOUNT_SUCCEEDED,
  payload: data
});

export const clearAddAccountSubmission = () => ({
  type: types.CLEAR_ADD_ACCOUNT_SUBMISSION
});

export function clearAddAccountSubmissionNotification() {
  return dispatch => {
    dispatch(clearAddAccountSubmission());
  };
}

export const clearRedirectToAccount = () => ({
  type: types.CLEAR_REDIRECT_TO_ACCOUNT
});

// Async action creator using thunk middleware
export function submitAddAccount(payload) {
  return dispatch => {
    dispatch(addAccountStarted());

    return policyholderService.submitAddAccount(payload).then(
      response => {
        if (response.data)
          return dispatch(reregisterAccountSucceeded(response.data));

        return dispatch(addAccountSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(addAccountFailed(error));
      }
    );
  };
}

export function reregisterAccountConfirm(payload) {
  return dispatch => {
    dispatch(addAccountStarted());

    return policyholderService
      .submitAccountReregistrationConfirmation(payload)
      .then(
        () => {
          return dispatch(addAccountSucceeded());
        },
        error => {
          dispatch(addAccountFailed(error));
          return Promise.reject(error);
        }
      );
  };
}
