import React from "react";
import PropTypes from "prop-types";
import DocumentLink from "./DocumentLink";

const BillStatement = ({ billStatementLink, btnSize, className }) => {
  return (
    <DocumentLink
      pdfUri={billStatementLink}
      filename={"Billing_Statement.pdf"}
      btnSize={btnSize}
      className={className}
    >
      <span>View Bill</span>
    </DocumentLink>
  );
};

BillStatement.propTypes = {
  billStatementLink: PropTypes.string,
  btnSize: PropTypes.string,
  className: PropTypes.string
};

export default BillStatement;
