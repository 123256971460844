import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchAutomaticPaymentsStarted = () => ({
  type: types.FETCH_AUTOMATIC_PAYMENTS_STARTED
});

export const fetchAutomaticPaymentsSucceeded = data => ({
  type: types.FETCH_AUTOMATIC_PAYMENTS_SUCCEEDED,
  payload: data
});

export const fetchAutomaticPaymentsFailed = error => ({
  type: types.FETCH_AUTOMATIC_PAYMENTS_FAILED,
  payload: error,
  error: true
});

export const selectEnrollmentAccount = data => ({
  type: types.SELECT_AUTOMATIC_PAYMENTS_ENROLLMENT_ACCOUNT,
  payload: data
});

export function selectAccountToEnroll(accountToEnroll) {
  return dispatch => {
    dispatch(selectEnrollmentAccount(accountToEnroll));
  };
}

// Async action creator using thunk middleware
export function fetchAutomaticPayments() {
  return dispatch => {
    dispatch(fetchAutomaticPaymentsStarted());

    return policyholderService.fetchAutomaticPaymentAccounts().then(
      response => {
        dispatch(fetchAutomaticPaymentsSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(fetchAutomaticPaymentsFailed(error));
      }
    );
  };
}
