import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const myPolicyHistoryReducer = (
  state = initialState.claimsHistoryData,
  action
) => {
  switch (action.type) {
    case types.FETCH_CLAIMS_HISTORY_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_CLAIMS_HISTORY_SUCCEEDED:
      return {
        ...state,
        claimsHistory: {
          ...state.claimsHistory,
          [action.payload.number]: action.payload.data
        },
        error: null,
        isLoading: false
      };
    case types.FETCH_CLAIMS_HISTORY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default myPolicyHistoryReducer;
