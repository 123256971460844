import { connect } from "react-redux";
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { formatDate } from "@ufginsurance/ui-kit";
import { Translations } from "../translations/Translations";
import ChangeNickname from "./ChangeNickname";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { v4 } from "uuid";

const propTypes = {
  policy: PropTypes.object,
  policyNumber: PropTypes.string,
  isEditableSession: PropTypes.bool
};

const renderNickname = (policy, policyNumber, isEditableSession) => {
  const { nickname } = policy;
  const label = nickname ? nickname : Translations.my_policy.add_nickname;

  return (
    <span>
      {label}
      <ChangeNickname
        nickname={nickname}
        policyNumber={policyNumber}
        disabled={!isEditableSession}
      />
    </span>
  );
};

const PolicyInfo = ({ policy, policyNumber, isEditableSession }) => {
  const { table } = Translations.my_policy;

  const policyType = useMemo(()=>{
    const policyType =
    Translations.policy_types[policy.type] ?? policy.type;
    return policyType;
  },[policy.type]);

  const termDetails = useMemo(() => {
    const details = [];
    const currentTerm =
      policy.terms && policy.terms.find(item => item.term === "current");
    if (currentTerm) {
      details.push(
        `${formatDate(currentTerm.effective_date, "MM/DD/YYYY")} - ${formatDate(
          currentTerm.expiration_date,
          "MM/DD/YYYY"
        )}`
      );
    }
    const renewalTerm =
      policy.terms && policy.terms.find(item => item.term === "renewal");
    if (renewalTerm) {
      details.push(
        `${formatDate(renewalTerm.effective_date, "MM/DD/YYYY")} - ${formatDate(
          renewalTerm.expiration_date,
          "MM/DD/YYYY"
        )}`
      );
    }

    const priorTerm =
      policy.terms && policy.terms.find(item => item.term === "prior");
    if (priorTerm) {
      details.push(
        `${formatDate(priorTerm.effective_date, "MM/DD/YYYY")} - ${formatDate(
          priorTerm.expiration_date,
          "MM/DD/YYYY"
        )}`
      );
    }
    return details.sort().reverse();
  }, [policy]);

  return (
    <table className="policy-info-table">
      <thead className="policy-info-table__head">
        <tr className="policy-info-table__row">
          <th>{table.head.account}</th>
          <th>{table.head.policy}</th>
          <th>{table.head.nickname}</th>
          <th>{table.head.term}</th>
          <th>{table.head.type}</th>
        </tr>
      </thead>
      <tbody>
        {termDetails.map(term => {
          return (
            <tr className="policy-info-table__row" key={v4()}>
              <td>{policy.account_number}</td>
              <td>{policy.number}</td>
              <td>{renderNickname(policy, policyNumber, isEditableSession)}</td>
              <td>{term}</td>
              <td>{policyType}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const mapStateToProps = state => ({
  isEditableSession: sessionSelector.policyholderHasWriteAccess(state)
});

PolicyInfo.propTypes = propTypes;
export default connect(mapStateToProps, null)(PolicyInfo);
