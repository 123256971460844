import React from "react";
import PropTypes from "prop-types";
import { PanelGrid, Panel, LoadingIndicator } from "@ufginsurance/ui-kit";
import StatementSummary from "./StatementSummary";
import PaperlessPreferences from "./PaperlessPreferences";

const AccountSummary = ({
  policyBilling,
  account,
  paperless,
  isInPendingCancel
}) => {
  const paperlessAlerts =
    paperless?.alertsData?.filter(_ => _.number === account.number) || [];

  const hasEligiblePaperlessAlerts =
    paperlessAlerts.length &&
    (paperlessAlerts[0]?.paperless_bill?.eligibility === "updatable" ||
      paperlessAlerts[0]?.policy_email_alert?.eligibility === "updatable" ||
      paperlessAlerts[0]?.bill_email_alert?.eligibility === "updatable");

  return (
    <PanelGrid sizes={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
      <Panel shadow title="Statement Summary">
        <StatementSummary
          policyBilling={policyBilling}
          account={account}
          isInPendingCancel={isInPendingCancel}
        />
      </Panel>
      <Panel shadow title="Manage Paperless Preferences">
        {!paperless ||
        (paperless?.isLoading && !paperless.alertsData.length) ? (
          <LoadingIndicator />
        ) : hasEligiblePaperlessAlerts ? (
          <PaperlessPreferences account={account} />
        ) : (
          <p className="panel-row">No paperless options available.</p>
        )}
      </Panel>
    </PanelGrid>
  );
};

AccountSummary.propTypes = {
  policyBilling: PropTypes.object,
  account: PropTypes.object.isRequired,
  paperless: PropTypes.object.isRequired,
  isInPendingCancel: PropTypes.bool.isRequired
};

export default AccountSummary;
