import { routes } from "@ufginsurance/sso-oidc-client-react";
import { Log } from "oidc-client";

const ssoBaseUrlAPI = window.env.REACT_APP_SSO_BASEURL;
const customerPortalClientId = window.env.REACT_APP_SSO_CLIENT_ID;

Log.logger = console;
Log.level = Log.ERROR;

export const config = {
  client_id: customerPortalClientId,
  redirect_uri: `${window.location.origin}${routes.OIDC_SSO_CALLBACK}`,
  scope: "openid profile policyholder",
  authority: `${ssoBaseUrlAPI}identity`,
  post_logout_redirect_uri: `${window.location.origin}${routes.HOME}`,
  clockSkew: 900
};
