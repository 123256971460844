import React from "react";
import HelpModal from "../modals/HelpModal";
import { Translations } from "../translations";

const AutomaticPaymentsModal = () => {
  const { automatic_payments } = Translations.payments_alerts.modals;

  const modalBody = (
    <div>
      <ul>
        {automatic_payments.bullets.map(bullet => {
          return typeof bullet === "object" ? (
            <div key={bullet}>
              <li>{bullet.head}</li>
              <ul>
                {bullet.body.map(point => (
                  <li key={point}>{point}</li>
                ))}
              </ul>
            </div>
          ) : (
            <li key={bullet}>{bullet}</li>
          );
        })}
      </ul>
    </div>
  );

  return <HelpModal title={automatic_payments.title} modalBody={modalBody} />;
};

export default AutomaticPaymentsModal;
