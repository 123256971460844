import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";
import Anchor from "./Anchor";
import * as routes from "../constants/routes";
import { v4 } from "uuid";

const propTypes = {
  links: PropTypes.arrayOf(PropTypes.object).isRequired
};

const QuickLinks = ({ links }) => {
  const staticLinks = [
    {
      url: `${window.env.REACT_APP_PUBLICSITE_BASEURL}/report-a-claim`,
      title: "Report a Claim"
    },
    { url: routes.AUTO_ID_CARDS_ROUTE, title: "Get Auto ID Cards" },
    { url: routes.BILLING_ACTIVITY_ROUTE, title: "View Billing Activity" },
    { url: routes.POLICY_ROUTE, title: "View Policy History" },
    { url: routes.INSTALLMENT_ROUTE, title: "View Installment Schedule" },
    { url: routes.BILLING_STATEMENTS_ROUTE, title: "View Statements" },
    { url: routes.PAYMENT_OPTIONS, title: "Payment Options" }
  ];

  const items =
    !links || links.length === 0 ? staticLinks : [...staticLinks, ...links];
  const anchors = items.map(item => {
    const options = {
      authenticationRequired: item.authentication_required
        ? item.authentication_required === true
        : false,
      download: !!(item.response_type && item.response_type === "blob"),
      displayKey: item.display_key
    };
    return (
      <Anchor
        key={item?.display_key || v4()}
        href={item.url}
        className="quick-link"
        target={item.target}
        title={
          item?.title || Translations.quick_links.link_keys[item.display_key]
        }
        options={options}
      />
    );
  });

  return (
    <div className="card">
      <div>
        <h3 className="card__header">{Translations.quick_links.title}</h3>
        <div className="line-container">
          <span className="red-line" />
          <span className="grey-line" />
        </div>
      </div>
      {anchors}
    </div>
  );
};

QuickLinks.propTypes = propTypes;

export default QuickLinks;
