import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchBillingAndAlertsStarted = () => ({
  type: types.FETCH_BILLING_ALERTS_STARTED
});

export const fetchBillingAndAlertsSucceeded = data => ({
  type: types.FETCH_BILLING_ALERTS_SUCCEEDED,
  payload: data
});

export const fetchBillingAndAlertsFailed = error => ({
  type: types.FETCH_BILLING_ALERTS_FAILED,
  payload: error,
  error: true
});

// Async action creator using thunk middleware
export function fetchBillingAndAlerts() {
  return dispatch => {
    dispatch(fetchBillingAndAlertsStarted());
    return policyholderService.fetchBillingAndAlertsAccounts().then(
      response => {
        dispatch(fetchBillingAndAlertsSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(fetchBillingAndAlertsFailed(error));
      }
    );
  };
}

export const postBillingAndAlertsStarted = () => ({
  type: types.POST_BILLING_ALERTS_STARTED
});

export const postBillingAndAlertsSucceeded = data => ({
  type: types.POST_BILLING_ALERTS_SUCCEEDED,
  payload: data
});

export const postBillingAndAlertsFailed = error => ({
  type: types.POST_BILLING_ALERTS_FAILED,
  payload: error,
  error: true
});

export function postBillingAndAlerts(request) {
  return dispatch => {
    dispatch(postBillingAndAlertsStarted());
    return policyholderService.submitBillingAndAlerts(request).then(
      () => dispatch(postBillingAndAlertsSucceeded(request)),
      error => {
        console.log(error);
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(postBillingAndAlertsFailed(error));
      }
    );
  };
}
