const initialState = {
  quickLinksData: {
    data: [],
    isLoading: false,
    error: null
  },
  autoIdCardsData: {
    data: [],
    isLoading: false,
    error: null
  },
  accountData: {
    accounts: [],
    isLoading: false,
    error: null
  },
  profileData: {
    userProfile: {},
    isLoading: false,
    error: null
  },
  changePasswordData: {
    redirectToMyProfile: false,
    changeSucceeded: false,
    isLoading: false,
    error: null
  },
  addAccountData: {
    addAccountSuccess: false,
    isLoading: false,
    error: null
  },
  editProfileData: {
    isLoading: false,
    error: null
  },
  submitEditProfile: {
    userProfile: null,
    redirectToMyProfile: false,
    openAutopayEmailModal: false,
    submissionMessage: "",
    submitEditProfileSuccess: false,
    isLoading: false,
    error: null
  },
  automaticPaymentsData: {
    accounts: [],
    accountToEnroll: null,
    isLoading: false,
    error: null
  },
  billingAndAlertsData: {
    alertsData: [],
    isLoading: false,
    isAcknowledgedPaymentModal: false,
    error: null
  },
  installmentScheduleData: {
    accountNumbers: null,
    accountDetails: null,
    isLoading: false,
    error: null
  },
  billingActivityData: {
    accounts: [],
    accountDetails: [],
    unapplied_amount: null,
    isLoading: false,
    error: null
  },
  policyBalanceRemainingData: {
    accountData: null,
    isLoading: false,
    error: null
  },
  myPolicyData: {
    policies: null,
    coverageOptionsData: null,
    isLoading: false,
    error: null
  },
  myPolicyFormsData: {
    forms: {},
    isLoading: false,
    error: null
  },
  coverageOptionsData: {
    decCodes: null,
    isLoading: false,
    error: null
  },
  policyActivityData: {
    activityData: null,
    isLoading: false,
    error: null
  },
  claimsAccountsData: {
    accounts: null,
    isLoading: false,
    error: null
  },
  openClaimsData: {
    claims: null,
    isLoading: false,
    error: null
  },
  claimsHistoryData: {
    claimsHistory: null,
    isLoading: false,
    error: null
  },
  userMigration: {
    error: null
  },
  reregistrationData: {
    data: null,
    isLoading: false,
    error: null
  },
  companyNotification: {
    notifictaions: null,
    isLoading: false,
    error: null
  },
  myAgent: {
    agentData: null,
    isLoading: false,
    error: null
  },
  billingStatementData: {
    statements: [],
    isLoading: false,
    error: null
  }
};

export default initialState;
