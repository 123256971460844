import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { fetchViewProfile } from "../actions/viewProfileActions";
import { fetchSecurityQuestions } from "../actions/editProfileActions";
import { clearMyProfileRedirectNotification } from "../actions/submitEditProfileActions";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { ErrorCodes } from "../constants/errorCodes";
import { MY_PROFILE_ROUTE } from "../constants/routes";
import EditProfile from "../components/edit-profile/EditProfile";
import ErrorBoundary from "../components/ErrorBoundary";
import LoadingIndicator from "../components/loading-indicator";
import { Translations } from "../components/translations";
import CustomErrorView from "../components/CustomErrorView";
import { fetchAutomaticPayments } from "../actions/automaticPaymentsAction";

class EditMyProfileContainer extends Component {
  static propTypes = {
    profileData: PropTypes.object,
    editProfileData: PropTypes.object,
    submitProfileData: PropTypes.object,
    fetchViewProfile: PropTypes.func.isRequired,
    fetchSecurityQuestions: PropTypes.func.isRequired,
    clearMyProfileRedirectNotification: PropTypes.func.isRequired,
    isEditableSession: PropTypes.bool.isRequired,
    fetchAutomaticPayments: PropTypes.func.isRequired,
    automaticPaymentsData: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.fetchViewProfile();
    this.props.fetchSecurityQuestions();

    const { accounts, error, isLoading } = this.props.automaticPaymentsData;
    if (!accounts?.length && !isLoading && !error) {
      this.props.fetchAutomaticPayments();
    }
  }

  componentWillUnmount() {
    this.props.clearMyProfileRedirectNotification();
  }

  render() {
    const {
      profileData,
      editProfileData,
      submitProfileData,
      isEditableSession
    } = this.props;

    const isLoading =
      (profileData && profileData.isLoading) ||
      (editProfileData && editProfileData.isLoading) ||
      (submitProfileData && submitProfileData.isLoading);

    const errors = [
      profileData && profileData.error,
      editProfileData && editProfileData.error,
      submitProfileData && submitProfileData.error
    ].filter(error => !!error);

    const hasUserSecurityQuestions =
      profileData.userProfile &&
      profileData.userProfile.security_questions &&
      profileData.userProfile.security_questions.length > 0;

    const hasAvailableSecurityQuestions =
      editProfileData.securityQuestions &&
      editProfileData.securityQuestions.length > 0;

    if (submitProfileData && submitProfileData.redirectToMyProfile) {
      return <Redirect to={MY_PROFILE_ROUTE} />;
    }

    if (this.props.automaticPaymentsData?.isLoading) {
      return <LoadingIndicator />;
    }

    return (
      <ErrorBoundary statusCode={ErrorCodes.ServerError}>
        <div className="form-controls">
          {isLoading && <LoadingIndicator />}
          {errors && errors.length > 0 && (
            <CustomErrorView
              error={errors[0]}
              errorLocationInTranslation={Translations.user_profile.error_codes}
            />
          )}
          <div>
            {hasUserSecurityQuestions && hasAvailableSecurityQuestions && (
              <EditProfile
                userProfile={profileData.userProfile}
                securityQuestions={editProfileData.securityQuestions}
                isEditableSession={isEditableSession}
              />
            )}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.profileData,
  editProfileData: state.editProfileData,
  submitProfileData: state.submitProfileData,
  isEditableSession: sessionSelector.policyholderHasWriteAccess(state),
  automaticPaymentsData: state.automaticPaymentsData
});

const mapDispatchToProps = {
  fetchViewProfile,
  fetchSecurityQuestions,
  clearMyProfileRedirectNotification,
  fetchAutomaticPayments
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditMyProfileContainer);
