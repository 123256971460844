import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchPolicyActivityStarted = () => ({
  type: types.FETCH_POLICY_ACTIVITY_STARTED
});

const fetchPolicyActivitySucceeded = data => ({
  type: types.FETCH_POLICY_ACTIVITY_SUCCEEDED,
  payload: data
});

const fetchPolicyActivityFailed = error => ({
  type: types.FETCH_POLICY_ACTIVITY_FAILED,
  payload: error,
  error: true
});

export const fetchPolicyActivity = policyNumber => {
  return dispatch => {
    dispatch(fetchPolicyActivityStarted());

    return policyholderService.fetchPolicyActivity(policyNumber).then(
      res => dispatch(fetchPolicyActivitySucceeded(res.data)),
      error => dispatch(fetchPolicyActivityFailed(error.response))
    );
  };
};
