import React from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";

const PhoneNumber = ({ number }) => {
  if (number.length !== 10) {
    return number;
  }

  const areaCode = number.slice(0, 3);
  const centralOfficeCode = number.slice(3, 6);
  const lineNumber = number.slice(6, 10);

  return (
    <span>{`${areaCode}\u2011${centralOfficeCode}\u2011${lineNumber}`}</span>
  );
};

PhoneNumber.propTypes = {
  number: PropTypes.string
};

PhoneNumber.defaultProps = {
  number: Translations.contact.unavailable
};

export default PhoneNumber;
