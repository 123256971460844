//https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
export const ErrorCodes = {
  BadRequest: 400,
  Unauthorized: 401,
  NotFound: 404,
  ServerError: 500,
  NotImplemented: 501,
  ServiceUnavailable: 503,
  GatewayTimeout: 504,
  UnknownError: 520
};
