import axios from "axios";
import {
  secureRequestInterceptor,
  secureResponseInterceptor
} from "@ufginsurance/sso-oidc-client-react";
import { logger } from "./logging";
import { getStoreState } from "./store";
import isIE from "./utils/isIE";

const logError = (message, error) => {
  logger.error({
    description: `${message}: ${JSON.stringify(error)}`,
    fatal: true
  });
};

const hasQueryString = path => {
  return path.indexOf("?") > 0;
};

const appendQueryItem = (path, item) => {
  return !hasQueryString(path) ? `${path}?${item}` : `${path}&${item}`;
};

const clientIDRequestInterceptor = (service, clientId, clientSecret) => {
  service.interceptors.request.use(config => {
    return {
      ...config,
      headers: {
        // Spread existing params in case other request interceptors add headers
        ...config.headers,
        client_id: clientId,
        client_secret: clientSecret
      }
    };
  });
};

const apiFactory = baseUrl => {
  const service = axios.create({
    baseURL: baseUrl
  });
  secureRequestInterceptor(
    service.interceptors.request,
    getStoreState,
    logError
  );
  secureResponseInterceptor(
    service.interceptors.response,
    getStoreState,
    logError
  );

  clientIDRequestInterceptor(
    service,
    window.env.REACT_APP_CLIENT_ID,
    window.env.REACT_APP_CLIENT_SECRET
  );

  service.interceptors.request.use(config => {
    // HACK: IE has a bad habit of very aggressively caching GET resources, like API get calls.
    // We don't want a previously logged in users' data to show up when a different user logs in;
    // though it would have to be on the same machine and probably within the same tab; regardless seems bad.
    if (isIE()) {
      config.headers["X-Requested-With"] = "XMLHttpRequest";
      config.headers.Expires = "-1";
      config.headers["Cache-Control"] =
        "no-cache,no-store,must-revalidate,max-age=-1,private"; // is this over kill?

      if (config.method === "get") {
        // making every get URL unique should in theory be enough to force IE to NOT fuckin cache!
        config.url = appendQueryItem(
          config.url,
          `timestamp=${Date.now().toString()}`
        );
      }
    }

    return config;
  });

  return service;
};
// Developers can override this with an env.local file
const baseUrl = window.env.REACT_APP_API_BASEURL;
//M4
const policyholderEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI;
const policyAccountsEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI;
const policyholderAdminEapiBaseUrl =
  window.env.REACT_APP_POLICYHOLDER_ADMIN_EAPI_MULE_4_URI;

export default {
  baseless: () => apiFactory(""),
  base: () => apiFactory(baseUrl),
  policyHolder: () => apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`),
  profile: () => apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`),
  billing: () => apiFactory(`${baseUrl}${policyholderEapiBaseUrl}`),
  accounts: () => apiFactory(`${baseUrl}${policyAccountsEapiBaseUrl}`),
  policies: () => apiFactory(`${baseUrl}${policyAccountsEapiBaseUrl}`),
  admin: () => apiFactory(`${baseUrl}${policyholderAdminEapiBaseUrl}`)
};
