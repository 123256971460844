import rootReducer from "../reducers";
import { config } from "../ssoConfiguration";
import { ssoClient } from "@ufginsurance/sso-oidc-client-react";
import { configureStore } from "@reduxjs/toolkit";

const initializeStore = () => {
  ssoClient.initializeMiddleware(config);
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        //ufg sso action payloads are not serializaable
        //https://redux-toolkit.js.org/api/serializabilityMiddleware
        //https://stackoverflow.com/questions/61704805/getting-an-error-a-non-serializable-value-was-detected-in-the-state-when-using
        serializableCheck: false
      }).concat(ssoClient.ssoMiddleware),
    devTools: process.env.NODE_ENV !== "production"
  });
  window.reduxStore = store;
  return window.reduxStore;
};

export const getStoreState = () => {
  return window.reduxStore && window.reduxStore.getState();
};

export default initializeStore;
