import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";

const propTypes = {
  location: PropTypes.shape({
    address1: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired
  }).isRequired
};

const Address = ({ location }) => {
  const { address1, street, city, state, zip } = location;
  return (
    <div>
      <div>{street || address1}</div>
      <div>
        {Translations.formatString(
          Translations.contact.address.line_two,
          city,
          state,
          zip
        )}
      </div>
    </div>
  );
};

Address.propTypes = propTypes;

export default Address;
