import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCompanyNotifications } from "../actions/companyNotificationsActions";
import { CompanyAlert } from "../components/ui-kit/";

class CompanyNotificationsContainer extends Component {
  static propTypes = {
    fetchCompanyNotifications: PropTypes.func.isRequired,
    companyNotifications: PropTypes.object.isRequired
  };

  state = { hideAlert: false };

  isCloseButton = target => target.includes("company-alert__close");

  onClose = () => {
    return this.setState({ hideAlert: true });
  };

  componentDidMount() {
    return this.props.fetchCompanyNotifications();
  }

  render() {
    const {
      companyNotifications: { notifications }
    } = this.props;
    const { hideAlert } = this.state;

    return (
      <div>
        {notifications && notifications.length > 0 && !hideAlert && (
          <CompanyAlert onClose={this.onClose} message={notifications} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyNotifications: state.companyNotifications
});

const mapDispatchToProps = {
  fetchCompanyNotifications
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyNotificationsContainer);
