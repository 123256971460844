import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const billingAndAlertsDataReducer = (
  state = initialState.billingAndAlertsData,
  action
) => {
  switch (action.type) {
    case types.FETCH_BILLING_ALERTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_BILLING_ALERTS_SUCCEEDED:
      return {
        ...state,
        alertsData: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_BILLING_ALERTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case types.POST_BILLING_ALERTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.POST_BILLING_ALERTS_SUCCEEDED:
      return {
        ...state,
        alertsData: (state?.alertsData || []).map(_ => {
          if (_.number !== action?.payload?.number) return _;
          return {
            ..._,
            [action?.payload?.name]: {
              ..._[action?.payload?.name],
              option: action?.payload?.option
            }
          };
        }),
        isLoading: false,
        error: null
      };
    case types.POST_BILLING_ALERTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default billingAndAlertsDataReducer;
