import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const installmentScheduleReducer = (
  state = initialState.installmentScheduleData,
  action
) => {
  switch (action.type) {
    case types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_SUCCEEDED:
      return {
        ...state,
        accountNumbers: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_SUCCEEDED:
      return {
        ...state,
        accountDetails: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_FAILED:
      return {
        ...state,
        accountDetails: null,
        isLoading: false,
        error: {
          ...action.payload,
          data: action.payload.data.detail
            ? action.payload.data.detail
            : action.payload.data
        }
      };
    default:
      return state;
  }
};

export default installmentScheduleReducer;
