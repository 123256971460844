import React from "react";
import { Translations } from "./translations";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <p className="footer__paragraph">
            <strong className="footer__strong">
              <a
                className="footer__anchor"
                href={window.env.REACT_APP_PUBLICSITE_BASEURL}
              >
                {Translations.footer.home}
              </a>
              |
              <a
                className="footer__anchor"
                href={`${window.env.REACT_APP_PUBLICSITE_BASEURL}/contact-us`}
              >
                {Translations.footer.contact_us}
              </a>
            </strong>
          </p>
          <p className="copy __paragraph">
            &copy; {Translations.footer.copyright}|
            <a
              className="footer__anchor"
              href={`${window.env.REACT_APP_PUBLICSITE_BASEURL}/about-ufg/privacy-terms-of-use/disclaimers-other-legal-documents`}
            >
              {Translations.footer.disclaimer}
            </a>
            {"|"}
            <a
              className="footer__anchor"
              href={`${window.env.REACT_APP_PUBLICSITE_BASEURL}/about-ufg/privacy-terms-of-use/privacy-statement`}
            >
              {Translations.footer.privacy}
            </a>
          </p>
        </div>
        <div className="footer__social">
          <a
            className="footer__anchor footer__social__anchor"
            href="https://facebook.com/ufginsurance"
            target="blank"
          >
            <span className="uikit-sr-only">Facebook</span>
            <i className="fa fa-facebook-f" />
          </a>
          <a
            className="footer__anchor footer__social__anchor"
            href="https://www.linkedin.com/company/ufginsurance"
            target="blank"
          >
            <span className="uikit-sr-only">LinkedIn</span>
            <i className="fa fa-linkedin" />
          </a>
          <a
            className="footer__anchor footer__social__anchor"
            target="blank"
            href="https://twitter.com/ufginsurance"
          >
            <span className="uikit-sr-only">Twitter</span>
            <i className="fa fa-twitter" />
          </a>
          <a
            className="footer__anchor footer__social__anchor"
            target="blank"
            href="https://www.instagram.com/ufginsurance/"
          >
            <span className="uikit-sr-only">Instagram</span>
            <i className="fa fa-instagram" />
          </a>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
