import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const billingActivityDataReducer = (
  state = initialState.billingActivityData,
  action
) => {
  switch (action.type) {
    case types.FETCH_BILLING_ACTIVITY_STARTED:
      return {
        ...state,
        accounts: null,
        isLoading: true,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_SUCCEEDED:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_BILLING_ACTIVITY_DETAILS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_DETAILS_SUCCEEDED:
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          [action.payload.accountNumber]: action.payload.activities
        },
        unapplied_amount: action?.payload?.unapplied_amount,
        isLoading: false,
        error: null
      };
    case types.FETCH_BILLING_ACTIVITY_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        unapplied_amount: null,
        error: action.payload
      };
    default:
      return state;
  }
};

export default billingActivityDataReducer;
