import React from "react";
import { Alert } from "@ufginsurance/ui-kit";
import PropTypes from "prop-types";
import { Translations } from "../translations";

const propTypes = {
  history: PropTypes.object,
  accounts: PropTypes.array,
  fetchCancellationNotice: PropTypes.func.isRequired
};

class CancellationNotice extends React.Component {
  state = {
    show: true
  };

  getCancellationPolicy = () => {
    let oasisPolicies = [];
    this.props.accounts.forEach(account => {
      oasisPolicies = [...oasisPolicies, ...account.policies];
    });
    const cancellationPolicy = oasisPolicies.find(p => {
      return p.status === "pending_cancellation";
    });
    return cancellationPolicy;
  };

  showAlert = () => {
    const { pathname } = this.props.history.location;
    const { accounts } = this.props;
    if (!(pathname === "/" || pathname.includes("account"))) return false;
    if (!accounts || accounts.length === 0) return false;
    if (!this.state.show) return false;
    return !!this.getCancellationPolicy();
  };

  dismissAlert = () => {
    this.setState({ show: false });
  };

  fetchCancellationNotice = () => {
    this.props.fetchCancellationNotice(this.getCancellationPolicy().number);
  };

  render() {
    if (!this.showAlert()) return null;
    return (
      <div className="col-lg-12 col-md-12 col-sm-12 cancellation-notice">
        <Alert type="error" onDismiss={this.dismissAlert}>
          <b>
            {
              Translations.account_summary.Pending_cancellation_notice
                .Notice_message
            }
          </b>
          {
            Translations.account_summary.Pending_cancellation_notice
              .Notice_message_account_line
          }
        </Alert>
      </div>
    );
  }
}

CancellationNotice.propTypes = propTypes;

export default CancellationNotice;
