import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Translations } from "../components/translations";
import { fetchClaimsAccounts } from "../actions/claimsAccountsActions";
import { fetchOpenClaims } from "../actions/openClaimsActions";
import { fetchClaimsHistoryData } from "../actions/claimsHistoryActions";
import LoadingIndicator from "../components/loading-indicator";
import PlainErrorView from "../components/PlainErrorView";
import ErrorView from "../components/ErrorView";
import ErrorLevel from "../constants/errorLevel";
import ClaimsList from "../components/claims/ClaimsList";
import { AnalyticCategories, AnalyticActions } from "../constants/analytics";
import { logger } from "../logging";
import {
  ContentHeader,
  SelectNonForm,
  FlexRow,
  LoadingIndicator as UIKitLoadingIndicator
} from "@ufginsurance/ui-kit";

const ClaimsContainer = ({
  match,
  fetchOpenClaims,
  fetchClaimsAccounts,
  fetchClaimsHistoryData,
  accountsData,
  openClaimsData,
  claimsHistoryData
}) => {
  const [activeTab, setActiveTab] = useState("");
  const [activeAccount, setActiveAccount] = useState("");
  const [activePolicy, setActivePolicy] = useState("");

  const getAccountOptions = accounts =>
    accounts
      ? accounts
          .map(account => ({ value: account.number, label: account.number }))
          .sort((a, b) => b?.value - a?.value)
      : [];

  const getPolicyOptions = () => {
    return activeAccount && accountsData
      ? accountsData.accounts
          .filter(account => account.number === activeAccount)[0]
          .policies.map(policy => {
            if (!policy.nickname && policy.type === "unknown") {
              return { value: policy.number, label: policy.number };
            }
            return {
              value: policy.number,
              label: `${
                policy.nickname || Translations.policy_types[policy.type]
              } - ${policy.number}`
            };
          })
      : [];
  };

  const trackAnalytics = details => {
    logger.event({
      category: AnalyticCategories.claim,
      action: AnalyticActions.claims.fetchClaims,
      label: details
    });
  };

  const handleAccountChange = ({ value }) => {
    setActiveAccount(value);
    trackAnalytics(`Account: ${value}`);
  };

  const handlePolicyChange = ({ value }) => {
    setActivePolicy(value);
    trackAnalytics(`Policy: ${value}`);
  };

  // Set the active tab based on path.
  useEffect(() => {
    if (match?.params?.claimsStatus && accountsData) {
      setActiveTab(match?.params?.claimsStatus);
    }
  }, [match, accountsData, setActiveAccount]);

  // Fetch the accounts.
  useEffect(() => {
    if (!accountsData?.accounts && !(accountsData?.isLoading ?? false)) {
      fetchClaimsAccounts();
    }
  }, [
    match.params,
    fetchClaimsAccounts,
    accountsData?.accounts,
    accountsData?.isLoading
  ]);

  // If only one account returned, set as default dropdown value.
  useEffect(() => {
    const { accounts } = accountsData;
    if (accounts && accounts?.length === 1) {
      setActiveAccount(accounts[0].number);
    }
  }, [accountsData]);

  // Fetch Open/Closed claims after activeTab update.
  useEffect(() => {
    if (
      (!openClaimsData.claims ||
        (openClaimsData.claims && !openClaimsData.claims[activeAccount])) &&
      activeAccount &&
      activeTab === "open"
    ) {
      fetchOpenClaims(activeAccount);
    }

    if (
      (!claimsHistoryData.claims ||
        (claimsHistoryData.claims && !openClaimsData.claims[activeAccount])) &&
      activeAccount &&
      activeTab === "closed"
    ) {
      fetchClaimsHistoryData(activeAccount, activeTab);
    }
  }, [
    activeAccount,
    activeTab,
    claimsHistoryData.claims,
    fetchClaimsHistoryData,
    fetchOpenClaims,
    openClaimsData.claims
  ]);

  const setHeader = () => {
    const { open_claims, claims_history } = Translations.claims;
    return activeTab === "open" ? open_claims : claims_history;
  };

  const renderList = () => {
    const { open_errors, closed_errors } = Translations.claims;
    const { claimsHistory } = claimsHistoryData;
    const { claims } = openClaimsData;

    if (
      (claimsHistoryData && claimsHistoryData.isLoading) ||
      (openClaimsData && openClaimsData.isLoading)
    ) {
      return <UIKitLoadingIndicator type="spinner" />;
    }

    if (activeTab === "closed" && claimsHistoryData && claimsHistoryData.error)
      return (
        <PlainErrorView
          error={claimsHistoryData.error.response}
          errorLocationInTranslation={closed_errors}
          hideDirectBillLink
          helpDeskErrorCodes={["302"]}
        />
      );

    if (activeTab === "open" && openClaimsData && openClaimsData.error)
      return (
        <PlainErrorView
          error={openClaimsData.error.response}
          errorLocationInTranslation={open_errors}
          hideDirectBillLink
          helpDeskErrorCodes={["302"]}
        />
      );

    if (activeAccount !== "") {
      return activeTab === "closed" && claimsHistory ? (
        <ClaimsList
          claims={claimsHistory[activeAccount]}
          policyNumber={activePolicy}
        />
      ) : activeTab === "open" && claims ? (
        <ClaimsList
          claims={claims[activeAccount]}
          policyNumber={activePolicy}
          activeTab={activeTab}
        />
      ) : null;
    }
  };

  const { info_message, link } = Translations.claims;

  if (accountsData.isLoading) return <LoadingIndicator />;
  if (accountsData.error) {
    return (
      <ErrorView
        level={ErrorLevel.danger}
        statusCode={accountsData.error.response.status}
      />
    );
  }

  const policyOptions = getPolicyOptions();

  return (
    <div>
      <ContentHeader>{setHeader()}</ContentHeader>
      <div>
        <i className="fa fa-info-circle" />
        <em> {info_message}</em>
        <em>
          {" "}
          <a href={`${window.env.REACT_APP_PUBLICSITE_BASEURL}/report-a-claim`}>
            {link}
          </a>
        </em>
      </div>
      <div className="claims__dropdowns">
        <FlexRow>
          <SelectNonForm
            id="accountNumber"
            name="accountNumber"
            label="Select Account"
            placeholder="Select Account"
            options={getAccountOptions(accountsData.accounts)}
            onChange={handleAccountChange}
            value={activeAccount}
            isClearable={false}
            size="md"
          />
          <SelectNonForm
            id="policyNumber"
            name="policyNumber"
            label="Select Policy"
            placeholder="Select Policy"
            options={policyOptions}
            onChange={handlePolicyChange}
            value={
              policyOptions?.length === 1
                ? policyOptions[0].value
                : activePolicy
            }
            isClearable={false}
            disabled={!activeAccount || policyOptions?.length === 1}
            size="md"
          />
        </FlexRow>
      </div>
      {renderList()}
    </div>
  );
};

const mapStateToProps = state => ({
  accountsData: state.claimsAccountsData,
  openClaimsData: state.openClaimsData,
  claimsHistoryData: state.claimsHistoryData
});

const mapDispatchToProps = {
  fetchOpenClaims,
  fetchClaimsAccounts,
  fetchClaimsHistoryData
};

ClaimsContainer.propTypes = {
  match: PropTypes.object,
  fetchOpenClaims: PropTypes.func.isRequired,
  fetchClaimsAccounts: PropTypes.func.isRequired,
  fetchClaimsHistoryData: PropTypes.func.isRequired,
  accountsData: PropTypes.object,
  openClaimsData: PropTypes.object,
  claimsHistoryData: PropTypes.object
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimsContainer);
