import React from "react";
import PropTypes from "prop-types";
import sanitizeHtml from "sanitize-html";

const CompanyAlert = ({ title, message, onClose }) => {
  const sanitize = text =>
    sanitizeHtml(text, {
      allowedTags: ["b", "i", "em", "strong", "a"],
      allowedAttributes: {
        a: ["href", "target"]
      }
    });

  return (
    <div className="company-alert">
      <div className="company-alert__icon">
        <i className="fa fa-exclamation-circle" />
      </div>
      <div className="company-alert__message-group">
        <p className="company-alert__title">{`${title}: `}</p>
        <p dangerouslySetInnerHTML={{ __html: sanitize(message) }}></p>
        <span
          className="company-alert__close"
          onClick={onClose}
          onKeyPress={onClose}
          role="presentation"
          aria-hidden="true"
        >
          X
        </span>
      </div>
    </div>
  );
};

CompanyAlert.defaultProps = {
  title: "Alert"
};

CompanyAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CompanyAlert;
