import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import { renderFormLink } from "./ActivityFunctions";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

const propTypes = {
  activityData: PropTypes.arrayOf(PropTypes.object),
  policyNumber: PropTypes.string,
  origin: PropTypes.string
};

const PolicyActivityListLegacy = ({ activityData, policyNumber, origin }) => {
  return (
    <div className="lines-of-business-table__wrapper">
      <table className="lines-of-business-table">
        <thead className="lines-of-business-table__head">
          <tr className="lines-of-business-table__row">
            <td className="lines-of-business-table__checkbox" />
            <td>{Translations.my_policy.activity.table.head.form_name}</td>
            <td>{Translations.my_policy.activity.table.head.effective_date}</td>
          </tr>
        </thead>
        <tbody>
          {activityData &&
            activityData.length > 0 &&
            activityData.map(policy => (
              <tr
                key={policy?.id?.id ?? policy.id}
                className="lines-of-business-table__row"
              >
                <td />
                <td>{renderFormLink(policyNumber, policy, origin)}</td>
                <td>{formatDate(policy.effective_date, "MM/DD/YYYY")}</td>
              </tr>
            ))}
          {activityData && activityData.length < 1 && (
            <span>
              <HelpDeskMessage before="There is currently no Policy History available for your policy or account. If you feel this is a technical error," />
            </span>
          )}
        </tbody>
      </table>
    </div>
  );
};

PolicyActivityListLegacy.propTypes = propTypes;
export default PolicyActivityListLegacy;
