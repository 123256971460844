import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchOpenClaimsStarted = () => ({
  type: types.FETCH_OPEN_CLAIMS_STARTED
});

export const fetchOpenClaimsSucceeded = data => ({
  type: types.FETCH_OPEN_CLAIMS_SUCCEEDED,
  payload: data
});

export const fetchOpenClaimsFailed = error => ({
  type: types.FETCH_OPEN_CLAIMS_FAILED,
  payload: error,
  error: true
});

export const fetchOpenClaims = number => dispatch => {
  dispatch(fetchOpenClaimsStarted());

  return policyholderService.fetchOpenClaimsData(number, "open").then(
    response =>
      dispatch(fetchOpenClaimsSucceeded({ number, data: response.data })),
    error => dispatch(fetchOpenClaimsFailed(error))
  );
};
