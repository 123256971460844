import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { AnalyticCategories } from "../constants/analytics";
import { logger } from "../logging";
import apiFactory from "../api";
import DocumentService from "../services/documentService";
import { LOADING_DOCUMENT_ROUTE } from "../constants/routes";
import isIE from "../utils/isIE";

const propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
  options: PropTypes.object
};

const trackAnalytics = evt => {
  logger.event({
    category: AnalyticCategories.quickLinks,
    action: evt.target.textContent
  });
};

const authenticatedBlobRequest = (url, options) => evt => {
  trackAnalytics(evt);
  evt.stopPropagation();

  const documentRenderer = new DocumentService();
  documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

  const api = apiFactory(url.protocol + "//" + url.host);
  const completeRender = response => {
    documentRenderer.saveOrOpenDocument(
      response.data,
      `${options.displayKey}.pdf`
    );
  };
  api
    .get(url.pathname, { responseType: "blob" })
    .then(completeRender, () => documentRenderer.clearRenderer());
};

const renderOutsideLink = (href, title, className, target, options) => {
  if (
    options &&
    options.authenticationRequired === true &&
    options.download === true
  ) {
    return (
      <Link
        to="#"
        onClick={authenticatedBlobRequest(new URL(href), options)}
        className={className ? className : ""}
      >
        <span>{title}</span>
      </Link>
    );
  }

  return (
    <a
      href={href}
      className={className ? className : ""}
      target={target ? target : ""}
      onClick={trackAnalytics}
    >
      {title}
    </a>
  );
};

const Anchor = ({ href, title, className, target, options }) => {
  const pattern = isIE()
    ? new RegExp("^((http(s)?|ftp(s)?|file|mailto)://)")
    : /^((http(s)?|ftp(s)?|file|mailto):\/\/)/;

  return pattern.test(href) ? (
    renderOutsideLink(href, title, className, target, options)
  ) : (
    <Link
      to={href}
      className={className ? className : ""}
      onClick={trackAnalytics}
    >
      {title}
    </Link>
  );
};

Anchor.propTypes = propTypes;

export default Anchor;
