import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const addAccountDataReducer = (state = initialState.addAccountData, action) => {
  switch (action.type) {
    case types.ADD_ACCOUNT_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.ADD_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        addAccountSuccess: true,
        isLoading: false,
        error: null
      };
    case types.FETCH_REREGISTER_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case types.CLEAR_ADD_ACCOUNT_SUBMISSION:
      return {
        ...state,
        addAccountSuccess: false,
        isLoading: false,
        error: null
      };
    case types.ADD_ACCOUNT_FAILED:
      return {
        ...state,
        addAccountSuccess: false,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default addAccountDataReducer;
