import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PaperlessAccountList from "./PaperlessAccountList";
import { MY_PROFILE_ROUTE } from "../../constants/routes";
import BillingAndAlertsModal from "./BillingAndAlertsModal";
import { ContentHeader } from "@ufginsurance/ui-kit";

const propTypes = {
  accounts: PropTypes.array.isRequired,
  email: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired
};

const BillingAndAlerts = ({ accounts, email, userProfile }) => {
  return (
    <div>
      <div className="billing-and-alerts">
        <ContentHeader>
          Paperless Billing and Alerts
          <BillingAndAlertsModal />
        </ContentHeader>
      </div>
      <div className="billing-and-alerts__message">
        <i className="fa fa-info-circle" />
        Before signing up for our paperless billing and email alerts, please
        ensure that your email address is correct: {email} and that you have a
        phone number listed in your profile. You can update your information in{" "}
        <Link to={MY_PROFILE_ROUTE}>My Profile</Link>
      </div>
      <PaperlessAccountList accounts={accounts} userProfile={userProfile} />
    </div>
  );
};

BillingAndAlerts.propTypes = propTypes;

export default BillingAndAlerts;
