import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";

const propTypes = {
  securityQuestions: PropTypes.array.isRequired
};

const ViewSecurityQuestions = ({ securityQuestions }) => {
  return securityQuestions ? (
    securityQuestions.map((securityQuestion, index) => {
      const questionNumber = index + 1;
      const rowClass = index % 2 === 0 ? "even" : "odd";

      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className={`detail-table__row row ${rowClass}`}>
          <div className="detail-table__heading col-xs-6 col-sm-6 col-md-4">
            {Translations.formatString(
              Translations.user_profile.security_question,
              questionNumber || ""
            )}
          </div>
          <div className="col-xs-6 col-sm-6 col-md-8">
            {securityQuestion.question}
          </div>
        </div>
      );
    })
  ) : (
    <br />
  );
};

ViewSecurityQuestions.propTypes = propTypes;
export default ViewSecurityQuestions;
