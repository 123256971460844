import React from "react";
import errorLevel from "../../constants/errorLevel";
import { ErrorCodes } from "../../constants/errorCodes";
import ErrorView from "../ErrorView";

const NotImplemented = () => (
  <ErrorView status={ErrorCodes.NotImplemented} level={errorLevel.info} />
);

export default NotImplemented;
