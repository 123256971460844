import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchViewProfileStarted = () => ({
  type: types.FETCH_VIEW_PROFILE_STARTED
});

export const fetchViewProfileSucceeded = data => ({
  type: types.FETCH_VIEW_PROFILE_SUCCEEDED,
  payload: data
});

export const fetchViewProfileFailed = error => ({
  type: types.FETCH_VIEW_PROFILE_FAILED,
  payload: error,
  error: true
});

// Async action creator using thunk middleware
export function fetchViewProfile() {
  return dispatch => {
    dispatch(fetchViewProfileStarted());
    return policyholderService.getUserProfile().then(
      response => {
        dispatch(fetchViewProfileSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(fetchViewProfileFailed(error));
      }
    );
  };
}
