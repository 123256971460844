import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";

export const TermsAndConditions = () => {
  return (
    <div>
      <ContentHeader>Automatic Payment Terms and Conditions</ContentHeader>
      <p>
        These Automatic Payment Terms and Conditions apply to your United Fire
        Group (UFG) account. The words &quot;United Fire Group,&quot;
        &quot;we,&quot; &quot;us&quot; and &quot;our&quot; refer to United Fire
        and Casualty Company, its subsidiaries and affiliates, your creditor
        with whom you have your account. The word &quot;you&quot; and
        &quot;your&quot; mean you, the individual(s) or entity identified on the
        account. As used in these Automatic Payment Terms and Conditions,
        &quot;communication&quot; means any account periodic statement, or
        associated authorization, agreement, disclosure, notice or other
        information that would accompany your account’s periodic statement
        including, but not limited to, information that we are required by law
        to provide to you in writing.
      </p>
      <ContentHeader>
        Acceptance of Automatic Payment Terms and Conditions
      </ContentHeader>
      <p>
        By clicking Agree, and registering to use any of UFG’s automatic payment
        options, you are confirming that you have read and accept all of the
        terms and conditions stated in this document, all terms and conditions
        applicable to ufgpolicyholder.com and all terms and conditions governing
        ufginsurance.com.
      </p>
      <ContentHeader>Terms of Use</ContentHeader>
      <p>
        Customers are not obligated to use automatic payment options. If you do
        not agree with the Automatic Payment Terms and Conditions, you may not
        use these features. To the extent there is a conflict between any
        requirements posted on our website pages and these Automatic Payment
        Terms and Conditions, the requirements posted on our website pages will
        govern.
      </p>
      <p>
        By registering for UFG’s automatic payment options, you agree to provide
        us with valid, current and correct information as requested at
        ufgpolicyholder.com. You also agree to promptly provide us with any
        updated information. UFG takes the fraudulent or unauthorized use of
        personal, financial or other customer information seriously. If we
        become aware of any fraudulent or unauthorized use of such data, we will
        pursue, and/or disclose such activity to the proper authorities,
        consistent with applicable law.
      </p>
      <p>
        By registering for UFG’s automatic payment options, you agree to
        indemnify and hold UFG harmless for any damage, claim, loss, action,
        expense or liability arising from the use or unauthorized use of your
        bank information or account by anyone who obtains your username and/or
        password either directly from you or as a result of your failure to
        adequately secure such information. If you believe that your security
        information has been lost or stolen, you must notify us immediately.
      </p>
      <p>
        All communications in either electronic or paper format from us to you
        will be considered &quot;in writing.&quot; You should print or download
        for your records, a copy of your electronic statements, this Automatic
        Payment Terms and Conditions disclosure, your initial authorization to
        utilize UFG’s automatic payment options, and any other communication
        that is important to you.
      </p>
      <p>
        By enrolling in UFG’s automatic payment options and providing your email
        address, you agree to receive information and authorize UFG to
        communicate with you concerning your account electronically at the email
        address you provide. UFG’s automatic payment options are solely for the
        use of payment of your billing statements. Your enrollment in UFG’s
        automatic payment options will continue unless and until you withdraw
        your consent by contacting UFG Billing Customer Service at 800-637-6309
        between 7 a.m. and 6 p.m. CT M-F. Any withdrawal of your consent will be
        effective only after we have a reasonable period of time to process your
        withdrawal.
      </p>
      <p>
        Your enrollment in automatic payment option(s) does not limit your
        obligation to pay the minimum amount due by the due date as shown on any
        billing statement or Policy Cancellation Notice you receive. Failure to
        pay the minimum amount due by 7:30 p.m. CT on the due date will result
        in a late fee being assessed to your account and could subject you to
        policy cancellation or termination.
      </p>
      <ContentHeader>Electronic Communication</ContentHeader>
      <p>
        If you register to use UFG’s automatic payment options, you may receive
        future notification(s), via email, confirming your online account
        activity, informing you about changes or updates to the services
        available, and/or providing other disclosures that may be authorized or
        required by law. In order to use UFG’s automatic payment options, it is
        your responsibility to provide us with and maintain a true, accurate and
        complete email address.
      </p>
      <ContentHeader>
        How to Change Your Automatic Payment Information
      </ContentHeader>
      <p>
        You can update your automatic payment information at any time by
        visiting ufgpolicyholder.com and editing your checking/savings account.
        At our option, we may treat invalid information, or the subsequent
        malfunction of a previously valid account, as a withdrawal of your
        consent to use our automatic payment options.
      </p>
      <ContentHeader>Termination/Changes</ContentHeader>
      <p>
        We reserve the right, at our sole discretion, to terminate or modify the
        terms and conditions on which we provide UFG’s automatic payment
        options. We will provide you notice of such termination or change as
        required by applicable law.
      </p>
      <ContentHeader>Federal Law</ContentHeader>
      <p>
        You acknowledge and agree that your consent to UFG’s automatic payment
        options is being provided in connection with a transaction affecting
        interstate commerce that may be subject to the federal Electronic
        Signatures in Global and National Commerce Act, and that you and we both
        intend that the Act apply to the fullest extent possible to validate our
        ability to conduct business through electronic means.
      </p>
      <ContentHeader>Further Questions</ContentHeader>
      <p>
        Please contact us if you have any questions about these Automatic
        Payment Terms and Conditions, or UFG’s automatic payment options.
        Questions can be directed to UFG Help Desk at 800-895-6253 or to UFG
        Billing Customer Service at 800-637-6309 between 7 a.m. and 6 p.m. CT
        M-F.
      </p>
    </div>
  );
};
