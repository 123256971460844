import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchQuickLinksStarted = () => ({
  type: types.FETCH_QUICK_LINKS_STARTED
});

const fetchQuickLinksSucceeded = data => ({
  type: types.FETCH_QUICK_LINKS_SUCCEEDED,
  payload: data
});

const fetchQuickLinksFailed = error => ({
  type: types.FETCH_QUICK_LINKS_FAILED,
  payload: error,
  error: true
});

export const fetchQuickLinks = userId => {
  return dispatch => {
    dispatch(fetchQuickLinksStarted());

    return policyholderService.getQuickLinks(userId).then(
      response => dispatch(fetchQuickLinksSucceeded(response.data)),
      error => dispatch(fetchQuickLinksFailed(error.response))
    );
  };
};
