import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchAutoIdCardsStarted = () => ({
  type: types.FETCH_AUTO_ID_CARDS_STARTED
});

const fetchAutoIdCardsSucceeded = data => ({
  type: types.FETCH_AUTO_ID_CARDS_SUCCEEDED,
  payload: data
});

const fetchAutoIdCardsFailed = error => ({
  type: types.FETCH_AUTO_ID_CARDS_FAILED,
  payload: error,
  error: true
});

export const fetchAutoIdCards = () => {
  return dispatch => {
    dispatch(fetchAutoIdCardsStarted());

    return policyholderService.getAccountsWithAutoPolicies().then(
      res => dispatch(fetchAutoIdCardsSucceeded(res.data)),
      error => dispatch(fetchAutoIdCardsFailed(error.response))
    );
  };
};
