import React from "react";
import PropTypes from "prop-types";
import { formatDate, Table, Popover } from "@ufginsurance/ui-kit";
import { Translations } from "./translations";
import BillStatement from "./account-summary/BillStatement";
import SmartPayLink from "../components/SmartPayLink";
import { v4 } from "uuid";

const propTypes = {
  billingStatements: PropTypes.array.isRequired
};

const BillingStatementsTable = ({ billingStatements }) => {
  const { policy_types } = Translations; //eslint-disable-line
  const { billing_types } = Translations.quick_links; //eslint-disable-line

  const renderAccountRow = statement => {
    return (
      <div>
        <strong>{`${billing_types[statement.bill_type]}`}</strong>
      </div>
    );
  };

  const renderPolicyRow = statement => {
    return (
      <div>
        <strong>{`${billing_types[statement.bill_type]}: `}</strong>
        {statement.nickname ||
          `${statement.number} - ${policy_types[statement.policy_type]}`}
      </div>
    );
  };

  const firstRow = statement => {
    switch (statement.bill_type) {
      case "account":
        return renderAccountRow(statement);
      case "policy":
        return renderPolicyRow(statement);
      default:
        return null;
    }
  };

  const formatBillingStatements = statements => {
    const accountNumbers = [...new Set(statements.map(_ => _.account_number))];
    return accountNumbers
      .map(accountNumber => {
        const dateToTime = date => new Date(date).getTime();
        return statements
          .filter(_ => _.account_number === accountNumber)
          .sort(
            (a, b) =>
              dateToTime(b.statement_date) - dateToTime(a.statement_date)
          )
          .slice(0, 3)
          .map(_ => ({ ..._, id: v4() }));
      })
      .flat();
  };

  const columns = [
    {
      key: "number",
      label: "ACCOUNT OR POLICY",
      element: row => firstRow(row)
    },
    {
      key: "statement_date",
      label: "STATEMENT DATE",
      element: row => formatDate(row?.statement_date, "MM/DD/YYYY")
    },
    {
      key: "statement_link",
      label: "ACTION",
      element: row => {
        return row.bill_type === "paygo" ? (
          <Popover
            id="smart-pay-popover"
            trigger="hover"
            triggerContent={
              <div>
                <SmartPayLink />
              </div>
            }
            popoverContent={
              "This Workers' Compensation Policy is managed by SmartPay"
            }
          />
        ) : (
          <BillStatement billStatementLink={row.statement_link} />
        );
      }
    }
  ];

  return (
    <Table
      id="billing-statements-list"
      rowKey="id"
      groupHeaderKey="account_number"
      groupHeaderContents={row => <span>Account # {row.account_number}</span>}
      columns={columns}
      data={formatBillingStatements(billingStatements)}
      showPagination
      itemsPerPage={10}
    />
  );
};

BillingStatementsTable.propTypes = propTypes;

export default BillingStatementsTable;
