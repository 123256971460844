import React from "react";
import PropTypes from "prop-types";
import BillingStatementsTable from "./BillingStatementsTable";
import { Translations } from "./translations";
import { DirectBillEmailLink } from "./email";
import { ContentHeader } from "@ufginsurance/ui-kit";

const propTypes = {
  billingStatements: PropTypes.arrayOf(
    PropTypes.shape({
      bill_type: PropTypes.string.isRequired,
      account_number: PropTypes.string.isRequired,
      number: PropTypes.string,
      nickname: PropTypes.string,
      policy_type: PropTypes.string,
      statement_date: PropTypes.string,
      statement_link: PropTypes.string
    })
  ).isRequired,
  error: PropTypes.object
};

const BillingStatements = ({ billingStatements, error }) => {
  const noBillingInfo = "No billing information available";
  const renderNoPoliciesMessage = () => (
    <p>
      {Translations.formatString(
        Translations.quick_links.view_last_statement.no_bills,
        <DirectBillEmailLink />
      )}
    </p>
  );

  const renderTableIfPolicies = payload => {
    return payload.length === 0 ||
      (error && error.response.data.message === noBillingInfo) ? (
      renderNoPoliciesMessage()
    ) : (
      <BillingStatementsTable billingStatements={payload} />
    );
  };

  return (
    <div>
      <ContentHeader>
        {Translations.quick_links.view_last_statement.title}
      </ContentHeader>
      {renderTableIfPolicies(billingStatements)}
    </div>
  );
};

BillingStatements.propTypes = propTypes;

export default BillingStatements;
