import React from "react";
import {
  Button,
  ContentHeader,
  Form,
  FormGroup,
  Input,
  useForm
} from "@ufginsurance/ui-kit";

const AddAccount = ({
  submitAddAccount,
  handleCancel,
  errorSummary,
  isEditableSession
}) => {
  const initialValues = {
    identifier: "",
    zip: ""
  };

  const handleSubmit = ({ values }) => {
    submitAddAccount(values);
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });

  const { handleOnBlur, handleOnValidate, handleOnChange, values } = form;

  return (
    <div>
      <div>
        <ContentHeader>Add Account/Policy</ContentHeader>
      </div>
      <div className="add-account__info-message">
        <i className="fa fa-info-circle" />
        <span>
          Once added, all policies associated with your account will be
          automatically included.
        </span>
      </div>
      <div className="row">
        <div className="col-xs-12  col-md-8 col-ls-6">{errorSummary}</div>
      </div>
      <Form context={form}>
        <FormGroup>
          <Input
            id="identifier"
            name="identifier"
            label="ACCOUNT/POLICY NUMBER"
            value={values.identifier}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            maxLength={11}
            numbersOnly
            required
          />
        </FormGroup>
        <FormGroup>
          <Input
            id="zip"
            name="zip"
            label="ZIP CODE"
            value={values.zip}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            maxLength={5}
            minLength={5}
            numbersOnly
            required
          />
        </FormGroup>
        <div>
          <p>
            <a
              href="https://www.ufginsurance.com/images/default-source/development-images/common/statement-guide.png"
              target="_blank"
              rel="noopener noreferrer"
            >
              Let us help you locate your policy/account number or name.
            </a>
          </p>
        </div>

        <div className=" row">
          <div className="col-xs-12">
            <Button
              type="submit"
              variant="primary"
              disabled={!isEditableSession}
            >
              Add Account
            </Button>
            &nbsp;
            <Button onClick={handleCancel} disabled={!isEditableSession}>
              Cancel
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddAccount;
