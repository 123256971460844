import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, ButtonBar, Modal } from "@ufginsurance/ui-kit";
import PaperlessUpdateModal from "../payments-and-alerts/PaperlessUpdateModal";
import { Translations } from "../translations";
import HelpModal from "../modals/HelpModal";
import { MY_PROFILE_ROUTE } from "../../constants/routes";
import InfoModal from "../modals/InfoModal";

const RenderToggle = ({
  account,
  toggle,
  toggleType,
  heading,
  number,
  disabled,
  userProfile
}) => {
  const [isConfirmPaperlessVisible, setIsConfirmPaperlessVisible] =
    useState(false);
  const [modalType, setModalType] = useState(null);
  const [updateProfileMessageVisible, setUpdateProfileMessageVisible] =
    useState(false);

  const handleToggle = button => {
    if (toggle === button) return;
    setIsConfirmPaperlessVisible(true);
    setModalType(
      button === "no" || toggleType === "policy_email_alert"
        ? "confirm"
        : "terms"
    );
  };

  const buildPayload = (number, option, name) => {
    const setToggle = option => {
      return option === "yes" ? (option = "no") : (option = "yes");
    };
    option = setToggle(option);
    return {
      number,
      option,
      name,
      handler: () => {}
    };
  };

  return account === "updatable" ? (
    <div className="toggle-item">
      <span className="toggle-item__header">{heading}</span>
      <div className="toggle-item__button">
        <ButtonBar size="sm">
          <Button
            variant={toggle === "yes" && !disabled ? "primary" : "plain"}
            onClick={() => handleToggle("yes")}
            disabled={disabled}
          >
            Yes
          </Button>
          <Button
            variant={toggle === "no" && !disabled ? "tertiary" : "plain"}
            onClick={() => handleToggle("no")}
            disabled={disabled}
          >
            No
          </Button>
        </ButtonBar>
        {isConfirmPaperlessVisible && (
          <Modal
            title={
              modalType === "terms" ? "Terms & Conditions" : "Confirm Update"
            }
            show={isConfirmPaperlessVisible}
            onHide={() => setIsConfirmPaperlessVisible(false)}
            altCloseMethod={false}
            body={
              <PaperlessUpdateModal
                payload={buildPayload(number, toggle, toggleType)}
                userProfile={userProfile}
                hideModal={() => setIsConfirmPaperlessVisible(false)}
              />
            }
          />
        )}
      </div>
    </div>
  ) : account === "not_available" ? (
    <div className="toggle-item">
      <span className="toggle-item__header">{heading}</span>
      <div className="toggle-item__button">
        <div className="toggle-item--not-available">
          Not Available
          <HelpModal
            title={Translations.modal.title.option_not_available}
            modalBody={
              Translations.modal.messages.paperless_billing.option_not_available
            }
            icon="fasQuestionCircle"
            color="primary"
            btnSize="medium"
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="toggle-item">
      <span className="toggle-item__header">
        {heading}
        {updateProfileMessageVisible ? (
          <InfoModal
            title={Translations.modal.title.update_your_profile}
            hideModal={() => setUpdateProfileMessageVisible(false)}
          >
            <p>
              {`${Translations.modal.messages.paperless_billing.update_your_profile} `}
              <Link to={MY_PROFILE_ROUTE}>My Profile</Link>
            </p>
          </InfoModal>
        ) : null}
      </span>
      <div className="toggle-item__button">
        <ButtonBar size="sm">
          <Button
            variant={toggle === "yes" && !disabled ? "primary" : "plain"}
            onClick={() => setUpdateProfileMessageVisible(true)}
            disabled={disabled}
          >
            Yes
          </Button>
          <Button
            variant={toggle === "no" && !disabled ? "tertiary" : "plain"}
            onClick={() => setUpdateProfileMessageVisible(true)}
            disabled={disabled}
          >
            No
          </Button>
        </ButtonBar>
      </div>
    </div>
  );
};

RenderToggle.propTypes = {
  account: PropTypes.string.isRequired,
  toggle: PropTypes.string.isRequired,
  toggleType: PropTypes.oneOf([
    "bill_email_alert",
    "paperless_bill",
    "policy_email_alert"
  ]).isRequired,
  heading: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  userProfile: PropTypes.object
};

export default RenderToggle;
