import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Alert, Button, ContentHeader } from "@ufginsurance/ui-kit";
import ViewSecurityQuestions from "./ViewSecurityQuestions";
import * as routes from "../../constants/routes";

const ViewMyProfile = ({
  changeSucceeded,
  userProfile,
  submissionMessage,
  trackAnalytics,
  isEditableSession,
  history
}) => (
  <div id={`detail-${userProfile.username}`}>
    <div>
      <div className="row">
        <div className="col-xs-12">
          <ContentHeader>My Profile</ContentHeader>
        </div>
      </div>
      {!!submissionMessage && (
        <div className="row">
          <div className="col-xs-12">
            <Alert type="success">{submissionMessage}</Alert>
          </div>
        </div>
      )}
      {!!changeSucceeded && (
        <div className="row">
          <div className="col-xs-12">
            <Alert type="success">Password successfully updated.</Alert>
          </div>
        </div>
      )}
    </div>
    <div className="detail-table">
      <div className="detail-table__row row even">
        <div className="detail-table__heading col-xs-6 col-sm-6 col-md-4">
          Email Address
        </div>
        <div className="col-xs-6 col-sm-6 col-md-8">{userProfile.email}</div>
      </div>
      <div className="detail-table__row row odd">
        <div className="detail-table__heading col-xs-6 col-sm-6 col-md-4">
          Preferred Phone Number
        </div>
        <div className="col-xs-6 col-sm-6 col-md-8">{userProfile.phone}</div>
      </div>
      {userProfile.security_questions && (
        <ViewSecurityQuestions
          securityQuestions={userProfile.security_questions}
        />
      )}
    </div>
    <div className="row" />
    <div className="detail-table">
      <div className="detail-table__row row">
        <div className="col-xs-12">
          {isEditableSession && (
            <div>
              <Button
                variant="primary"
                onClick={e => {
                  trackAnalytics(e);
                  history.push(routes.EDIT_MY_PROFILE_ROUTE);
                }}
              >
                Edit Profile
              </Button>
              &nbsp;
              <Button
                variant="outline"
                onClick={e => {
                  trackAnalytics(e);
                  history.push(routes.MY_PROFILE_CHANGE_PASSWORD_ROUTE);
                }}
              >
                Change Password
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

ViewMyProfile.propTypes = {
  userProfile: PropTypes.object.isRequired,
  submissionMessage: PropTypes.string,
  changeSucceeded: PropTypes.bool,
  trackAnalytics: PropTypes.func.isRequired,
  isEditableSession: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default withRouter(ViewMyProfile);
