import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchAccountsStarted = () => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_STARTED
});

const fetchAccountsSucceeded = data => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_SUCCEEDED,
  payload: data
});

const fetchAccountsFailed = error => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_FAILED,
  payload: error,
  error: true
});

const fetchAccountDetailsStarted = () => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_STARTED
});

const fetchAccountDetailsSucceeded = data => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_SUCCEEDED,
  payload: data
});

const fetchAccountDetailsFailed = error => ({
  type: types.FETCH_INSTALLMENT_SCHEDULE_DETAILS_FAILED,
  payload: error,
  error: true
});

export function fetchInstallmentScheduleAccountList() {
  return dispatch => {
    dispatch(fetchAccountsStarted());

    return policyholderService.fetchInstallmentScheduleAccounts().then(
      res => {
        dispatch(fetchAccountsSucceeded(res.data));
      },
      error => {
        dispatch(fetchAccountsFailed(error));
      }
    );
  };
}

export function fetchInstallmentScheduleAccountDetails(accountNumber) {
  return dispatch => {
    dispatch(fetchAccountDetailsStarted());

    return policyholderService
      .fetchInstallmentScheduleAccountDetails(accountNumber)
      .then(
        res => dispatch(fetchAccountDetailsSucceeded(res.data)),
        error => dispatch(fetchAccountDetailsFailed(error.response))
      );
  };
}
