import { createSlice } from "@reduxjs/toolkit";
import { getHelpDeskInfo } from "../services/policyholderService";
import { logger } from "../logging";

const helpDeskSlice = createSlice({
  name: "helpDeskState",
  initialState: {
    isLoading: false,
    data: null,
    error: false
  },
  reducers: {
    started: state => {
      state.isLoading = true;
      state.data = null;
      state.error = false;
    },
    succeeded: (state, action) => {
      state.isLoading = false;
      state.data = action?.payload;
      state.error = false;
    },
    failed: state => {
      state.isLoading = false;
      state.data = null;
      state.error = true;
    }
  }
});

export const fetchHelpDeskInfo = () => dispatch => {
  const { started, succeeded, failed } = helpDeskSlice.actions;
  const onError = e => {
    dispatch(failed());
    logger.error({
      description: e.toString(),
      fatal: true
    });
  };
  dispatch(started());
  return getHelpDeskInfo().then(
    response => {
      if (response?.status === 200) dispatch(succeeded(response?.data));
      else onError(response);
    },
    error => onError(error)
  );
};

export default helpDeskSlice.reducer;
