import React from "react";
import { SidebarNav } from "../ui-kit";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";

const links = [
  {
    to: routes.BILLING_ACTIVITY_ROUTE,
    activeClassName: "nav-menu--active",
    text: Translations.billing_activity.billing_activity,
    iconClass: "fa fa-caret-right"
  },
  {
    to: routes.POLICY_BALANCE_REMAINING_ROUTE,
    activeClassName: "nav-menu--active",
    text: Translations.billing_activity.policy_balance_remaining,
    iconClass: "fa fa-caret-right"
  }
];

const BillingActivityNav = () => {
  return (
    <SidebarNav
      links={links}
      header={Translations.billing_activity.billing_activity}
    />
  );
};
export default BillingActivityNav;
