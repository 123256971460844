import { SYSTEMS_UNAVAILABLE_ROUTE } from "../../constants/routes";

const SystemsAvailable = ({ children }) => {
  /*
    Can't figure out syntax to make Route only show component when path
    does NOT match SYSTEMS_UNAVAILABLE_ROUTE.
    https://reacttraining.com/react-router/web/api/Route/path-string
    https://forbeslindesay.github.io/express-route-tester/
   */
  return window.location.pathname !== SYSTEMS_UNAVAILABLE_ROUTE
    ? children
    : null;
};

export default SystemsAvailable;
