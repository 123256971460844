import React from "react";
import PropTypes from "prop-types";
import { Button } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";

const renderElections = data => {
  data = data.account_elections;

  return (
    <div>
      <ul>
        {(data.payment_type === "eft" || data.payment_type === "rbp") && (
          <li>
            {Translations.formatString(
              Translations.prev_reg.auto_payments,
              Translations.payment.sources[data.payment_source]
            )}
          </li>
        )}
        {data.paperless_bill === "yes" && <li>Paperless Billing</li>}
        {data.bill_email_alert === "yes" && <li>Payment Email Alerts</li>}
        {data.policy_email_alert === "yes" && <li>Policy Email Alerts</li>}
      </ul>
    </div>
  );
};

const AddPrevRegistered = ({ data, handleCancel, handleSubmit }) => {
  return (
    <div className="add-prev-reg__modal-wrapper">
      <div className="add-prev-reg__modal-content">
        <h3>{Translations.prev_reg.prompt}</h3>
        {renderElections(data)}
        <p>{Translations.prev_reg.acknowledge}</p>
        <p>{Translations.prev_reg.acknowledge2}</p>

        <div className="detail-table">
          <div className="detail-table__row row">
            <div className="add-prev-reg__submit-group col-xs-12">
              <Button variant="primary" onClick={handleSubmit}>
                I Understand
              </Button>
              &nbsp;
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AddPrevRegistered.propTypes = {
  data: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default AddPrevRegistered;
