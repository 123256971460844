import React from "react";
import { Link } from "react-router-dom";
import DocumentService from "../../services/documentService";
import { getPdf } from "../../services/policyholderService";
import { LOADING_DOCUMENT_ROUTE } from "../../constants/routes";

const documentType = (description, declaration_description, origin) =>
  declaration_description && origin === "ufg_core"
    ? `${declaration_description} - ${description}`
    : description;

const handleGetPdf = (policyNumber, description, token) => event => {
  event.stopPropagation();
  const documentRenderer = new DocumentService();
  documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);
  getPdf(
    `${
      window.env.REACT_APP_POLICYHOLDER_ACCOUNTS_EAPI_MULE_4_URI
    }/policies/${policyNumber}/documents/activity/pdf?token=${btoa(
      JSON.stringify(token)
    )}`
  ).then(
    response => {
      documentRenderer.saveOrOpenDocument(response.data, `${description}.pdf`);
    },
    () => {
      documentRenderer.clearRenderer();
    }
  );
};

export const renderFormLink = (policyNumber, policy, origin) => {
  return (
    <Link
      to="#"
      onClick={handleGetPdf(policyNumber, policy.description, policy.id)}
      color="tertiary"
    >
      <span>
        {documentType(
          policy.description,
          policy.declaration_type_description,
          origin
        )}
      </span>
    </Link>
  );
};
