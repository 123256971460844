import { combineReducers } from "redux";
import accountData from "./accountDataReducer";
import autoIdCardsData from "./autoIdCardsReducer";
import quickLinksData from "./quickLinksReducer";
import profileData from "./profileDataReducer";
import changePasswordData from "./changePasswordDataReducer";
import addAccountData from "./addAccountDataReducer";
import editProfileData from "./editProfileDataReducer";
import submitProfileData from "./submitEditProfileReducer";
import automaticPaymentsData from "./automaticPaymentsReducer";
import billingAndAlertsData from "./billingAndAlertsReducer";
import installmentScheduleData from "./installmentScheduleReducer";
import billingActivityData from "./billingActivityReducer";
import policyBalanceRemainingData from "./policyBalanceRemainingReducer";
import myPolicyData from "./myPolicyReducer";
import policyActivityData from "./policyActivityReducer";
import myPolicyFormsData from "./myPolicyFormsReducer";
import claimsAccountsData from "./claimsAccountsReducer";
import openClaimsData from "./openClaimsReducer";
import claimsHistoryData from "./claimsHistoryReducer";
import userMigration from "./userMigrationReducer";
import companyNotifications from "./companyNotificationReducer";
import myAgent from "./myAgentReducer";
import reregistrationData from "./reregisterAccountReducer";
import billingStatementData from "./billingStatementsReducer";
import helpDeskState from "../help-desk/behaviors";
import { reducer as oidc } from "redux-oidc";
import { ssoSession } from "@ufginsurance/sso-oidc-client-react";

const rootReducer = combineReducers({
  oidc,
  ssoSession,
  accountData,
  autoIdCardsData,
  quickLinksData,
  profileData,
  changePasswordData,
  addAccountData,
  editProfileData,
  submitProfileData,
  automaticPaymentsData,
  billingAndAlertsData,
  installmentScheduleData,
  billingActivityData,
  policyBalanceRemainingData,
  myPolicyData,
  policyActivityData,
  myPolicyFormsData,
  claimsAccountsData,
  openClaimsData,
  claimsHistoryData,
  userMigration,
  companyNotifications,
  myAgent,
  reregistrationData,
  billingStatementData,
  helpDeskState
});

export default rootReducer;
