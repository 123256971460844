import React from "react";
import { Translations } from "../translations";
import { HelpDeskEmailLink } from "../email";
import { ContentHeader } from "@ufginsurance/ui-kit";

const SystemsUnavailable = () => (
  <div>
    <ContentHeader>Systems Unavailable</ContentHeader>
    <p className="alert alert-danger">
      {Translations.formatString(
        Translations.error_messages.error_503,
        <HelpDeskEmailLink />
      )}
    </p>
  </div>
);

export default SystemsUnavailable;
