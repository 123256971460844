import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchMyAgentStarted = () => ({
  type: types.FETCH_MY_AGENT_STARTED
});

export const fetchMyAgentSucceeded = data => ({
  type: types.FETCH_MY_AGENT_SUCCEEDED,
  payload: data
});

export const fetchMyAgentFailed = error => ({
  type: types.FETCH_MY_AGENT_FAILED,
  payload: error,
  error: true
});

export const fetchMyAgent = () => dispatch => {
  dispatch(fetchMyAgentStarted());

  return policyholderService.fetchMyAgent().then(
    response => {
      dispatch(fetchMyAgentSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchMyAgentFailed(error));
    }
  );
};
