import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";

const PaymentOptions = () => {
  return (
    <div className="payment-options">
      <ContentHeader>Payment Options</ContentHeader>
      <p>
        We offer a variety of simple and secure payment options for our
        customers, including automatic payments, one-time payments and mail
        payments. Choose whichever option is most convenient for you!
      </p>
      <div>
        <ContentHeader>AUTOMATIC PAYMENTS</ContentHeader>
        <p>
          You can have payments automatically deducted from your checking or
          savings account each month, with no installment fees, no late fees and
          no worries.
        </p>
        <p>Choose one of the following payment methods:</p>
        <ul>
          <li>
            Checking account: You must provide the account number and routing
            number for your bank.
          </li>
          <li>
            Savings account: You must provide the account number and routing
            number for your bank.
          </li>
        </ul>
        <h2 className="payment-options__secondary-header">
          Automatic Payments: What You Need To Know
        </h2>
        <ul>
          <li>
            After completing the enrollment process, automatic payments will
            become effective on the next bill that is generated. If you
            currently have a payment due, you must make a one-time payment to
            avoid late fees, cancellation or termination.
          </li>
          <li>
            By enrolling in automatic payments, you will no longer receive a
            monthly billing statement unless your account activity results in a
            charge to the monthly withdrawal amount of more than $1. If the
            withdrawal amount changes by more than $1, we will send you a
            one-time notification.
          </li>
          <li>
            Changes to or cancellation of your automatic payment enrollment must
            be made at least one calendar day prior to the date your payment is
            to be withdrawn from your checking/savings account.
          </li>
          <li>
            If you are currently enrolled in automatic payments and would like
            to cancel or change your payment method, visit the Automatic
            Payments section of ufgPolicyholder.com. If you have any questions
            about canceling or changing your payment method, contact UFG Billing
            Customer Service at 800-637-6309 between 7 a.m. and 6 p.m. Monday
            through Friday.
          </li>
        </ul>
      </div>
      <div>
        <ContentHeader>One-Time Payments</ContentHeader>
        <p>
          You can make a one-time payment to your account online or by phone at
          800-450-9239, 24 hours a day and seven days a week.
        </p>
        <p>Choose one of the following payment methods:</p>
        <ul>
          <li>
            Credit card: We accept Visa, MasterCard, Discover and American
            Express
          </li>
          <li>
            Debit card: We accept cards with a Pulse, Star, Nyce or Accel logo
          </li>
          <li>
            Electronic check: You must provide the account number and routing
            number for your checking/savings account
          </li>
        </ul>
        <p>
          Note: The pay-in-full amount is required for payments made with a
          credit/debit card.
        </p>
        <h2 className="payment-options__secondary-header">
          One-Time Payments: What You Need To Know
        </h2>
        <ul>
          <li>You can pay one policy or account at a time.</li>
          <li>
            You can pay any amount between the minimum due and the total balance
            unless paying with a credit/debit card, which requires the
            pay-in-full amount of the policy/account.
          </li>
          <li>
            Transactions are not considered payment if returned by your
            financial institution for any reason.
          </li>
          <li>Payments can be scheduled up to 15 days in the future. </li>
          <li>
            Payments that are not received by the due date may be assessed a
            late fee.
          </li>
          <li>
            Payments made after 7:30 p.m. CT will not be considered paid until
            the next business day.
          </li>
          <li>
            You cannot make a one-time payment on policies/accounts that are
            expired or canceled, billed to your mortgage holder or other
            designee, have a zero balance or that are already set up for
            automatic payments.
          </li>
        </ul>
      </div>
      <div>
        <ContentHeader>Mail Payments</ContentHeader>
        <p>
          You can mail a check or money order to UFG via regular mail or express
          mail.
        </p>
        <p>Choose one of the following addresses:</p>
        <div className="payment-options__mail-payments">
          <p>For payments with a remittance slip, mail to:</p>
          <p>
            <strong>UFG Insurance</strong>
            <br />
            PO Box 3244
            <br />
            Cedar Rapids, IA 52406-3244
          </p>
        </div>
        <div className="payment-options__mail-payments">
          <p>For payments without a remittance slip, mail to:</p>
          <p>
            <strong>UFG Insurance</strong>
            <br />
            PO Box 73909
            <br />
            Cedar Rapids, IA 52407-3909
          </p>
        </div>
        <div className="payment-options__mail-payments">
          <p>For overnight payments (FedEx, UPS, USPS), mail to:</p>
          <p>
            <strong>UFG Insurance</strong>
            <br />
            118 2nd Avenue SE
            <br />
            Cedar Rapids, IA 52401
          </p>
        </div>
        <p>
          Question about your bill or payment? Contact UFG Billing Customer
          Service at 800-637-6309 between 7 a.m. and 6 p.m. Monday through
          Friday.
        </p>
      </div>
    </div>
  );
};

export default PaymentOptions;
