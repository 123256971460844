//Define image path constants for CDN images only.  Import images directly for project specific images
export const headerLogo = `${window.env.REACT_APP_IMAGES_CDN_URL}/customer-portal/logo.png`;

export const images = [
  {
    action: `${window.env.REACT_APP_PUBLICSITE_BASEURL}/insurance/services/risk-control`,
    imageSource: `${window.env.REACT_APP_IMAGES_CDN_URL}/customer-portal/risk-control.png`,
    alternateText: "UFG Risk Control Services"
  }
];

export const routingAccountNumberCheckImage = `${window.env.REACT_APP_IMAGES_CDN_URL}/common/check-image.png`;

export default {
  headerLogo,
  images
};
