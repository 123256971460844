import { Translations } from "../components/translations/Translations";

class DocumentService {
  createNewWindow(url) {
    const newWindow = window.open(url);
    newWindow.document.title =
      Translations.loading_messages.loading_with_ellipsis;
    return newWindow;
  }

  closeNewWindow() {
    this.newWindow.close();
  }

  createPDF(blob) {
    return new Blob([blob], { type: "application/pdf" });
  }

  createBlobURL(pdf) {
    return window.URL.createObjectURL(pdf);
  }

  initializeRenderer(url) {
    if (this.requiresNewWindow()) {
      this.newWindow = this.createNewWindow(url);
    }
  }

  saveOrOpenDocument(blob, filename) {
    if (this.isEdgeOrIE()) {
      this.openAsMSBlobDownload(blob, filename);
    } else {
      const file = this.createPDF(blob);
      const fileURL = this.createBlobURL(file);
      if (this.isAndroidDevice()) {
        this.openAsHTML5Download(fileURL, filename);
      } else if (this.isChromeOnIOS()) {
        this.openAsDataURL(file);
      } else {
        this.openAsBlobUri(fileURL);
      }
    }
  }

  clearRenderer() {
    if (this.requiresNewWindow()) {
      this.closeNewWindow();
    }
  }

  openAsDataURL(blob) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        const a = document.createElement("a");
        a.style.display = "none";
        a.setAttribute("href", reader.result);
        document.body.appendChild(a);
        a.click();
      },
      false
    );
    reader.readAsDataURL(blob);
  }

  openAsMSBlobDownload(blob, filename) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  }

  openAsBlobUri(fileURL) {
    this.newWindow.location = fileURL;
  }

  openAsHTML5Download(fileURL, filename) {
    const a = document.createElement("a");
    a.style.display = "none";
    a.setAttribute("href", fileURL);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
  }

  requiresNewWindow() {
    return (
      !this.isEdgeOrIE() && !this.isAndroidDevice() && !this.isChromeOnIOS()
    );
  }

  isEdgeOrIE() {
    return window.navigator && window.navigator.msSaveOrOpenBlob;
  }

  isAndroidDevice() {
    return window.navigator.userAgent.match(/Android/i);
  }

  isChromeOnIOS() {
    return (
      window.navigator.userAgent.match(/iPhone|iPad|iPod/i) &&
      window.navigator.userAgent.match(/CriOS/i)
    );
  }
}

export default DocumentService;
