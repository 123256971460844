import API from "../api";
import qs from "query-string";
import { formatDate } from "@ufginsurance/ui-kit";

const baseUIUrl = window.env.REACT_APP_BASEURL;

///
/// pdfs
/// -----

export const getPdf = (url, options = {}) => {
  const fullyQualifiedUrl = URL.parse(url);
  return fullyQualifiedUrl
    ? API.baseless().get(fullyQualifiedUrl, {
        ...options,
        responseType: "blob"
      })
    : API.base().get(url, { ...options, responseType: "blob" });
};

export const fetchPdfs = (url, formList) =>
  API.base().post(url, formList, {
    "Content-Type": "application/json",
    responseType: "blob"
  });

///
/// general
/// -----

export const getQuickLinks = userId =>
  API.policyHolder().get(`/quick-links?user_id=${userId}`);

export const getHelpDeskInfo = () => API.policyHolder().get(`/help-desk`);

///
/// account
/// -----

export const fetchBillingActivityAccounts = () =>
  API.accounts().get("/accounts");

export const fetchClaimsAccountsData = () => API.accounts().get("/accounts");

export const getBillingStatements = () => API.accounts().get("/accounts/bills");

export const getAccountsWithAutoPolicies = () =>
  API.accounts().get("/accounts/auto-id-cards");

export const getAccountSummary = shouldRevalidate => {
  return API.accounts().get(
    "/accounts/summary",
    shouldRevalidate && {
      headers: { "Cache-Control": "must-revalidate" }
    }
  );
};

//{account_id}/bill

export const fetchOpenClaimsData = (number, status) =>
  API.accounts().get(`/accounts/${number}/claims?status=${status}`);

export const fetchHistoryClaimsData = (number, status) =>
  API.accounts().get(`/accounts/${number}/claims?status=${status}`);

//{{account_id}/policies

export const submitAddAccount = payload =>
  API.accounts().post("/accounts/registration", payload);

export const patchAcknowledgePaperlessTerms = number =>
  API.accounts().patch(
    `/accounts/${number}/acknowledgements?acknowledge_type=has_reviewed_paperless_terms`
  );

export const submitAccountReregistrationConfirmation = payload =>
  API.accounts().post("/accounts/registration/complete", payload);

export const fetchBillingAndAlertsAccounts = () =>
  API.accounts().get("/accounts/alerts");

export const submitBillingAndAlerts = payload =>
  API.accounts().post("/accounts/alerts", payload);

export const fetchGuidewireConversions = () =>
  API.accounts().get("/accounts/gw-conversions");

export const postGWConfirmation = () =>
  API.accounts().post("/accounts/gw-conversions", JSON.stringify(""), {
    headers: { "Content-Type": "application/json" }
  });

export const fetchCompanyNotifications = () =>
  API.admin().get("/admin/notifications");

export const fetchMyAgent = () => API.policyHolder().get(`/agencies`);

///
/// billing
/// -----

export const fetchAutomaticPaymentAccounts = () =>
  API.billing().get("/billing/automatic-payments");

export const fetchPaymentVendorRoute = (accountNumber, returnUrl) =>
  API.billing().get(
    `/billing/automatic-payments/enrollment/${accountNumber}?cancellation_url=${
      returnUrl || baseUIUrl
    }`
  );

export const fetchInstallmentScheduleAccounts = () =>
  API.billing().get("/billing/installment-schedules");

export const fetchInstallmentScheduleAccountDetails = accountNumber =>
  API.billing().get(`/billing/installment-schedules/${accountNumber}`);

export const fetchBillingActivityDetails = (
  accountNumber,
  activity_date_from,
  activity_date_to
) => {
  let queryString;
  if (activity_date_from && activity_date_to) {
    queryString = qs.stringify({
      activity_date_from: formatDate(activity_date_from, "YYYY-MM-DD"),
      activity_date_to: formatDate(activity_date_to, "YYYY-MM-DD")
    });
    return API.billing().get(
      `billing/activity/${accountNumber}?${queryString}`
    );
  }
  return API.policyHolder().get(`billing/activity/${accountNumber}`);
};

export const fetchPolicyBalanceRemainingDetails = accountNumber =>
  API.billing().get(`/billing/activity/${accountNumber}/unpaid-policy`);

///
/// policies
/// -----

export const fetchMyPoliciesList = () => API.policies().get("/policies");

export const fetchPolicyActivity = policyNumber =>
  API.policies().get(`/policies/${policyNumber}/activity`);

export const fetchPolicyDecs = (policyNumber, company_branch, effective_date) =>
  API.policies().get(
    `/policies/${policyNumber}/coverage-options?effective_date=${effective_date}&company_branch=${company_branch}`
  );

export const fetchPolicyForms = (policyNumber, coverageOption, paramsString) =>
  API.policies().get(
    `/policies/${policyNumber}/coverage-forms/${coverageOption}${paramsString}`
  );

export const submitNickname = (policyNumber, payload) =>
  API.policies().post(`/policies/${policyNumber}/nickname`, payload);

export const fetchCancellationNotice = policyNumber =>
  API.policies().get(`/policies/${policyNumber}/cancellation-notice`, {
    responseType: "blob"
  });

///
/// profile
/// -----

export const getUserProfile = () => API.profile().get("/profile");

export const submitEditProfile = userProfile =>
  API.profile().put("/profile", userProfile);

export const submitChangePassword = payload =>
  API.profile().put("/profile/password", payload);

export const fetchSecurityQuestions = () =>
  API.profile().get(`/profile/security-questions`);

///
/// feedback
/// -----
export const postFeedback = body => API.policyHolder().post("/feedback", body);
