import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ChangePassword from "../components/my-profile/ChangePassword";
import {
  submitChangePassword,
  clearRedirectToMyProfileNotification
} from "../actions/changePasswordActions";
import { logger } from "../logging";
import LoadingIndicator from "../components/loading-indicator";
import { AnalyticCategories } from "../constants/analytics";
import { Translations } from "../components/translations";
import CustomErrorView from "../components/CustomErrorView";
import { MY_PROFILE_ROUTE } from "../constants/routes";

class ChangePasswordContainer extends Component {
  static propTypes = {
    changePasswordData: PropTypes.object.isRequired,
    submitChangePassword: PropTypes.func.isRequired,
    clearRedirectToMyProfileNotification: PropTypes.func.isRequired
  };

  state = {
    cancelAndRedirectToMyProfile: false
  };

  mapValuesToPayload = values => {
    return {
      old_password: values.currentPassword,
      new_password: values.newPassword
    };
  };

  componentWillUnmount() {
    this.props.clearRedirectToMyProfileNotification();
  }

  trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.profile,
      action: "The password was changed successfully"
    });
  };

  handleSubmit = values => {
    this.props.submitChangePassword(this.mapValuesToPayload(values));
  };

  handleCancel = () => {
    this.setState({ cancelAndRedirectToMyProfile: true });
  };

  render() {
    const { redirectToMyProfile, isLoading, error } =
      this.props.changePasswordData;
    const { cancelAndRedirectToMyProfile } = this.state;

    if (redirectToMyProfile) {
      this.trackAnalytics();
      return <Redirect to={MY_PROFILE_ROUTE} />;
    }

    if (cancelAndRedirectToMyProfile) {
      return <Redirect to={MY_PROFILE_ROUTE} />;
    }

    let errorSummary = null;
    if (error) {
      const errorMessage = `Error fetching changing password: ${JSON.stringify(
        error
      )}`;

      logger.error({ description: errorMessage, fatal: false });

      if (error.response.status === 403) {
        errorSummary = this.getErrorDisplay(error.response);
      } else {
        errorSummary = (
          <CustomErrorView
            error={error.response}
            errorLocationInTranslation={
              Translations.change_password.error_codes
            }
          />
        );
      }
    }

    return (
      <div className="form-controls">
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div>
            <ChangePassword
              handleCancel={this.handleCancel}
              submitChangePassword={this.handleSubmit}
              errorSummary={errorSummary}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  changePasswordData: state.changePasswordData
});

const mapDispatchToProps = {
  submitChangePassword,
  clearRedirectToMyProfileNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);
