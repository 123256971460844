import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const submitEditProfileReducer = (
  state = initialState.submitEditProfile,
  action
) => {
  switch (action.type) {
    case types.SUBMIT_EDIT_PROFILE_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.SUBMIT_EDIT_PROFILE_SUCCEEDED:
      return {
        ...state,
        userProfile: action.payload.userProfile,
        redirectToMyProfile: !action.payload?.showAutopayEmailModalAfterSubmit,
        openAutopayEmailModal: action.payload?.showAutopayEmailModalAfterSubmit,
        submissionMessage: action.payload.submissionMessage,
        submitEditProfileSuccess: true,
        isLoading: false,
        error: null
      };
    case types.SUBMIT_EDIT_PROFILE_FAILED:
      return {
        ...state,
        userProfile: null,
        isLoading: false,
        error: action.payload
      };
    case types.CLEAR_EDIT_PROFILE_SUBMISSION:
      return initialState.submitEditProfile;
    case types.CLEAR_MY_PROFILE_REDIRECT:
      return { ...state, redirectToMyProfile: false };
    default:
      return state;
  }
};

export default submitEditProfileReducer;
