export const AUTOMATIC_PAYMENTS_ELIGIBLE_ACCOUNTS_ROUTE =
  "/payments-alerts/automatic-payments";
export const MY_PROFILE_ROUTE_ROOT = "/my-profile";
export const MY_PROFILE_ROUTE = "/my-profile/home";
export const EDIT_MY_PROFILE_ROUTE = "/my-profile/edit";
export const MY_PROFILE_CHANGE_PASSWORD_ROUTE = "/my-profile/change-password";
export const MY_PROFILE_ADD_ACCOUNT_ROUTE = "/my-profile/add-account";
export const SSO_LOGOUT_ROUTE = "/logout";
export const ROOT_ROUTE = "/";
export const ACCOUNTS_ROUTE = "/account";
export const BILLING_STATEMENTS_ROUTE = "/accounts/bills";
export const BILLING_ACTIVITY_TAB_ROOT_ROUTE = "/account/billing";
export const BILLING_ACTIVITY_ROUTE = "/account/billing/activity";
export const BILLING_ACTIVITY_OPTIONAL_ACCOUNT_PARAM =
  "/account/billing/activity/:accountNumber?";
export const BILLING_ACTIVITY_ACCOUNT_POLICY_ROUTE =
  "/account/billing/activity/:accountNumber?/:policyNumber?";
export const BILLING_ACTIVITY_ROUTE_PDF =
  "/billing/activity/:accountNumber/pdf";
export const AUTO_ID_CARDS_ROUTE = "/accounts/autoidcards";
export const POLICY_ROUTE = "/policy";
export const POLICY_ROUTE_OPTIONAL_ACCOUNT_PARAM = "/policy/:accountNumber?";
export const CLAIMS_ROUTE_ROOT = "/claims";
export const CLAIMS_ROUTE = "/claims/:claimsStatus?";
export const DEFAULT_CLAIMS_ROUTE = "/claims/open";
export const CLAIMS_HISTORY_ROUTE = `${CLAIMS_ROUTE_ROOT}/history`;
export const PAYMENT_ROUTE = "/payment";
export const INSTALLMENT_ROUTE = "/installment";
export const INSTALLMENT_ROUTE_OPTIONAL_ACCOUNT_PARAM =
  "/installment/:accountNumber?";
export const INSTALLMENT_ROUTE_PDF = "/billing/installment-schedules/pdf";
export const BILLING_AND_ALERTS_ROUTE =
  "/my-profile/paperless-billing-and-alerts";
export const ERROR_NOT_FOUND_ROUTE = "*";
export const EMPTY_ROUTE = "/empty";
export const LOADING_DOCUMENT_ROUTE = "/loading-document";
export const PAYMENTS_AND_ALERTS_TAB_ROOT_ROUTE = "/payments-alerts";
export const PAYMENT_OPTIONS = "/payment-options";
export const POLICY_BALANCE_REMAINING_ROUTE = "/account/billing/unpaid-policy";
export const POLICY_BALANCE_REMAINING_ROUTE_PDF =
  "/billing/activity/unpaid-policy/pdf";
export const MY_POLICY_ALL_FORMS_PDF = "/policies/:policy_id/documents";
export const POLICY_HISTORY = "/policies/:policy_id/documents/history";
export const POLICY_HISTORY_DOCUMENT =
  "/policies/:policy_id/documents/history/pdf?token=";
export const SYSTEMS_UNAVAILABLE_ROUTE = "/systems-unavailable";
