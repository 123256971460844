import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";

class LoadingIndicator extends React.Component {
  static propTypes = {
    message: PropTypes.string
  };

  static defaultProps = {
    message: Translations.loading_messages.loading_with_ellipsis
  };

  state = {
    isVisible: false
  };

  // Delaying showing of loading indicator so that we don't
  // have flashing or flickering of the modal on fast api calls
  // https://yuiblog.com/blog/2010/11/11/improving-perceived-site-performance-with-spinners/
  delayShowing = () => {
    this.timeout = setTimeout(() => {
      this.setState({ isVisible: true });
    }, 250);
  };

  componentDidMount() {
    this.delayShowing();
  }

  componentWillUnmount() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
  }

  render() {
    const { message } = this.props;
    const { isVisible } = this.state;

    return (
      isVisible && (
        <div className="loading-modal modal fade in" role="presentation">
          <div className="modal-dialog" role="document">
            <div className="loading-modal__content modal-content">
              <div className="loading-modal__loader" />
              <div className="loading-modal__message">{message}</div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default LoadingIndicator;
