import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchSecurityQuestionsStarted = () => ({
  type: types.FETCH_SECURITY_QUESTIONS_STARTED
});

const fetchSecurityQuestionsSucceeded = data => ({
  type: types.FETCH_SECURITY_QUESTIONS_SUCCEEDED,
  payload: data
});

const fetchSecurityQuestionsFailed = error => ({
  type: types.FETCH_SECURITY_QUESTIONS_FAILED,
  payload: error,
  error: true
});

// Async action creator using thunk middleware
export function fetchSecurityQuestions() {
  return dispatch => {
    dispatch(fetchSecurityQuestionsStarted());

    return policyholderService.fetchSecurityQuestions().then(
      response => {
        dispatch(fetchSecurityQuestionsSucceeded(response.data));
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(fetchSecurityQuestionsFailed(error));
      }
    );
  };
}
