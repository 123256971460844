import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AccountBilling from "./AccountBilling";
import PaperlessBillingAlert from "./PaperlessBillingAlert";
import { Translations } from "../translations";
import { DirectBillEmailLink } from "../email";
import { ContentHeader, Modal, ButtonDropNav } from "@ufginsurance/ui-kit";
import InfoModal from "../modals/InfoModal";
import PaperlessUpdateModal from "../payments-and-alerts/PaperlessUpdateModal";
import { v4 } from "uuid";
import { PaymentTypes } from "../../constants/enumerations";
import "./_AccountList.scss";
import { Button } from "react-bootstrap";

const propTypes = {
  accounts: PropTypes.array.isRequired,
  addAccountSuccess: PropTypes.string,
  fetchCancellationNotice: PropTypes.func.isRequired,
  billingsAndAlerts: PropTypes.object,
  accountsLinked: PropTypes.array
};

const AccountList = ({
  accounts,
  addAccountSuccess,
  fetchCancellationNotice,
  billingsAndAlerts,
  accountsLinked
}) => {
  const [isHidePaperlessModal, setIsHidePaperlessModal] = useState(
    billingsAndAlerts.isAcknowledgedPaymentModal
  );
  const isFirstTimeLoggingIn =
    (accounts.length === 1 ||
      (accounts.length === 2 && accountsLinked?.length > 0)) &&
    billingsAndAlerts?.alertsData?.every(
      _ => _.paperless_bill.has_reviewed_paperless_terms === false
    ) &&
    billingsAndAlerts?.alertsData?.some(
      _ => _.paperless_bill.eligibility === "updatable"
    );

  const hasActivePolicies = account => account.policies.length > 0;
  const hasAccountWithoutPaperlessBilling = billingsAndAlerts?.alertsData?.some(
    a => a.paperless_bill.option === "no"
  );

  const [isShowLinkedAccountsModal, setIsShowLinkedAccountsModal] =
    useState(false);

  useEffect(() => {
    setIsShowLinkedAccountsModal(
      accountsLinked?.length > 0 && isHidePaperlessModal
    );
  }, [accountsLinked, isHidePaperlessModal]);

  const renderLinkedAccountsMessage = (
    <div className="linked-accounts-modal">
      We found your other accounts and have added them for you.
      <br />
      Account(s):
      <br />
      <ul>
        {accountsLinked
          ? accountsLinked.map(item => {
              return <li key={item}>{item}</li>;
            })
          : ""}
      </ul>
      <div className="linked-accounts-modal-footer">
        <Button
          type="submit"
          variant="primary"
          onClick={() => setIsShowLinkedAccountsModal(false)}
        >
          Close
        </Button>
      </div>
    </div>
  );

  const renderNoActivePoliciesMessage = account => {
    if (account.policies.length === 0) {
      return (
        <p>
          {Translations.formatString(
            Translations.account_summary.messages.no_section_group,
            <DirectBillEmailLink />
          )}
        </p>
      );
    }

    if (!account.hasOwnProperty("has_direct_bill_policies")) {
      return (
        <p>
          {Translations.formatString(
            Translations.account_summary.messages.no_section_group,
            <DirectBillEmailLink />
          )}
        </p>
      );
    }

    const key = account.has_direct_bill_policies
      ? Translations.account_summary.messages.direct_bill
      : Translations.account_summary.messages.agency_bill;
    return (
      <p>
        {account.has_direct_bill_policies
          ? Translations.formatString(key, <DirectBillEmailLink />)
          : key}
      </p>
    );
  };

  const renderAccountBilling = account => (
    <div>
      <AccountBilling
        account={account}
        fetchCancellationNotice={fetchCancellationNotice}
        sectionGroup={"direct_bill_by_policy"}
        paperless={billingsAndAlerts}
      />
      <AccountBilling
        account={account}
        fetchCancellationNotice={fetchCancellationNotice}
        sectionGroup={"direct_bill_by_account"}
        paperless={billingsAndAlerts}
      />
      <AccountBilling
        account={account}
        fetchCancellationNotice={fetchCancellationNotice}
        sectionGroup={"direct_bill_by_others"}
        paperless={billingsAndAlerts}
      />
      <AccountBilling
        account={account}
        fetchCancellationNotice={fetchCancellationNotice}
        sectionGroup={"agency_bill"}
        paperless={billingsAndAlerts}
      />
      <AccountBilling
        account={account}
        fetchCancellationNotice={fetchCancellationNotice}
        sectionGroup={PaymentTypes.payGo}
        paperless={billingsAndAlerts}
      />
    </div>
  );

  const setIsNewModal = () => {
    billingsAndAlerts.isAcknowledgedPaymentModal = true;
    setIsHidePaperlessModal(true);
  };

  const goToAccountList = (accounts || []).map(_ => ({
    text: `${_.number} - ${_.name}`,
    to: _.number
  }));

  const handleGoToAccountChange = option => {
    //there's probabl a better way to do this, but this was pretty straight-forward
    const id = `${option.to}-summary`;
    const accountSummaryPanel = document.getElementById(id);
    window.scrollTo({
      top: accountSummaryPanel.offsetTop,
      behavior: "smooth"
    });
    accountSummaryPanel.children[1].children[0].children[0].classList.add(
      "outline-summary"
    );
    setTimeout(() => {
      accountSummaryPanel.children[1].children[0].children[0].classList.remove(
        "outline-summary"
      );
    }, 5000);
  };

  return (
    <div>
      <ContentHeader className="account-list">
        Account
        {goToAccountList?.length > 2 && (
          <span className="pull-right go-to-account">
            <ButtonDropNav
              id="go-to-account"
              buttonText="Go to Account..."
              varient="primary"
              links={goToAccountList}
              onClick={handleGoToAccountChange}
            />
          </span>
        )}
      </ContentHeader>
      {isFirstTimeLoggingIn && !isHidePaperlessModal && (
        <InfoModal
          title={"Paperless Billing Terms and Conditions"}
          hideModal={() => this.setIsHidePaperlessModal(true)}
          altCloseMethod={false}
          hideCross
        >
          <PaperlessUpdateModal
            payload={{
              number: billingsAndAlerts?.alertsData?.find(
                _ => _.paperless_bill.eligibility === "updatable"
              ).number,
              option: "yes",
              name: "paperless_bill"
            }}
            isNewAccount
            setIsNewModal={setIsNewModal}
          />
        </InfoModal>
      )}
      {hasAccountWithoutPaperlessBilling && <PaperlessBillingAlert />}
      {(addAccountSuccess || accountsLinked) && (
        <p className="alert alert-success">
          {Translations.add_account.successMessage}
        </p>
      )}
      {accounts.map(account => {
        return (
          <div
            className="account-list__account-wrapper"
            key={`${account?.number}-${v4()}`}
            id={`${account?.number}-summary`}
          >
            <div className="account-header">
              <div className="account-header__row">
                <h2 className="account-header__number">
                  {`Account # ${account?.number || ""} - ${
                    account?.name || ""
                  } `}
                </h2>
              </div>
            </div>
            {hasActivePolicies(account)
              ? renderAccountBilling(account)
              : renderNoActivePoliciesMessage(account)}
          </div>
        );
      })}
      {accountsLinked?.length > 0 && (
        <Modal
          body={renderLinkedAccountsMessage}
          show={isShowLinkedAccountsModal}
          onHide={() => setIsShowLinkedAccountsModal(false)}
          closeIcon={false}
        />
      )}
    </div>
  );
};

AccountList.propTypes = propTypes;
export default AccountList;
