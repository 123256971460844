import React, { Component } from "react";
import PropTypes from "prop-types";
import LoadingIndicator from "../loading-indicator";
import { getPdf, fetchPdfs } from "../../services/policyholderService";
import { Translations } from "../translations";
import DocumentService from "../../services/documentService";
import InfoModal from "../modals/InfoModal";
import { Button } from "@ufginsurance/ui-kit";
import { LOADING_DOCUMENT_ROUTE } from "../../constants/routes";
import { AnalyticCategories } from "../../constants/analytics";
import { logger } from "../../logging";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";

class DocumentLink extends Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    pdfUri: PropTypes.string.isRequired,
    children: PropTypes.any,
    filename: PropTypes.string.isRequired,
    isPostRequest: PropTypes.bool,
    postPayload: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    disabled: PropTypes.bool,
    timeout: PropTypes.number,
    btnSize: PropTypes.string,
    inline: PropTypes.bool
  };

  static defaultProps = {
    timeout: parseInt(window.env.REACT_APP_API_TIMEOUT, 10)
  };

  state = {
    loading: false,
    error: false
  };

  trackAnalytics = (event, details) => {
    logger.event({
      category: AnalyticCategories.document,
      action: event.target.textContent,
      label: details
    });
  };

  handleGetPdf = event => {
    const { filename, isPostRequest, pdfUri, postPayload, timeout } =
      this.props;
    event.persist();
    event.stopPropagation();

    const details = `filename: ${filename}, pdfUrl: ${pdfUri}`;
    this.trackAnalytics(event, details);
    this.setState({ loading: true });

    const documentRenderer = new DocumentService();
    documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

    //postload.length counts how many forms selected in coverage-forms...
    const key = isPostRequest
      ? "post:" + pdfUri + JSON.stringify(postPayload.length)
      : "get:" + pdfUri;
    sessionStorage.setItem(key, Date.now());

    if (isPostRequest) {
      return fetchPdfs(pdfUri, postPayload).then(
        response => {
          logger.apitimes(key);
          documentRenderer.saveOrOpenDocument(response.data, filename);
          this.setState({ loading: false });
        },
        error => {
          documentRenderer.clearRenderer();
          this.setState({ loading: false, error });
        }
      );
    }

    getPdf(pdfUri, { timeout }).then(
      response => {
        logger.apitimes(key);
        documentRenderer.saveOrOpenDocument(response.data, filename);
        this.setState({ loading: false });
      },
      error => {
        logger.error({
          description: error && error.response && error.response.statusText,
          details: `${event.target.textContent} ${details}`
        });
        documentRenderer.clearRenderer();
        this.setState({ loading: false, error });
      }
    );
  };

  renderErrorDisplay = () => {
    return (
      <InfoModal
        hideModal={() => this.setState({ error: false })}
        title={Translations.modal.title.pdf_document}
        message={
          <HelpDeskMessage before="There was an error loading your document. For further assistance," />
        }
      />
    );
  };

  render() {
    const { disabled, className, pdfUri } = this.props;
    const { error, loading } = this.state;

    if (!pdfUri) return null;

    if (loading) {
      return (
        <LoadingIndicator message={Translations.modal.messages.pdf_loading} />
      );
    }

    return (
      <span className={className}>
        {error && this.renderErrorDisplay()}
        <Button
          onClick={this.handleGetPdf}
          className="btn-theme"
          variant={this.props.color || "primary"}
          disabled={disabled}
          isLink
          inline={this.props?.inline || false}
          size={this.props?.btnSize || "md"}
        >
          {this.props.children}
        </Button>
      </span>
    );
  }
}

export default DocumentLink;
