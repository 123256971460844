import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

export const fetchPolicyFormsStarted = () => ({
  type: types.FETCH_POLICY_FORMS_STARTED
});

export const fetchPolicyFormsSucceeded = (
  data,
  policyNumber,
  coverageOption
) => ({
  type: types.FETCH_POLICY_FORMS_SUCCEEDED,
  payload: { data, coverageOption, policyNumber }
});

export const fetchPolicyFormsFailed = error => ({
  type: types.FETCH_POLICY_FORMS_FAILED,
  payload: error,
  error: true
});

export const fetchForms = (policyNumber, coverageOption, paramsString) => {
  return dispatch => {
    dispatch(fetchPolicyFormsStarted());

    return policyholderService
      .fetchPolicyForms(policyNumber, coverageOption, paramsString)
      .then(
        res => {
          dispatch(
            fetchPolicyFormsSucceeded(res.data, policyNumber, coverageOption)
          );
        },
        error => dispatch(fetchPolicyFormsFailed(error))
      );
  };
};
