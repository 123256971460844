import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ViewMyProfile from "../components/my-profile/ViewMyProfile";
import { fetchViewProfile } from "../actions/viewProfileActions";
import { clearSubmissionNotification } from "../actions/submitEditProfileActions";
import { clearChangePasswordSubmissionNotification } from "../actions/changePasswordActions";
import ErrorBoundary from "../components/ErrorBoundary";
import errorLevel from "../constants/errorLevel";
import ErrorView from "../components/ErrorView";
import { ErrorCodes } from "../constants/errorCodes";
import { logger } from "../logging";
import LoadingIndicator from "../components/loading-indicator";
import { AnalyticCategories } from "../constants/analytics";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";

class MyProfileContainer extends Component {
  static propTypes = {
    profileData: PropTypes.object.isRequired,
    changePasswordData: PropTypes.object.isRequired,
    fetchViewProfile: PropTypes.func.isRequired,
    submitProfileData: PropTypes.object.isRequired,
    clearSubmissionNotification: PropTypes.func.isRequired,
    clearChangePasswordSubmissionNotification: PropTypes.func.isRequired,
    isEditableSession: PropTypes.bool.isRequired
  };

  componentDidMount() {
    this.props.fetchViewProfile();
  }

  componentWillUnmount() {
    this.props.clearSubmissionNotification();
    this.props.clearChangePasswordSubmissionNotification();
  }

  trackAnalytics = event => {
    logger.event({
      category: AnalyticCategories.navigation,
      action: event.target.textContent
    });
  };

  render() {
    const { userProfile, isLoading, error } = this.props.profileData;
    const { submissionMessage } = this.props.submitProfileData;
    const { changeSucceeded } = this.props.changePasswordData;
    if (error) {
      const errorMessage = `Error fetching profile: ${JSON.stringify(error)}`;
      logger.error({
        description: errorMessage,
        fatal: false
      });
      const errStatus =
        error.response && error.response.status
          ? parseInt(error.response.status, 10)
          : ErrorCodes.ServerError;
      return <ErrorView statusCode={errStatus} level={errorLevel.danger} />;
    }
    return (
      <ErrorBoundary statusCode={ErrorCodes.ServerError}>
        <div className="form-controls">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            userProfile && (
              <ViewMyProfile
                changeSucceeded={changeSucceeded}
                submissionMessage={submissionMessage}
                userProfile={userProfile}
                trackAnalytics={this.trackAnalytics}
                isEditableSession={this.props.isEditableSession}
              />
            )
          )}
        </div>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.profileData,
  submitProfileData: state.submitProfileData,
  changePasswordData: state.changePasswordData,
  isEditableSession: sessionSelector.policyholderHasWriteAccess(state)
});

const mapDispatchToProps = {
  fetchViewProfile,
  clearSubmissionNotification,
  clearChangePasswordSubmissionNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);
