export const FETCH_ACCOUNT_DATA_STARTED = "FETCH_ACCOUNT_DATA_STARTED";
export const FETCH_ACCOUNT_DATA_SUCCEEDED = "FETCH_ACCOUNT_DATA_SUCCEEDED";
export const FETCH_ACCOUNT_DATA_FAILED = "FETCH_ACCOUNT_DATA_FAILED";
export const FETCH_VIEW_PROFILE_STARTED = "FETCH_VIEW_PROFILE_STARTED";
export const FETCH_VIEW_PROFILE_SUCCEEDED = "FETCH_VIEW_PROFILE_SUCCEEDED";
export const FETCH_VIEW_PROFILE_FAILED = "FETCH_VIEW_PROFILE_FAILED";
export const CHANGE_PASSWORD_STARTED = "CHANGE_PASSWORD_STARTED";
export const CHANGE_PASSWORD_SUCCEEDED = "CHANGE_PASSWORD_SUCCEEDED";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const ADD_ACCOUNT_STARTED = "ADD_ACCOUNT_STARTED";
export const ADD_ACCOUNT_SUCCEEDED = "ADD_ACCOUNT_SUCCEEDED";
export const ADD_ACCOUNT_FAILED = "ADD_ACCOUNT_FAILED";
export const FETCH_SECURITY_QUESTIONS_STARTED =
  "FETCH_SECURITY_QUESTIONS_STARTED";
export const FETCH_SECURITY_QUESTIONS_SUCCEEDED =
  "FETCH_SECURITY_QUESTIONS_SUCCEEDED";
export const FETCH_SECURITY_QUESTIONS_FAILED =
  "FETCH_SECURITY_QUESTIONS_FAILED";
export const SUBMIT_EDIT_PROFILE_STARTED = "SUBMIT_EDIT_PROFILE_STARTED";
export const SUBMIT_EDIT_PROFILE_SUCCEEDED = "SUBMIT_EDIT_PROFILE_SUCCEEDED";
export const SUBMIT_EDIT_PROFILE_FAILED = "SUBMIT_EDIT_PROFILE_FAILED";
export const CLEAR_EDIT_PROFILE_SUBMISSION = "CLEAR_EDIT_PROFILE_SUBMISSION";
export const CLEAR_MY_PROFILE_REDIRECT = "CLEAR_MY_PROFILE_REDIRECT";
export const CLEAR_CHANGE_PASSWORD_SUBMISSION =
  "CLEAR_CHANGE_PASSWORD_SUBMISSION";
export const CLEAR_REDIRECT_TO_MYPROFILE = "CLEAR_REDIRECT_TO_MYPROFILE";
export const CLEAR_ADD_ACCOUNT_SUBMISSION = "CLEAR_ADD_ACCOUNT_SUBMISSION";
export const CLEAR_REDIRECT_TO_ACCOUNT = "CLEAR_REDIRECT_TO_ACCOUNT";
export const FETCH_AUTOMATIC_PAYMENTS_STARTED =
  "FETCH_AUTOMATIC_PAYMENTS_STARTED";
export const FETCH_AUTOMATIC_PAYMENTS_SUCCEEDED =
  "FETCH_AUTOMATIC_PAYMENTS_SUCCEEDED";
export const FETCH_AUTOMATIC_PAYMENTS_FAILED =
  "FETCH_AUTOMATIC_PAYMENTS_FAILED";
export const SELECT_AUTOMATIC_PAYMENTS_ENROLLMENT_ACCOUNT =
  "SELECT_AUTOMATIC_PAYMENTS_ENROLLMENT_ACCOUNT";
export const FETCH_BILLING_ALERTS_STARTED = "FETCH_BILLING_ALERTS_STARTED";
export const FETCH_BILLING_ALERTS_SUCCEEDED = "FETCH_BILLING_ALERTS_SUCCEEDED";
export const FETCH_BILLING_ALERTS_FAILED = "FETCH_BILLING_ALERTS_FAILED";

export const POST_BILLING_ALERTS_STARTED = "POST_BILLING_ALERTS_STARTED";
export const POST_BILLING_ALERTS_SUCCEEDED = "POST_BILLING_ALERTS_SUCCEEDED";
export const POST_BILLING_ALERTS_FAILED = "POST_BILLING_ALERTS_FAILED";

export const SUBMIT_EFT_SIGN_UP_STARTED = "SUBMIT_EFT_SIGN_UP_STARTED";
export const SUBMIT_EFT_SIGN_UP_SUCCEEDED = "SUBMIT_EFT_SIGN_UP_SUCCEEDED";
export const SUBMIT_EFT_SIGN_UP_FAILED = "SUBMIT_EFT_SIGN_UP_FAILED";
export const CLEAR_SUBMIT_EFT_SIGN_UP = "CLEAR_SUBMIT_EFT_SIGN_UP";
export const CLEAR_AUTOMATIC_PAYMENTS_REDIRECT =
  "CLEAR_AUTOMATIC_PAYMENTS_REDIRECT";
export const FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_STARTED =
  "FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_STARTED";
export const FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_SUCCEEDED =
  "FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_SUCCEEDED";
export const FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_FAILED =
  "FETCH_INSTALLMENT_SCHEDULE_ACCOUNTS_FAILED";
export const FETCH_INSTALLMENT_SCHEDULE_DETAILS_STARTED =
  "FETCH_INSTALLMENT_SCHEDULE_DETAILS_STARTED";
export const FETCH_INSTALLMENT_SCHEDULE_DETAILS_SUCCEEDED =
  "FETCH_INSTALLMENT_SCHEDULE_DETAILS_SUCCEEDED";
export const FETCH_INSTALLMENT_SCHEDULE_DETAILS_FAILED =
  "FETCH_INSTALLMENT_SCHEDULE_DETAILS_FAILED";
export const FETCH_BILLING_ACTIVITY_STARTED = "FETCH_BILLING_ACTIVITY_STARTED";
export const FETCH_BILLING_ACTIVITY_SUCCEEDED =
  "FETCH_BILLING_ACTIVITY_SUCCEEDED";
export const FETCH_BILLING_ACTIVITY_FAILED = "FETCH_BILLING_ACTIVITY_FAILED";
export const FETCH_BILLING_ACTIVITY_DETAILS_STARTED =
  "FETCH_BILLING_ACTIVITY_DETAILS_STARTED";
export const FETCH_BILLING_ACTIVITY_DETAILS_SUCCEEDED =
  "FETCH_BILLING_ACTIVITY_DETAILS_SUCCEEDED";
export const FETCH_BILLING_ACTIVITY_DETAILS_FAILED =
  "FETCH_BILLING_ACTIVITY_DETAILS_FAILED";
export const FETCH_POLICY_BALANCE_REMAINING_STARTED =
  "FETCH_BILLING_ACTIVITY_STARTED";
export const FETCH_POLICY_BALANCE_REMAINING_SUCCEEDED =
  "FETCH_BILLING_ACTIVITY_SUCCEEDED";
export const FETCH_POLICY_BALANCE_REMAINING_FAILED =
  "FETCH_BILLING_ACTIVITY_FAILED";
export const FETCH_POLICY_BALANCE_REMAINING_DETAILS_STARTED =
  "FETCH_BILLING_ACTIVITY_DETAILS_STARTED";
export const FETCH_POLICY_BALANCE_REMAINING_DETAILS_SUCCEEDED =
  "FETCH_BILLING_ACTIVITY_DETAILS_SUCCEEDED";
export const FETCH_POLICY_BALANCE_REMAINING_DETAILS_FAILED =
  "FETCH_BILLING_ACTIVITY_DETAILS_FAILED";
export const FETCH_MY_POLICY_INFO_STARTED = "FETCH_MY_POLICY_INFO_STARTED";
export const FETCH_MY_POLICY_INFO_SUCCEEDED = "FETCH_MY_POLICY_INFO_SUCCEEDED";
export const FETCH_MY_POLICY_INFO_FAILED = "FETCH_MY_POLICY_INFO_FAILED";
export const FETCH_POLICY_ACTIVITY_STARTED = "FETCH_POLICY_ACTIVITY_STARTED";
export const FETCH_POLICY_ACTIVITY_SUCCEEDED =
  "FETCH_POLICY_ACTIVITY_SUCCEEDED";
export const FETCH_POLICY_ACTIVITY_FAILED = "FETCH_POLICY_ACTIVITY_FAILED";

export const FETCH_QUICK_LINKS_STARTED = "FETCH_QUICK_LINKS_STARTED";
export const FETCH_QUICK_LINKS_SUCCEEDED = "FETCH_QUICK_LINKS_SUCCEEDED";
export const FETCH_QUICK_LINKS_FAILED = "FETCH_QUICK_LINKS_FAILED";

export const FETCH_AUTO_ID_CARDS_STARTED = "FETCH_AUTO_ID_CARDS_STARTED";
export const FETCH_AUTO_ID_CARDS_SUCCEEDED = "FETCH_AUTO_ID_CARDS_SUCCEEDED";
export const FETCH_AUTO_ID_CARDS_FAILED = "FETCH_AUTO_ID_CARDS_FAILED";

export const FETCH_COVERAGE_OPTIONS_STARTED = "FETCH_COVERAGE_OPTIONS_STARTED";
export const FETCH_COVERAGE_OPTIONS_SUCCEEDED =
  "FETCH_COVERAGE_OPTIONS_SUCCEEDED";
export const FETCH_COVERAGE_OPTIONS_FAILED = "FETCH_COVERAGE_OPTIONS_FAILED";
export const FETCH_POLICY_DECS_STARTED = "FETCH_POLICY_DECS_STARTED";
export const FETCH_POLICY_DECS_SUCCEEDED = "FETCH_POLICY_DECS_SUCCEEDED";
export const FETCH_POLICY_DECS_FAILED = "FETCH_POLICY_DECS_FAILED";
export const FETCH_POLICY_FORMS_STARTED = "FETCH_POLICY_FORMS_STARTED";
export const FETCH_POLICY_FORMS_SUCCEEDED = "FETCH_POLICY_FORMS_SUCCEEDED";
export const FETCH_POLICY_FORMS_FAILED = "FETCH_POLICY_FORMS_FAILED";
export const FETCH_CLAIMS_ACCOUNTS_STARTED = "FETCH_CLAIMS_ACCOUNTS_STARTED";
export const FETCH_CLAIMS_ACCOUNTS_SUCCEEDED =
  "FETCH_CLAIMS_ACCOUNTS_SUCCEEDED";
export const FETCH_CLAIMS_ACCOUNTS_FAILED = "FETCH_CLAIMS_ACCOUNTS_FAILED";
export const FETCH_OPEN_CLAIMS_STARTED = "FETCH_OPEN_CLAIMS_STARTED";
export const FETCH_OPEN_CLAIMS_SUCCEEDED = "FETCH_OPEN_CLAIMS_SUCCEEDED";
export const FETCH_OPEN_CLAIMS_FAILED = "FETCH_OPEN_CLAIMS_FAILED";
export const FETCH_CLAIMS_HISTORY_STARTED = "FETCH_CLAIMS_HISTORY_STARTED";
export const FETCH_CLAIMS_HISTORY_SUCCEEDED = "FETCH_CLAIMS_HISTORY_SUCCEEDED";
export const FETCH_CLAIMS_HISTORY_FAILED = "FETCH_CLAIMS_HISTORY_FAILED";
export const SUBMIT_USER_MIGRATION_STARTED = "SUBMIT_USER_MIGRATION_STARTED";
export const SUBMIT_USER_MIGRATION_SUCCEEDED =
  "SUBMIT_USER_MIGRATION_SUCCEEDED";
export const SUBMIT_USER_MIGRATION_FAILED = "SUBMIT_USER_MIGRATION_FAILED";
export const FETCH_COMPANY_NOTIFICATIONS_STARTED =
  "FETCH_COMPANY_NOTIFICATIONS_STARTED";
export const FETCH_COMPANY_NOTIFICATIONS_SUCCEEDED =
  "FETCH_COMPANY_NOTIFICATIONS_SUCCEEDED";
export const FETCH_COMPANY_NOTIFICATIONS_FAILED =
  "FETCH_COMPANY_NOTIFICATIONS_FAILED";
export const FETCH_MY_AGENT_STARTED = "FETCH_MY_AGENT_STARTED";
export const FETCH_MY_AGENT_SUCCEEDED = "FETCH_MY_AGENT_SUCCEEDED";
export const FETCH_MY_AGENT_FAILED = "FETCH_MY_AGENT_FAILED";
export const SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_STARTED =
  "SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_STARTED";
export const SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_SUCCEEDED =
  "SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_SUCCEEDED";
export const SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_FAILED =
  "SUBMIT_ACCOUNT_POLICY_REGISTRATION_DATA_FAILED";
export const FETCH_REREGISTER_ACCOUNT_SUCCEEDED =
  "FETCH_REREGISTER_ACCOUNT_SUCCEEDED";
export const FETCH_BILLING_STATEMENT_DATA_STARTED =
  "FETCH_BILLING_STATEMENT_DATA_STARTED";
export const FETCH_BILLING_STATEMENT_DATA_SUCCEEDED =
  "FETCH_BILLING_STATEMENT_DATA_SUCCEEDED";
export const FETCH_BILLING_STATEMENT_DATA_FAILED =
  "FETCH_BILLING_STATEMENT_DATA_FAILED";
export const FETCH_CANCELLATION_NOTICE_LOADING =
  "FETCH_BILLING_STATEMENT_DATA_LOADING";
export const FETCH_CANCELLATION_NOTICE_SUCCESS =
  "FETCH_BILLING_STATEMENT_DATA_SUCCESS";
export const FETCH_CANCELLATION_NOTICE_FAILED =
  "FETCH_BILLING_STATEMENT_DATA_FAILED";

export const FETCH_GW_CONVERSIONS_STARTED = "FETCH_GW_CONVERSIONS_STARTED";
export const FETCH_GW_CONVERSIONS_SUCCEEDED = "FETCH_GW_CONVERSIONS_SUCCEEDED";
export const FETCH_GW_CONVERSIONS_FAILED = "FETCH_GW_CONVERSIONS_FAILED";

export const POST_GW_CONFIRMATION_STARTED = "POST_GW_CONFIRMATION_STARTED";
export const POST_GW_CONFIRMATION_SUCCEEDED = "POST_GW_CONFIRMATION_SUCCEEDED";
export const POST_GW_CONFIRMATION_FAILED = "POST_GW_CONFIRMATION_FAILED";

export const HYDRATE_ADDED_LINKED_ACCOUNTS = "HYDRATE_ADDED_LINKED_ACCOUNTS";
