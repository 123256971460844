import React from "react";
import HelpModal from "../modals/HelpModal";
import { Translations } from "../translations";

const BillingAndAlertsModal = () => {
  const { info_message, bullets } =
    Translations.payments_alerts.paperless_billing.help_modal;

  return (
    <HelpModal
      title="Paperless Billing and Alerts"
      modalBody={
        <div>
          <p>{info_message}</p>
          <ul>
            {bullets.map(bullet => (
              <li key={bullet}>{bullet}</li>
            ))}
          </ul>
        </div>
      }
    />
  );
};

export default BillingAndAlertsModal;
