import React, { useState } from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";
import { Button, Modal } from "@ufginsurance/ui-kit";

const HelpModal = ({ title, modalBody, icon }) => {
  const [showModal, setShowModal] = useState(false);

  const onHide = () => setShowModal(false);

  const showHandler = () => {
    setShowModal(!showModal);
    return false;
  };

  return (
    <>
      <Button
        icon={icon}
        onClick={showHandler}
        variant="link"
        inline
        className="help-modal-button"
      />

      <Modal title={title} body={modalBody} show={showModal} onHide={onHide} />
    </>
  );
};

// new function props
HelpModal.propTypes = {
  title: PropTypes.string,
  modalBody: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  icon: PropTypes.string
};

HelpModal.defaultProps = {
  modalBody: Translations.loading_messages.loading_with_ellipsis,
  icon: "fasQuestionCircle"
};

export default HelpModal;
