import { connect } from "react-redux";
import React from "react";
import LoadingIndicator from "./components/loading-indicator";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  sessionSelector,
  userSelector,
  ssoService,
  ssoClient
} from "@ufginsurance/sso-oidc-client-react";
import "@ufginsurance/ui-kit/build/styles/index.css";
import { config } from "./ssoConfiguration";
import App from "./App";

const AppLoader = props => {
  const { isPolicyholder, user, logoutUser } = props;

  if (!isPolicyholder) {
    logoutUser(
      ssoClient.userManager,
      user,
      () => {},
      config.post_logout_redirect_uri
    );
  }

  return isPolicyholder ? <App /> : <LoadingIndicator />;
};

AppLoader.propTypes = {
  isPolicyholder: PropTypes.bool,
  user: PropTypes.object,
  logoutUser: PropTypes.func
};

const mapDispatchToProps = {
  logoutUser: ssoService.logoutAndRedirect
};

const mapStateToProps = state => ({
  isPolicyholder: sessionSelector.isPolicyholder(state),
  user: userSelector.getOpenIDUser(state)
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppLoader)
);
