import React from "react";
import {
  ssoClient,
  SSOValidationContainer,
  configuration
} from "@ufginsurance/sso-oidc-client-react";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import AppLoader from "./AppLoader";

const LaunchDarklyContainer = ({ store }) => {
  return (
    <SSOValidationContainer
      store={store}
      userManager={ssoClient.userManager}
      clientType={configuration.CLIENT_TYPE_POLICYHOLDER}
    >
      <AppLoader />
    </SSOValidationContainer>
  );
};
export default withLDConsumer()(LaunchDarklyContainer);
