import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import {
  Button,
  ContentHeader,
  Currency,
  DatePicker,
  FieldErrors,
  Form,
  formatDate,
  FormGroup,
  LoadingIndicator,
  useForm,
  Select
} from "@ufginsurance/ui-kit";
import BillingActivity from "../components/billing-activity/BillingActivity";
import {
  fetchBillingActivity,
  fetchBillingActivityAccountDetails
} from "../actions/billingActivityActions";
import DocumentLink from "../components/account-summary/DocumentLink";
import { Translations } from "../components/translations/Translations";
import { DirectBillEmailLink } from "../components/email";
import { fetchAccountData } from "../actions/accountActions";
import { getPaymentType } from "../utils";

const BillingActivityContainer = ({
  accountData,
  billingActivityData,
  fetchAccountData,
  fetchBillingActivity,
  fetchBillingActivityAccountDetails,
  match
}) => {
  const { accounts, isLoading, error } = billingActivityData;

  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const initialValues = {
    accountNumber: "",
    policy_number: "",
    beginDate: moment(new Date(), "YYYY-MM-DD").subtract(18, "months").toDate(),
    endDate: new Date()
  };

  const handleSubmit = ({ values: { policy_number, beginDate, endDate } }) => {
    fetchBillingActivityAccountDetails(
      policy_number,
      formatDate(beginDate, "YYYY/MM/DD"),
      formatDate(endDate, "YYYY/MM/DD")
    );
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });

  const {
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm,
    values,
    errors
  } = form;

  useEffect(() => {
    fetchAccountData();
    fetchBillingActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set account number onload, fetch the activity for that account/policy.
  useEffect(() => {
    const { params } = match;

    if (accounts?.length && !isLoading && !values.accountNumber) {
      const filteredAccounts = (accounts || []).map(account => account.number);
      const isInPath = (filteredAccounts || []).find(
        _ => _ === params.accountNumber
      );

      setFilteredAccounts(filteredAccounts);

      if (isInPath) {
        updateForm({
          values: {
            ...values,
            accountNumber: params.accountNumber,
            ...(!!params.policyNumber
              ? { policy_number: params.policyNumber }
              : {})
          }
        });
        fetchBillingActivityAccountDetails(params.accountNumber);
      } else if (!!filteredAccounts?.length) {
        updateForm({
          values: {
            ...values,
            accountNumber: filteredAccounts[0],
            ...(accounts[0]?.policies?.length === 1
              ? { policy_number: accounts[0].policies[0].number }
              : {})
          }
        });
        fetchBillingActivityAccountDetails(filteredAccounts[0]);
      }
    }
  }, [
    accounts,
    fetchBillingActivityAccountDetails,
    isLoading,
    match,
    updateForm,
    values
  ]);

  // Set the data to display in the billing activity table.
  useEffect(() => {
    if (billingActivityData.isLoading) return;

    if (
      values?.policy_number &&
      !!billingActivityData?.accountDetails[values?.accountNumber]?.length
    ) {
      setActivityData(
        billingActivityData.accountDetails[values.accountNumber].filter(
          record => record.policy_number === values.policy_number
        )
      );
    } else if (
      values?.accountNumber &&
      !!billingActivityData?.accountDetails[values?.accountNumber]?.length
    ) {
      setActivityData(billingActivityData.accountDetails[values.accountNumber]);
    } else {
      setActivityData([]);
    }
  }, [billingActivityData, values.accountNumber, values.policy_number]);

  const createPrintButtonPayload = () => {
    return billingActivityData?.accountDetails[values.accountNumber]?.map(
      accountDetails => {
        const account = Object.assign({}, accountDetails);
        account.effective_due_date = moment(account.effective_due_date).format(
          moment.HTML5_FMT.DATE
        );
        account.activity_date = moment(account.activity_date).format(
          moment.HTML5_FMT.DATE
        );
        return account;
      }
    );
  };

  const getFilteredAccounts = () => {
    if (accounts?.length > 0) {
      return accounts.map(item => ({
        label: item.number,
        value: item.number
      }));
    }
    return [];
  };

  const getPolicyNumbers = () => {
    const filteredAccounts =
      values?.accountNumber && accounts
        ? accounts.filter(
            a =>
              (a.number === values.accountNumber?.value ||
                a.number === values?.accountNumber) &&
              a.has_direct_bill_policies
          )
        : [];

    if (!filteredAccounts.length) return [];

    return filteredAccounts[0].policies
      .filter(
        policy =>
          policy.section_group === "direct_bill_by_policy" ||
          policy.section_group === "direct_bill_by_account"
      )
      .map(policy => {
        if (!policy.nickname && policy.type === "unknown") {
          return {
            value: policy.number,
            label: policy.number
          };
        }

        const policyType =
          Translations.policy_types[policy.type] ?? policy.type;

        return {
          value: policy.number,
          label: `${policyType} - ${policy.number}${
            !policy?.nickname ? "" : ` - ${policy.nickname}`
          }`
        };
      });
  };

  const isGuideWirePolicy = useMemo(
    () =>
      (accountData?.accounts?.find(
        ({ number }) => number === values?.accountNumber
      )?.origin || "") === "oasis",
    [accountData, values.accountNumber]
  );

  const handleAccountChange = ({ field, value }) => {
    const activeAccountDetails = billingActivityData?.accounts?.filter(
      acct => acct.number === value
    )?.[0];

    const isAgentBilled = activeAccountDetails
      ? activeAccountDetails.policies.filter(
          policy => policy.section_group === "agent_bill"
        ).length > 0
      : false;

    if (value && !isAgentBilled) {
      fetchBillingActivityAccountDetails(value);
    }

    updateForm({
      values: {
        ...values,
        [field]: value,
        policy_number: "",
        isAgentBilled
      }
    });
  };

  const handlePolicyChange = ({ field, value }) => {
    if (
      !isGuideWirePolicy &&
      value &&
      !billingActivityData?.accountDetails?.hasOwnProperty(value)
    ) {
      fetchBillingActivityAccountDetails(value);
    }

    if (
      isGuideWirePolicy &&
      value &&
      values?.beginDate &&
      values?.endDate &&
      !billingActivityData?.accountDetails?.hasOwnProperty(value)
    ) {
      fetchBillingActivityAccountDetails(
        value,
        formatDate(values.beginDate, "YYYY/MM/DD"),
        formatDate(values.endDate, "YYYY/MM/DD")
      );
    }

    handleOnChange({ field, value });
  };

  const handleBeginDateValidation = ({ field, value, validation }) => {
    const errors = handleOnValidate({ field, value, validation });

    if (isGuideWirePolicy && values.endDate && value) {
      const lastEighteenthMonthDate = moment(
        values.endDate,
        "YYYY-MM-DD"
      ).subtract(18, "months");
      if (moment(value, "YYYY-MM-DD").diff(lastEighteenthMonthDate) < 0) {
        errors.push("The date range selected cannot exceed 18 months.");
      }
    }

    return errors;
  };

  const handleEndDateValidation = ({ field, value, validation }) => {
    const errors = handleOnValidate({ field, value, validation });

    if (isGuideWirePolicy && values.beginDate && value) {
      const lastEighteenthMonthDate = moment(value, "YYYY-MM-DD").subtract(
        18,
        "months"
      );
      if (
        moment(values.beginDate, "YYYY-MM-DD").diff(lastEighteenthMonthDate) < 0
      ) {
        errors.push("The date range selected cannot exceed 18 months.");
      }
    }

    return errors;
  };

  return (
    <div className="billing-activity">
      <ContentHeader>Billing Activity</ContentHeader>

      <Form className="policy-change__form" context={form}>
        <FormGroup>
          <Select
            id="accountNumber"
            name="accountNumber"
            label="Account Number"
            size="md"
            value={values.accountNumber}
            options={getFilteredAccounts()}
            onChange={handleAccountChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            isLoading={isLoading}
            disabled={isLoading}
          />
          <Select
            id="policy_number"
            name="policy_number"
            label="Policy Number"
            size="md"
            options={getPolicyNumbers()}
            onChange={handlePolicyChange}
            onBlur={handleOnBlur}
            onValidate={handleOnValidate}
            value={values.policy_number}
            isClearable
            isLoading={isLoading}
            disabled={isLoading}
          />

          {!!accounts && (
            <div className="billing-activity__print">
              <DocumentLink
                isPostRequest
                postPayload={{ history: createPrintButtonPayload() }}
                className="installment-schedule__print-button"
                color="primary"
                pdfUri={
                  window.env.REACT_APP_POLICYHOLDER_EAPI_MULE_4_URI +
                  `/billing/activity/${values?.accountNumber}/pdf`
                }
                filename="Billing_Statement.pdf"
                disabled={!!error}
              >
                <span>PRINT</span>
              </DocumentLink>
            </div>
          )}
        </FormGroup>

        {isGuideWirePolicy && values?.policy_number && (
          <>
            <div className="date-billing-container">
              <FormGroup hideErrors>
                <DatePicker
                  id="beginDate"
                  name="beginDate"
                  label="Begin Date"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleBeginDateValidation}
                  value={values.beginDate}
                  format="YYYY/MM/DD"
                  maxDate={values.endDate}
                  size="md"
                />
                <DatePicker
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  onValidate={handleEndDateValidation}
                  value={values.endDate}
                  format="YYYY/MM/DD"
                  minDate={values.beginDate}
                  maxDate={new Date()}
                  size="md"
                />
              </FormGroup>
              <Button
                type="submit"
                disabled={
                  !(values?.beginDate && values?.endDate) ||
                  !!Object.keys(errors)?.length
                }
                variant="primary"
                labelSpace
              >
                Submit
              </Button>
              {!!billingActivityData.activities && (
                <span>
                  Unapplied Amount:{" "}
                  <Currency
                    amount={billingActivityData?.unapplied_amount || 0}
                  />
                </span>
              )}
            </div>
            <FieldErrors fields={["beginDate", "endDate"]} />
          </>
        )}
      </Form>

      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {(!filteredAccounts?.length ||
            !billingActivityData.accountDetails[
              values.accountNumber || values.policy_number
            ] ||
            (error && error.response?.status !== 404)) && (
            <div className="policy-balance-remaining__no-accounts">
              There is currently no billing activity to view for your policy or
              account. If you feel this is in error, please contact UFG Billing
              Customer Service at 800-637-6309 or <DirectBillEmailLink />{" "}
              between 7 a.m. and 6 p.m. CT Monday through Friday.
            </div>
          )}

          {error && error.response?.status === 404 && (
            <div className="policy-balance-remaining__no-accounts">
              Our records indicate your account is billed to your agent, please
              contact the agency for billing related information.
            </div>
          )}

          {values?.isAgentBilled && (
            <p>
              Our records indicate your account is billed to your agent, please
              contact the agency for billing related information.
            </p>
          )}

          {!values?.isAgentBilled &&
            !!billingActivityData?.accountDetails[
              values.accountNumber || values.policy_number
            ]?.length && (
              <BillingActivity
                activity={activityData}
                account={values.accountNumber}
                policy={values.policy_number}
                paymentType={getPaymentType(
                  billingActivityData,
                  values.accountNumber,
                  values.policy_number
                )}
              />
            )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  billingActivityData: state.billingActivityData,
  accountData: state.accountData
});

const mapDispatchToProps = {
  fetchBillingActivity,
  fetchBillingActivityAccountDetails,
  fetchAccountData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingActivityContainer);
