import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const accountDataReducer = (state = initialState.accountData, action) => {
  switch (action.type) {
    case types.FETCH_ACCOUNT_DATA_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_ACCOUNT_DATA_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_ACCOUNT_DATA_SUCCEEDED:
      return {
        ...state,
        accounts: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_CANCELLATION_NOTICE_LOADING:
      return {
        ...state,
        cancellationLoading: true,
        cancellationError: null
      };
    case types.FETCH_CANCELLATION_NOTICE_SUCCESS:
      return {
        ...state,
        cancellationLoading: false,
        cancellationError: action.payload
      };
    case types.FETCH_CANCELLATION_NOTICE_FAILED:
      return {
        ...state,
        cancellationLoading: false,
        cancellationError: null
      };

    case types.FETCH_GW_CONVERSIONS_STARTED:
      return {
        ...state,
        isLoading: true,
        gw_error: null,
        linkedAccountsAdded: []
      };
    case types.FETCH_GW_CONVERSIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        gw_error: action?.payload?.response?.data?.message || null,
        linkedAccountsAdded: []
      };
    case types.FETCH_GW_CONVERSIONS_SUCCEEDED:
      return {
        ...state,
        gw_conversions: action.payload?.filter(
          _ => _.gw_conversion_account_number !== "linked_account"
        ),
        isLoading: false,
        gw_error: null,
        linkedAccountsAdded: action?.payload?.filter(
          _ => _.gw_conversion_account_number === "linked_account"
        )
      };

    case types.POST_GW_CONFIRMATION_STARTED:
      return {
        ...state,
        isLoading: true,
        post_gw_error: null
      };
    case types.POST_GW_CONFIRMATION_FAILED:
      return {
        ...state,
        isLoading: false,
        post_gw_error: action?.payload?.response?.data?.message || null
      };
    case types.POST_GW_CONFIRMATION_SUCCEEDED:
      return {
        ...state,
        gw_conversions_success: action.payload,
        gw_conversions: null,
        isLoading: false,
        post_gw_error: null
      };
    default:
      return state;
  }
};

export default accountDataReducer;
