import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const companyNotificationReducer = (
  state = initialState.companyNotification,
  action
) => {
  switch (action.type) {
    case types.FETCH_COMPANY_NOTIFICATIONS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_COMPANY_NOTIFICATIONS_SUCCEEDED:
      return {
        ...state,
        notifications: action.payload,
        isLoading: false,
        error: null
      };
    case types.FETCH_COMPANY_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default companyNotificationReducer;
