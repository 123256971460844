import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Input, FormGroup, Popover, Icon, Select } from "@ufginsurance/ui-kit";

const EditSecurityQuestion = ({
  questions,
  label,
  selections,
  questionId,
  questionValue,
  answerId,
  answerValue,
  handleBlur,
  handleChange,
  handleValidate,
  handleSecurityQuestionChange
}) => {
  const [options, setOptions] = useState([]);
  const [showSecret, setShowSecret] = useState(false);

  useEffect(() => {
    const ignoredOptions = (selections || [])
      .filter(_ => _.question !== questionValue)
      .map(_ => _.question);
    const filteredOptions = (questions || [])
      .filter(_ => !ignoredOptions.includes(_.question))
      .map(_ => ({ value: _.question, label: _.question }));
    setOptions(filteredOptions);
  }, [questions, selections, questionValue]);

  const triggerEl = <Icon icon="fasInfoCircle"></Icon>;
  const popoverEl =
    "Answer fields should be a minimum of 3 characters to 35 characters.";
  const tooltip = (
    <Popover
      id="secret-answer-info"
      tooltip
      triggerContent={triggerEl}
      popoverContent={popoverEl}
    />
  );

  return (
    <>
      <FormGroup>
        <Select
          id={questionId}
          name={questionId}
          label={label}
          value={questionValue}
          options={options}
          onBlur={handleBlur}
          onChange={handleSecurityQuestionChange}
          onValidate={handleValidate}
          size="full"
          required
        />
      </FormGroup>
      <FormGroup>
        <Input
          id={answerId}
          name={answerId}
          type={showSecret ? "text" : "password"}
          label={`${label} Answer`}
          labelElement={tooltip}
          value={answerValue || ""}
          onBlur={handleBlur}
          onChange={handleChange}
          onValidate={handleValidate}
          minLength={3}
          maxLength={35}
          size="fill"
          required
        />
        <Icon
          icon="farEye"
          size="1x"
          onClick={() => setShowSecret(!showSecret)}
          className="secret-toggle"
        />
      </FormGroup>
    </>
  );
};

EditSecurityQuestion.propTypes = {
  questions: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  selections: PropTypes.array.isRequired,
  questionId: PropTypes.string.isRequired,
  questionValue: PropTypes.string,
  answerId: PropTypes.string.isRequired,
  answerValue: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
  handleSecurityQuestionChange: PropTypes.func.isRequired
};

export default EditSecurityQuestion;
