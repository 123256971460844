import React from "react";
import PropTypes from "prop-types";
import orderBy from "lodash.orderby";
import PhoneNumber from "../PhoneNumber";
import { EmailLink } from "../email";
import { Translations } from "../translations";
import { v4 } from "uuid";

const MyAgent = ({ agentData }) => {
  const agents = orderBy(agentData, a => a.name);

  return agents.map(agent => {
    const { address, contact_information } = agent;

    return (
      <div key={`{agent.agent_number}-${v4()}`} className="my-agent">
        <p className="my-agent__name">
          {contact_information.website ? (
            <a
              href={`${contact_information.website}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {agent.name}
            </a>
          ) : (
            agent.name
          )}
        </p>
        <div>
          <p>
            {address.address1}
            <br />
            {`${address.city}, ${address.state}`} {address.zip}
          </p>
        </div>
        <div className="my-agent__contact">
          <i className="fa fa-phone" />
          <span className="uikit-sr-only">Phone Number:</span>
          <PhoneNumber number={contact_information.phone_numbers[0]} />
        </div>
        {contact_information.fax_numbers[0] ? (
          <div className="my-agent__contact">
            <i className="fa fa-fax" />
            <span className="uikit-sr-only">Fax Number:</span>
            <PhoneNumber number={contact_information.fax_numbers[0]} />
          </div>
        ) : (
          ""
        )}
        <div className="my-agent__contact">
          <i className="fa fa-envelope" />
          <span className="uikit-sr-only">Email:</span>
          <EmailLink email={contact_information.email || ""}>
            {Translations.my_agencies.email_link_text}
          </EmailLink>
        </div>
      </div>
    );
  });
};

MyAgent.propTypes = {
  agentData: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default MyAgent;
