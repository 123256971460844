import React from "react";
import PropTypes from "prop-types";

const EmailLink = ({ children, className, email }) => {
  if (!email) {
    return null;
  }

  const mailTo = email.startsWith("mailto:") ? email : `mailto:${email}`;
  return (
    <a className={className} href={mailTo}>
      {children ? children : email}
    </a>
  );
};

EmailLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  email: PropTypes.string.isRequired
};

export default EmailLink;
