const iconStatus = [
  {
    status: "Canceled",
    iconName: "fasClock",
    color: "#808080"
  },
  {
    status: "Initial Cancellation",
    iconName: "fasExclamationTriangle",
    color: "#B7312C"
  },
  {
    status: "Pending Cancellation",
    iconName: "fasExclamationTriangle",
    color: "#B7312C"
  },
  {
    status: "Canceled Pending Audit",
    iconName: "fasExclamationTriangle",
    color: "#B7312C"
  },
  {
    status: "In Force",
    iconName: "fasCheckCircle",
    color: "#2A8200"
  },
  {
    status: "Pending Payment",
    iconName: "fasClock",
    color: "#0054A8"
  },
  {
    status: "Lapsed",
    iconName: "fasClock",
    color: "#808080"
  },
  {
    status: "Expired",
    iconName: "fasClock",
    color: "#808080"
  },
  {
    status: "Scheduled",
    iconName: "fasClock",
    color: "#002E5D"
  }
];

export const iconPolicyStatusFactory = policyStatus => {
  const policyStatusIcon = iconStatus.find(icon => {
    return policyStatus && icon && icon.status
      ? icon.status.toUpperCase() === policyStatus.toUpperCase()
      : "";
  });
  return {
    iconPolicyStatus:
      policyStatusIcon && policyStatusIcon.iconName
        ? policyStatusIcon.iconName
        : "",
    iconPolicyStatusColor:
      policyStatusIcon && policyStatusIcon.color ? policyStatusIcon.color : ""
  };
};
