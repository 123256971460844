import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Panel } from "@ufginsurance/ui-kit";
import { fetchMyAgent } from "../actions/fetchMyAgentActions";
import MyAgent from "../components/my-agent/MyAgent";

class MyAgentContainer extends Component {
  static propTypes = {
    fetchMyAgent: PropTypes.func.isRequired,
    myAgent: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { fetchMyAgent } = this.props;
    fetchMyAgent();
  }

  render() {
    const { agentData } = this.props.myAgent;
    return (
      agentData && (
        <Panel id="my-agency" title="My Agency" highlight="red" collapsible>
          <MyAgent agentData={agentData} />
        </Panel>
      )
    );
  }
}

const mapStateToProps = state => ({
  myAgent: state.myAgent
});

const mapDispatchToProps = {
  fetchMyAgent
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAgentContainer);
