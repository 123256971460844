import React from "react";
import PropTypes from "prop-types";
import { formatDate, Alert } from "@ufginsurance/ui-kit";
import Currency from "../Currency";
import { Translations } from "../translations";
import SmartPayLink from "../SmartPayLink";
import { isPayGo } from "../../utils";

const renderRow = (record, index, policy) => {
  return (
    <tr key={index} className="billing-activity-table__row">
      <td className="billing-activity-table__td">{record.policy_number}</td>
      <td className="billing-activity-table__td">
        {formatDate(record.activity_date, "MM/DD/YYYY")}
      </td>
      <td className="billing-activity-table__td">{record.activity}</td>
      <td className="billing-activity-table__td">
        {formatDate(record.effective_due_date, "MM/DD/YYYY")}
      </td>
      <td>
        <Currency quantity={record.amount} />
      </td>
      {(!!policy || !record.policy_number.match(/^[0-9]{11}$/)) && (
        <td>
          <Currency quantity={record.balance} />
        </td>
      )}
    </tr>
  );
};

const propTypes = {
  activity: PropTypes.array,
  account: PropTypes.string,
  policy: PropTypes.string,
  paymentType: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

const BillingActivity = ({ activity, account, policy, paymentType }) => {
  if (!activity?.length) return null;
  return (
    <>
      {isPayGo(paymentType) && (
        <Alert dismissible={false} className="wc-smart-pay-alert">
          This Workers&apos; Compensation Policy is managed by SmartPay
          <SmartPayLink />
        </Alert>
      )}

      {!isPayGo(paymentType) && (
        <table className="billing-activity-table">
          <thead className="billing-activity-table__head">
            <tr className="billing-activity-table__row">
              {Translations.billing_activity.billing_actibity_table_headers
                .slice(0, 4)
                .map(header => (
                  <th className="billing-activity-table__td" key={header}>
                    {header}
                  </th>
                ))}
              {Translations.billing_activity.billing_actibity_table_headers
                .slice(4, 5)
                .map(header => (
                  <th key={header}>{header}</th>
                ))}
              {(!!policy || !account.match(/^6[0-9]{9}$/)) &&
                Translations.billing_activity.billing_actibity_table_headers
                  .slice(5)
                  .map(header => <th key={header}>{header}</th>)}
            </tr>
          </thead>
          <tbody>
            {activity.map((record, i) => renderRow(record, i, policy))}
          </tbody>
        </table>
      )}
    </>
  );
};

BillingActivity.propTypes = propTypes;

export default BillingActivity;
