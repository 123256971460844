import React, { useState, useEffect } from "react";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

import {
  Button,
  FlexRow,
  Form,
  FormGroup,
  TextArea,
  Modal,
  Alert,
  Panel,
  Icon,
  useForm
} from "@ufginsurance/ui-kit";

import { postFeedback } from "../services/policyholderService";
import "./feedback.scss";

export default function FeedbackModal({
  policyholderUsername,
  policyholderEmail
}) {
  const initialValues = {
    rating: 0,
    comments: ""
  };
  const handleSubmit = ({ values }) => {
    const body = {
      policyholder_username: policyholderUsername,
      policyholder_email: policyholderEmail,
      selected_grade: values.rating.toString(),
      comments: values.comments
    };
    setSubmittingFeedback(true);
    postFeedback(body)
      .then(() => {
        setSuccessfulFeedbackSent(true);
      })
      .catch(() => {
        setFailedToSendFeedback(true);
      })
      .finally(() => {
        setSubmittingFeedback(false);
      });
  };

  const form = useForm({ values: initialValues, onSubmit: handleSubmit });
  const { values, updateForm, handleOnChange, handleOnBlur, handleOnValidate } =
    form;

  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);
  const [successfulFeedbackSent, setSuccessfulFeedbackSent] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [failedToSendFeedback, setFailedToSendFeedback] = useState(false);

  const onHide = () => {
    setShowFeedbackModal(false);
    setSuccessfulFeedbackSent(false);
    setFailedToSendFeedback(false);
    updateForm({ values: initialValues });
  };

  useEffect(() => {
    const shouldDisable = values.rating === 0 || values.comments.length === 0;

    setSubmitDisabled(shouldDisable);
  }, [values.rating, values.comments]);

  const setRating = newRating => {
    updateForm({ values: { ...values, rating: newRating } });
  };

  return (
    <>
      <Modal
        show={showFeedbackModal}
        onHide={onHide}
        id="feedback-modal"
        altCloseMethod={false}
        title={
          successfulFeedbackSent
            ? "Thank you!"
            : "Provide feedback to the UFG product team"
        }
        size="lg"
        body={
          <>
            {failedToSendFeedback && (
              <Alert type="error">
                <HelpDeskMessage before="We encountered an error trying to save your feedback. Try again, or " />
              </Alert>
            )}
            {!successfulFeedbackSent && (
              <Form context={form}>
                <FlexRow align="center">
                  <div>
                    How satisfied are you with the UFG Policyholder website?
                  </div>
                </FlexRow>
                <FlexRow align="center">
                  <div>
                    <Icon
                      size="2x"
                      icon={values.rating >= 1 ? "fasStar" : "farStar"}
                      onClick={() => setRating(1)}
                      color="#006FCF"
                      dataAttrib={[
                        { name: "fullstory-feedback", value: "1-star" }
                      ]}
                    />
                  </div>
                  <div>
                    <Icon
                      size="2x"
                      icon={values.rating >= 2 ? "fasStar" : "farStar"}
                      onClick={() => setRating(2)}
                      color="#006FCF"
                      dataAttrib={[
                        { name: "fullstory-feedback", value: "2-star" }
                      ]}
                    />
                  </div>
                  <div>
                    <Icon
                      size="2x"
                      icon={values.rating >= 3 ? "fasStar" : "farStar"}
                      onClick={() => setRating(3)}
                      color="#006FCF"
                      dataAttrib={[
                        { name: "fullstory-feedback", value: "3-star" }
                      ]}
                    />
                  </div>
                  <div>
                    <Icon
                      size="2x"
                      icon={values.rating >= 4 ? "fasStar" : "farStar"}
                      onClick={() => setRating(4)}
                      color="#006FCF"
                      dataAttrib={[
                        { name: "fullstory-feedback", value: "4-star" }
                      ]}
                    />
                  </div>
                  <div>
                    <Icon
                      size="2x"
                      icon={values.rating >= 5 ? "fasStar" : "farStar"}
                      onClick={() => setRating(5)}
                      color="#006FCF"
                      dataAttrib={[
                        { name: "fullstory-feedback", value: "5-star" }
                      ]}
                    />
                  </div>
                </FlexRow>
                <FormGroup>
                  <TextArea
                    label="Comments"
                    id="comments"
                    name="comments"
                    className="feedback_text_area"
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    value={values.comments}
                    onValidate={handleOnValidate}
                    dataAttrib={[
                      {
                        name: "fullstory_oa_feedback_comments",
                        value: policyholderUsername
                      }
                    ]}
                    maxLength={500}
                    required
                    placeholder="Your feedback will be sent directly to our product team."
                  />
                </FormGroup>
                <Panel bgcolor="grey" dismissible={false} type="plain">
                  <strong>Need Help?</strong>
                  <br />
                  <strong>Technical Support:</strong> Contact{" "}
                  <a href="mailto:helpdesk@unitedfiregroup.com">
                    UFG Help Desk
                  </a>{" "}
                  between 7 a.m. and 5 p.m. CT at 800-895-6253.
                  <br />
                  <strong>Billing support:</strong> Contact Billing Customer
                  Service between 7 a.m. and 6 p.m. CT at 800-637-6309.
                </Panel>
                <FormGroup align="right">
                  <Button variant="plain" onClick={onHide}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={submitDisabled || submittingFeedback}
                    spinner={submittingFeedback}
                  >
                    Submit
                  </Button>
                </FormGroup>
              </Form>
            )}
            {successfulFeedbackSent && (
              <>
                <div>
                  We genuinely appreciate you taking the time to send us
                  feedback. Your feedback allows us to make our products better
                  every day.
                </div>
                <FlexRow align="right">
                  <Button variant="plain" onClick={onHide}>
                    Close
                  </Button>
                </FlexRow>
              </>
            )}
          </>
        }
      />
      <Button
        icon="farPaperPlane"
        onClick={() => setShowFeedbackModal(true)}
        variant="tertiary"
        className="feedback_button"
      >
        Feedback
      </Button>
    </>
  );
}
