import React from "react";
import PropTypes from "prop-types";
import { formatDate, Alert } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import Currency from "../Currency";

const propTypes = {
  origin: PropTypes.oneOf(["oasis", "ufg_core"]),
  policies: PropTypes.arrayOf(PropTypes.object),
  selectedPolicy: PropTypes.string.isRequired,
  is_service_center: PropTypes.bool.isRequired
};

const filterPolicies = (policies, selectedPolicy) => {
  if (!selectedPolicy) return policies;
  return policies.filter(policy => {
    return policy.policy_number === selectedPolicy;
  });
};

const renderPolicyTable = (origin, policies, selectedPolicy) => {
  return filterPolicies(policies, selectedPolicy).map(policy => {
    return (
      <tr
        className="policy-balance-remaining-table__row"
        key={policy.policy_number + "_" + policy.index}
      >
        <td className="policy-balance-remaining-table__td">
          {policy.policy_number}
        </td>
        <td className="policy-balance-remaining-table__td">
          {formatDate(policy.effective_date, "MM/DD/YYYY")}
        </td>
        <td className="policy-balance-remaining-table__td">
          {formatDate(policy.expiration_date, "MM/DD/YYYY")}
        </td>
        <td className="policy-balance-remaining-table__trailing">
          <Currency quantity={policy.premium_due} />
        </td>
        {origin === "ufg_core" && (
          <td className="policy-balance-remaining-table__right-align">
            <Currency quantity={policy.service_fees} />
          </td>
        )}
        {origin === "ufg_core" && (
          <td className="policy-balance-remaining-table__right-align">
            <Currency quantity={policy.prior_earned_premium_due} />
          </td>
        )}
        {origin === "ufg_core" && (
          <td className="policy-balance-remaining-table__right-align">
            <Currency quantity={policy.current_earned_premium_due} />
          </td>
        )}
        <td className="policy-balance-remaining-table__trailing">
          <Currency quantity={policy.total_due} />
        </td>
        <td className="policy-balance-remaining-table__trailing">
          {policy.policy_status &&
            Translations.policy_statuses[policy.policy_status].toUpperCase()}
        </td>
      </tr>
    );
  });
};

const PolicyBalanceRemaining = ({
  origin,
  policies,
  selectedPolicy,
  is_service_center
}) => {
  const final_audit = filterPolicies(policies, selectedPolicy).some(
    o => o?.final_audit
  );
  if (final_audit)
    return (
      <Alert type="warning">
        {is_service_center
          ? Translations.billing_activity.warning_messages.service_account
          : Translations.billing_activity.warning_messages.non_service_account}
      </Alert>
    );
  return (
    <table className="policy-balance-remaining-table">
      <thead className="policy-balance-remaining-table__head">
        <tr className="policy-balance-remaining-table__row">
          {Translations.billing_activity.policy_balance_remaining_table_headers
            .slice(0, 3)
            .map(header => (
              <td className="policy-balance-remaining-table__td" key={header}>
                {header}
              </td>
            ))}
          {Translations.billing_activity.policy_balance_remaining_table_headers
            .slice(3)
            .map(header => {
              if (header === "PREM DUE" || header === "TOTAL DUE") {
                return (
                  <td
                    key={header}
                    className="policy-balance-remaining-table__trailing"
                  >
                    {header}
                  </td>
                );
              }
              return origin === "oasis" &&
                (header === "S/C FEES" ||
                  header === "PRIOR EPD" ||
                  header === "CURR EPD") ? null : (
                <td
                  className="policy-balance-remaining-table__right-align"
                  key={header}
                >
                  {header}
                </td>
              );
            })}
        </tr>
      </thead>
      <tbody>{renderPolicyTable(origin, policies, selectedPolicy)}</tbody>
    </table>
  );
};

PolicyBalanceRemaining.propTypes = propTypes;

export default PolicyBalanceRemaining;
