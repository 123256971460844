import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { PanelGrid, Panel, Alert, Icon } from "@ufginsurance/ui-kit";
import AccountSummary from "./AccountSummary";
import { v4 } from "uuid";
import PolicySummary from "./PolicySummary";

const AccountBilling = ({ account, paperless, fetchCancellationNotice }) => {
  const policies = useMemo(() => {
    return account?.policies ?? [];
  }, [account?.policies]);

  const showPendingCancellationNotice = useMemo(() => {
    if (policies.length === 0) return false;

    return policies.some(policy => policy.status === "pending_cancellation");
  }, [policies]);

  const classes = useMemo(() => {
    return classNames(
      "policy-details-panel-v2",
      "policy-details-panel-v2-no-padding"
    );
  }, []);

  if (policies.length === 0) return null;

  return (
    <div className="policy-list-wrapper" key={account.number}>
      <Panel
        bgcolor="grey"
        className={classes}
        isOpen={false}
        id={v4()}
        noBorder
      >
        {showPendingCancellationNotice && (
          <Panel
            noBorder
            bgcolor="grey"
            className="policy-cancellation-notice-v2"
          >
            <div className="col-lg-12 col-md-12 col-sm-12 policy-cancellation-notice-v2">
              <Alert type="error" dismissible={false}>
                <Icon size="1x" icon="fasExclamationTriangle" />
                &nbsp;You have one or more policies at risk of being canceled.
              </Alert>
            </div>
          </Panel>
        )}
        <AccountSummary
          policyBilling={policies[0].billing}
          account={account}
          paperless={paperless}
          isInPendingCancel={showPendingCancellationNotice}
        />
        <Panel title="My Policies" className="my-policies-panel-v2">
          <PanelGrid sizes={{ sm: 1, md: 1, lg: 2, xl: 2 }}>
            {policies.map(policy => {
              return (
                <PolicySummary
                  key={policy.number}
                  policy={policy}
                  isOasis={account?.origin === "oasis"}
                  accountNumber={account.number}
                  fetchCancellationNotice={fetchCancellationNotice}
                />
              );
            })}
          </PanelGrid>
        </Panel>
      </Panel>
    </div>
  );
};

AccountBilling.propTypes = {
  account: PropTypes.object.isRequired,
  paperless: PropTypes.object.isRequired,
  fetchCancellationNotice: PropTypes.func.isRequired
};

export default AccountBilling;
