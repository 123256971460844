import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import HelpModal from "../modals/HelpModal";
import {
  Button,
  Form,
  Input,
  FormGroup,
  useForm,
  Alert,
  Anchor
} from "@ufginsurance/ui-kit";
import { submitNickname } from "../../services/policyholderService";
import * as routes from "../../constants/routes";

const ChangeNickname = ({ disabled, nickname, policyNumber }) => {
  const [error, setError] = useState(false);

  const initialValues = {
    nickname
  };

  const onSubmit = useCallback(
    ({ values }) => {
      submitNickname(policyNumber, {
        nickname: values.nickname
      })
        .then(
          () => (window.location = `${routes.POLICY_ROUTE}/${policyNumber}`)
        )
        .catch(() => setError(true));
    },
    [policyNumber]
  );

  const form = useForm({ values: initialValues, onSubmit });

  const { handleOnBlur, handleOnValidate, handleOnChange, values } = form;

  if (disabled) return;

  return (
    <HelpModal
      title="Policy Nickname"
      color="primary"
      icon="fasPencil"
      iconSize="sm"
      modalBody={
        <Form context={form} className="change-nickname-modal">
          {error && (
            <Alert type="error" dismissible={false}>
              Unable to update your settings at this time. Please try again. If
              you continue having problems, please contact UFG Help Desk at
              800-895-6253 or email{" "}
              <Anchor variant="red" href="mailto:helpdesk@unitedfiregroup.com">
                helpdesk@unitedfiregroup.com
              </Anchor>
            </Alert>
          )}
          <FormGroup>
            <Input
              id="nickname"
              name="nickname"
              label="Nickname"
              className="change-nickname__text-field"
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              onValidate={handleOnValidate}
              value={values.nickname}
              minLength={3}
              maxLength={20}
              required
            />
          </FormGroup>

          <div className="change-nickname__require">
            <h4 className="change-nickname__require-head">
              Your nickname must:
            </h4>
            <ul>
              <li>Contain 3 to 20 characters</li>
              <li>Special characters are allowed</li>
              <li>Leading and trailing spaces are not allowed</li>
            </ul>
          </div>

          <FormGroup align="right">
            <Button disabled={disabled} type="submit" variant="primary">
              Submit
            </Button>
          </FormGroup>
        </Form>
      }
    />
  );
};

ChangeNickname.propTypes = {
  nickname: PropTypes.string,
  policyNumber: PropTypes.string,
  disabled: PropTypes.bool
};

ChangeNickname.defaultProps = {
  nickname: ""
};

export default ChangeNickname;
