import React from "react";
import PropTypes from "prop-types";
import AutoIdCardsTable from "./AutoIdCardsTable";
import { ContentHeader } from "@ufginsurance/ui-kit";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const propTypes = {
  autoIdCardAccounts: PropTypes.arrayOf(PropTypes.object).isRequired
};

const AutoIdCards = ({ autoIdCardAccounts }) => {
  const renderTable = autoIdCardAccounts => {
    return autoIdCardAccounts.length === 0 ? (
      <p>
        <HelpDeskMessage before="There are currently no auto ID cards available for your policy or account. If you feel this is a technical error," />
      </p>
    ) : (
      <AutoIdCardsTable autoIdCardAccounts={autoIdCardAccounts} />
    );
  };

  return (
    <div>
      <ContentHeader>Get Auto ID Cards</ContentHeader>
      {renderTable(autoIdCardAccounts)}
    </div>
  );
};

AutoIdCards.propTypes = propTypes;

export default AutoIdCards;
