import React, { Component } from "react";
import PropTypes from "prop-types";
import Advertisement from "./Advertisement";

class AdSlider extends Component {
  static propTypes = {
    delay: PropTypes.number.isRequired,
    ads: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  state = { currentIndex: 0 };

  componentDidMount() {
    const { delay } = this.props;
    /*
     * Always having setInterval regardless of whether there are multiple ads
     * because cDM is only executed once and doesn't correctly handle ads array
     * length changes between initial render and next props
     * WF Issue - https://unitedfiregroup.my.workfront.com/issue/view?ID=5a8eea57002d092f37d2b981d2134a8c
     */
    this.rotateListener = setInterval(this.rotate, delay);
  }

  componentWillUnmount() {
    clearInterval(this.rotateListener);
  }

  rotate = () => this.setState({ currentIndex: this.getNextIndex() });

  getNextIndex = () => {
    const { ads } = this.props;
    const { currentIndex } = this.state;
    return currentIndex >= ads.length - 1 ? 0 : currentIndex + 1;
  };

  getCurrentAd = () => this.props.ads[this.state.currentIndex];

  render() {
    const currentAd = this.getCurrentAd();

    return (
      <div className="slideshow-container">
        <div className="fade2">
          <Advertisement {...currentAd} />
        </div>
      </div>
    );
  }
}

export default AdSlider;
