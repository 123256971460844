import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ContentHeader,
  Form,
  useForm,
  Input,
  FormGroup,
  Icon,
  Popover
} from "@ufginsurance/ui-kit";
import "./_ChangePassword.scss";

const propTypes = {
  errorSummary: PropTypes.element,
  handleCancel: PropTypes.func.isRequired,
  submitChangePassword: PropTypes.func.isRequired
};

const ChangePassword = ({
  errorSummary,
  handleCancel,
  submitChangePassword
}) => {
  const [showSecret, setShowSecret] = useState({
    current: false,
    new: false,
    confirm: false
  });

  const onSubmit = useCallback(
    ({ values }) => {
      submitChangePassword(values);
    },
    [submitChangePassword]
  );

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: ""
  };

  const form = useForm({ values: initialValues, onSubmit });

  const {
    values,
    errors,
    handleOnChange,
    handleOnBlur,
    handleOnValidate,
    updateForm
  } = form;

  const handleOnPasswordValidate = ({ field, value, validation }) => {
    const fieldErrors = handleOnValidate({ field, value, validation });

    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9\s]).{6,50}$/;
    const validPassword = passwordRegex.test(value);

    if (value && !validPassword) {
      fieldErrors.push(
        "Must be between 6 - 50 characters",
        "Must contain at least 1 uppercase letter",
        "Must contain at least 1 lowercase letter",
        "Must contain at least 1 number",
        "Must contain at least 1 special character"
      );
    }

    return fieldErrors;
  };

  const handleOnConfirmNewPassword =
    password =>
    ({ field, value, validation }) => {
      const fieldErrors = handleOnValidate({ field, value, validation });

      if (!!password && !!value && password !== value) {
        fieldErrors.push("Both passwords must match.");
      }

      return fieldErrors;
    };

  const handleOnNewPasswordChange = ({ field, value }) => {
    if (!value.length) {
      updateForm({
        values: { ...values, [field]: value },
        errors: { ...errors, newPassword: [], confirmNewPassword: [] }
      });
    } else handleOnChange({ field, value });
  };

  return (
    <div>
      <div className="row">
        <div className="col-xs-12">
          <ContentHeader>Change Password</ContentHeader>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12  col-md-8 col-ls-6">{errorSummary}</div>
      </div>
      <div className="row">
        <div className="col-xs-12  col-md-8 col-ls-6">
          <Form context={form}>
            <FormGroup wrap={false}>
              <Input
                id="currentPassword"
                name="currentPassword"
                label="Current Password"
                type={showSecret.current ? "text" : "password"}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.currentPassword}
                size="full"
                maxLength={50}
                required
              />
              <Icon
                icon="farEye"
                size="1x"
                onClick={() =>
                  setShowSecret({ ...showSecret, current: !showSecret.current })
                }
                className="secret-toggle"
              />
            </FormGroup>
            <FormGroup wrap={false}>
              <Input
                id="newPassword"
                name="newPassword"
                label="New Password"
                onChange={handleOnNewPasswordChange}
                onBlur={handleOnBlur}
                onValidate={handleOnPasswordValidate}
                type={showSecret.new ? "text" : "password"}
                value={values.newPassword}
                size="full"
                labelElement={
                  <Popover
                    id="singleAggregate"
                    trigger="hover"
                    triggerContent={<Icon icon="fasInfoCircle" />}
                    placement="top"
                    popoverContent={
                      <div className="password-rules">
                        <b>Password Rules:</b>
                        <ul>
                          <li>Must be between 6 - 50 characters</li>
                          <li>Must contain at least 1 uppercase letter</li>
                          <li>Must contain at least 1 lowercase letter</li>
                          <li>Must contain at least 1 number</li>
                          <li>Must contain at least 1 special character</li>
                        </ul>
                      </div>
                    }
                  />
                }
                maxLength={50}
                required
              />
              <Icon
                icon="farEye"
                size="1x"
                onClick={() =>
                  setShowSecret({ ...showSecret, new: !showSecret.new })
                }
                className="secret-toggle"
              />
            </FormGroup>
            <FormGroup wrap={false}>
              <Input
                id="confirmNewPassword"
                name="confirmNewPassword"
                label="Confirm New Password"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnConfirmNewPassword(values.newPassword)}
                type={showSecret.confirm ? "text" : "password"}
                value={values.confirmNewPassword}
                size="full"
                maxLength={50}
                required
              />
              <Icon
                icon="farEye"
                size="1x"
                onClick={() =>
                  setShowSecret({ ...showSecret, confirm: !showSecret.confirm })
                }
                className="secret-toggle"
              />
            </FormGroup>
            <FormGroup>
              <Button type="submit" variant="primary">
                Change Password
              </Button>
              &nbsp;
              <Button onClick={handleCancel}>Cancel</Button>
            </FormGroup>
          </Form>
        </div>
      </div>
    </div>
  );
};

ChangePassword.propTypes = propTypes;

export default ChangePassword;
