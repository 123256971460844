import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  action: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
  alternateText: PropTypes.string.isRequired
};

const Advertisement = ({ action, imageSource, alternateText }) => (
  <a
    href={action}
    target="_blank"
    rel="noopener noreferrer"
    className="feature-link"
  >
    <img src={imageSource} alt={alternateText} />
  </a>
);

Advertisement.propTypes = propTypes;

export default Advertisement;
