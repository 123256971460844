import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "@ufginsurance/ui-kit";
import Currency from "../Currency";
import { Translations } from "../translations";
import { v4 } from "uuid";

const propTypes = {
  account: PropTypes.object.isRequired
};

const emptyCell = () => <td />;

const renderChangedIndicator = wasChanged => (wasChanged ? "*" : null);

const renderPolicies = (policies, origin) => {
  return policies.map(policy => {
    return (
      <tr key={`${policy.policy_number}-${v4()}`}>
        {emptyCell()}
        <td>{policy.policy_number}</td>
        <td>{formatDate(policy.bill_date, "MM/DD/YYYY")}</td>
        {origin !== "oasis" && (
          <td>
            <Currency quantity={policy.balance} />
          </td>
        )}
        <td>
          {renderChangedIndicator(policy.amount_due_changed)}
          <Currency quantity={policy.amount_due} />
        </td>
      </tr>
    );
  });
};

const renderInstallmentsTable = (installments, origin) => {
  const { account_label } = Translations.installment_schedule;
  return installments.map((installment, i) => {
    return (
      //eslint-disable-next-line react/no-array-index-key
      <tbody key={i}>
        <tr className="installment-schedule__account-row">
          <td>{formatDate(installment.due_date, "MM/DD/YYYY")}</td>
          <td>{account_label}</td>
          {emptyCell()}
          {origin !== "oasis" && (
            <td>
              <Currency quantity={installment.balance} />
            </td>
          )}
          <td>
            {renderChangedIndicator(installment.amount_due_changed)}
            <Currency quantity={installment.amount_due} />
          </td>
        </tr>
        {renderPolicies(installment.policies, origin)}
      </tbody>
    );
  });
};

const InstallmentSchedule = ({ account }) => {
  const { table_headings } = Translations.installment_schedule;
  const { table_headings_oasis } = Translations.installment_schedule;
  return (
    <table className="table installment-schedule">
      <thead className="installment-schedule__column-headings">
        <tr>
          {account.origin === "oasis"
            ? table_headings_oasis.map(heading => <td key={v4()}>{heading}</td>)
            : table_headings.map(heading => <td key={v4()}>{heading}</td>)}
        </tr>
      </thead>
      {renderInstallmentsTable(account.installments, account.origin)}
      <tfoot className="installment-schedule__footer">
        <tr>
          {emptyCell()}
          {emptyCell()}
          {account.origin !== "oasis" && emptyCell()}
          <td>Total:</td>
          {/* translations */}
          <td>
            <Currency quantity={account.total_amount_due} />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

InstallmentSchedule.propTypes = propTypes;

export default InstallmentSchedule;
