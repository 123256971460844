import React, { Component } from "react";
import PropTypes from "prop-types";
import QuickLinks from "../components/QuickLinks";
import { fetchQuickLinks } from "../actions/fetchQuickLinksActions";
import { connect } from "react-redux";
import { userSelector } from "@ufginsurance/sso-oidc-client-react";

class QuickLinksContainer extends Component {
  static propTypes = {
    fetchQuickLinks: PropTypes.func.isRequired,
    quickLinksData: PropTypes.object.isRequired,
    user: PropTypes.object
  };

  componentDidMount() {
    this.props.fetchQuickLinks(this.props.user.profile.policyholderId);
  }

  render() {
    const { data, isLoading, error } = this.props.quickLinksData;
    return <QuickLinks links={isLoading || (!data && error) ? [] : data} />;
  }
}

const mapStateToProps = state => ({
  user: userSelector.getOpenIDUser(state),
  quickLinksData: {
    data: state.quickLinksData.data,
    error: state.quickLinksData.error,
    isLoading: state.quickLinksData.isLoading
  }
});

const mapDispatchToProps = {
  fetchQuickLinks
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickLinksContainer);
