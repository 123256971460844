import {
  ADD_ACCOUNT_FAILED,
  ADD_ACCOUNT_STARTED,
  ADD_ACCOUNT_SUCCEEDED,
  FETCH_REREGISTER_ACCOUNT_SUCCEEDED
} from "../constants/actionTypes";
import initialState from "./initialState";

/*
 * TODO - Chris: The addAccountDataReducer and this reducer both handle some of
 * the same actions. We should either merge this into addAccountDataReducer or
 * remove reregistration actions from addAccountDataReducer.
 */
const reregisterAccountReducer = (
  state = initialState.reregistrationData,
  action
) => {
  switch (action.type) {
    case ADD_ACCOUNT_FAILED:
    case ADD_ACCOUNT_STARTED:
    case ADD_ACCOUNT_SUCCEEDED:
      return initialState.reregistrationData;
    case FETCH_REREGISTER_ACCOUNT_SUCCEEDED:
      return {
        ...state,
        data: action.payload,
        // TODO - Chris: We don't update these anywhere so they probably need
        // removed from this reducer and initial state.
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
};

export default reregisterAccountReducer;
