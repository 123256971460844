import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const SidebarNav = ({ header, links }) => {
  return (
    <nav className="nav-menu">
      {header && (
        <div>
          <h3 className="nav-header">{header}</h3>
          <div className="line-container">
            <span className="red-line" />
            <span className="grey-line" />
          </div>
        </div>
      )}
      {links.map(link => {
        return (
          <NavLink
            key={link.to}
            to={link.to}
            activeClassName={link.activeClassName}
          >
            <i className={`nav-menu-icon ${link.iconClass}`} />
            {link.text}
          </NavLink>
        );
      })}
    </nav>
  );
};

SidebarNav.propTypes = {
  header: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      text: PropTypes.string.isRequired,
      activeClassName: PropTypes.string,
      iconClass: PropTypes.string
    }).isRequired
  ).isRequired
};

export default SidebarNav;
