import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Alert, Anchor } from "@ufginsurance/ui-kit";
import * as routes from "../../constants/routes";

const PaperlessBillingAlert = ({ history }) => {
  return (
    <Alert className="account-list-v2__paperless-alert">
      <div>Go green with paperless billing!</div>
      <Anchor
        onClick={() => history.push(`${routes.BILLING_AND_ALERTS_ROUTE}`)}
      >
        Enroll now
      </Anchor>
    </Alert>
  );
};

PaperlessBillingAlert.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(PaperlessBillingAlert);
