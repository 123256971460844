import * as policyholderService from "../services/policyholderService";
import * as types from "../constants/actionTypes";

export const fetchBillingStatementDataStarted = () => ({
  type: types.FETCH_BILLING_STATEMENT_DATA_STARTED
});

export const fetchBillingStatementDataSucceeded = data => ({
  type: types.FETCH_BILLING_STATEMENT_DATA_SUCCEEDED,
  payload: data
});

export const fetchBillingStatementDataFailed = error => ({
  type: types.FETCH_BILLING_STATEMENT_DATA_FAILED,
  payload: error
});

// Async action creator using thunk middleware
export const fetchBillingStatementData = () => dispatch => {
  dispatch(fetchBillingStatementDataStarted());

  return policyholderService.getBillingStatements().then(
    response => {
      dispatch(fetchBillingStatementDataSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchBillingStatementDataFailed(error));
    }
  );
};
