import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const changePasswordDataReducer = (
  state = initialState.changePasswordData,
  action
) => {
  switch (action.type) {
    case types.CHANGE_PASSWORD_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.CHANGE_PASSWORD_SUCCEEDED:
      return {
        ...state,
        changeSucceeded: true,
        redirectToMyProfile: true,
        isLoading: false,
        error: null
      };
    case types.CLEAR_CHANGE_PASSWORD_SUBMISSION:
      return {
        ...state,
        changeSucceeded: false,
        isLoading: false,
        error: null
      };

    case types.CLEAR_REDIRECT_TO_MYPROFILE:
      return {
        ...state,
        redirectToMyProfile: false,
        isLoading: false,
        error: null
      };

    case types.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        changeSucceeded: false,
        isLoading: false,
        error: {
          ...action.payload,
          data: action.payload.response.data.detail
            ? action.payload.response.data.detail
            : action.payload.response.data
        }
      };
    default:
      return state;
  }
};

export default changePasswordDataReducer;
