import { connect } from "react-redux";
import PolicyActivity from "./PolicyActivity";

const mapStateToProps = state => ({
  policyActivityData: state.policyActivityData
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PolicyActivity);
