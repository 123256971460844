import React, { Component } from "react";
import PropTypes from "prop-types";
import { Alert } from "@ufginsurance/ui-kit";
import { Translations } from "./translations";
import ErrorView from "../components/ErrorView";
import ErrorLevel from "../constants/errorLevel";
import { ErrorCodes } from "../constants/errorCodes";
import { SYSTEMS_UNAVAILABLE_ROUTE } from "../constants/routes";
import { logger } from "../logging";
import { DirectBillEmailLink, HelpDeskEmailLink } from "./email";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const isCustomError = error => error.data && error.data.status;

const getCustomErrorStatus = error => parseInt(error.data.status, 10);

const getHttpStatus = error => {
  // oringally: error => error.response && error.response.status
  const value =
    error.response && error.response.status
      ? parseInt(error.response.status, 10)
      : 500;

  return value;
};

const getErrorStatus = error => {
  if (isCustomError(error)) {
    return getCustomErrorStatus(error);
  }

  const httpStatus = getHttpStatus(error);

  // OPP2-2036: It was decided to default all 500 errors to the service unavailable message.
  // This is something I'm, (Doug Mason), am in diagreement with and oppose but this was of group decision.
  // originally: httpStatus ? httpStatus : ErrorCodes.ServerError;
  return httpStatus ? httpStatus : ErrorCodes.ServiceUnavailable; //.ServerError; //originally else defaulted to ServerError.
};

class CustomErrorView extends Component {
  componentDidMount() {
    if (!this.wrapper) {
      return;
    }

    window.scrollTo({ top: this.wrapper.offsetTop, behavior: "smooth" });
  }

  setWrapper = el => (this.wrapper = el);

  render() {
    const { error, errorLocationInTranslation, helpDeskErrorCodes } =
      this.props;
    if (!error) return null;

    logger.error({
      description: `Error fetching: ${JSON.stringify(error)}`,
      fatal: false
    });

    const errorStatus = getErrorStatus(error);
    // Possibly unnecessary, but including this check and redirect as catch-all
    if (
      errorStatus === ErrorCodes.ServiceUnavailable ||
      errorStatus === ErrorCodes.GatewayTimeout
    ) {
      window.location.replace(SYSTEMS_UNAVAILABLE_ROUTE);
      return null;
    }

    const errorCode = error?.data?.error_code;
    const errorMessage = errorLocationInTranslation[error.data.error_code];
    const isSpecificError =
      (errorStatus === 400 || errorStatus === 500) && errorCode && errorMessage;
    const isHelpDeskError = helpDeskErrorCodes?.includes(errorCode?.toString());

    return (
      <div ref={this.setWrapper}>
        {isSpecificError ? (
          <Alert type="error" dismissible={false}>
            {isHelpDeskError ? (
              <HelpDeskMessage before={errorMessage} />
            ) : (
              Translations.formatString(
                errorMessage,
                <HelpDeskEmailLink />,
                <DirectBillEmailLink />
              )
            )}
          </Alert>
        ) : (
          <ErrorView statusCode={errorStatus} level={ErrorLevel.danger} />
        )}
      </div>
    );
  }
}

CustomErrorView.propTypes = {
  error: PropTypes.object,
  errorLocationInTranslation: PropTypes.object.isRequired,
  helpDeskErrorCodes: PropTypes.arrayOf(PropTypes.string)
};

export default CustomErrorView;
