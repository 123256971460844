import React from "react";
import { SidebarNav } from "../ui-kit";
import { Translations } from "../../components/translations";
import * as routes from "../../constants/routes";

const links = [
  {
    to: `${routes.CLAIMS_ROUTE_ROOT}/open`,
    activeClassName: "nav-menu--active",
    text: Translations.claims.open_claims,
    iconClass: "fa fa-caret-right"
  },
  {
    to: `${routes.CLAIMS_ROUTE_ROOT}/closed`,
    activeClassName: "nav-menu--active",
    text: Translations.claims.claims_history,
    iconClass: "fa fa-caret-right"
  }
];

const ClaimsNav = () => {
  return <SidebarNav links={links} header={Translations.claims.claims} />;
};
export default ClaimsNav;
