import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useForm, Form, Switch, Modal } from "@ufginsurance/ui-kit";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import PaperlessUpdateModal from "../payments-and-alerts/PaperlessUpdateModal";
import { v4 } from "uuid";

const PaperlessPreferences = ({ account }) => {
  const userProfile = useSelector(state => state.profileData?.userProfile);
  const billingAndAlertsData = useSelector(state => state.billingAndAlertsData);
  const isEditableSession = useSelector(state =>
    sessionSelector.policyholderHasWriteAccess(state)
  );

  const paperless =
    billingAndAlertsData?.alertsData?.find(_ => _.number === account?.number) ||
    {};

  const alertMetadata = [
    { type: "paperless_bill", display: "Paperless Billing" },
    { type: "bill_email_alert", display: "Payment Email Alerts" },
    { type: "policy_email_alert", display: "Policy Email Alerts" }
  ];

  const initialValues = alertMetadata.reduce(
    (o, alert) => ({
      ...o,
      [`${alert.type}-toggle-${paperless?.number}`]:
        paperless[alert.type]?.option === "yes"
    }),
    {}
  );

  const [isConfirmPaperlessVisible, setIsConfirmPaperlessVisible] =
    useState(false);
  const [modalPayload, setModalPayload] = useState({});

  const form = useForm({ values: initialValues, onSubmit: () => {} });

  const { values, handleOnBlur, handleOnValidate, handleOnChange } = form;

  const profileWithUpdatableEmails = {
    ...userProfile,
    accountEmails: [paperless?.alert_contact_email, userProfile?.email].filter(
      (a, i, v) => v.indexOf(a) === i && a
    )
  };

  const modalType =
    modalPayload?.option === "no" || modalPayload.name === "policy_email_alert"
      ? "confirm"
      : "terms";

  const handlePaperlessChange = ({ field, value }, alertType) => {
    setModalPayload({
      number: paperless?.number,
      option: value ? "yes" : "no",
      name: alertType,
      handler: () => handleOnChange({ field, value })
    });
    setIsConfirmPaperlessVisible(true);
  };

  return (
    <Form context={form}>
      {alertMetadata.map(alert => {
        if (paperless[alert.type]?.eligibility !== "updatable") return <></>;
        return (
          <div
            className="panel-row paperless-row"
            key={`${alert.type}-toggle-${paperless?.number}`}
          >
            <span>{alert.display}</span>
            <span>
              <Switch
                id={`${alert.type}-toggle-${v4()}`}
                name={`${alert.type}-toggle-${paperless?.number}`}
                value={values[`${alert.type}-toggle-${paperless?.number}`]}
                onBlur={handleOnBlur}
                onChange={_ => handlePaperlessChange(_, alert.type)}
                onValidate={handleOnValidate}
                label={alert.display}
                noLabel
                disabled={!isEditableSession || billingAndAlertsData.isLoading}
                dataWrapperAttrib={[{ name: alert.type }]}
              />
            </span>
          </div>
        );
      })}
      {isConfirmPaperlessVisible && (
        <Modal
          title={
            modalType === "terms" ? "Terms & Conditions" : "Confirm Update"
          }
          show={isConfirmPaperlessVisible}
          onHide={() => setIsConfirmPaperlessVisible(false)}
          altCloseMethod={false}
          body={
            <PaperlessUpdateModal
              payload={modalPayload}
              userProfile={profileWithUpdatableEmails}
              hideModal={() => setIsConfirmPaperlessVisible(false)}
              embeddedIn="summary"
            />
          }
        />
      )}
    </Form>
  );
};

PaperlessPreferences.propTypes = {
  account: PropTypes.object.isRequired
};

export default PaperlessPreferences;
