import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { headerLogo } from "../imageSourceConstants";
import { Link } from "react-router-dom";
import { Translations } from "./translations";
import RouteNavItem from "./RouteNavItem";
import DateFormat from "./DateFormat";
import moment from "moment";
import { AnalyticCategories } from "../constants/analytics";
import { logger } from "../logging";
import * as routes from "../constants/routes";
import { withRouter } from "react-router";
import {
  userSelector,
  sessionSelector,
  ssoActions
} from "@ufginsurance/sso-oidc-client-react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navExpanded: false,
      userNavExpanded: false
    };
  }

  static propTypes = {
    user: PropTypes.object,
    lastLoginDate: PropTypes.string,
    employeeUsername: PropTypes.string,
    isImpersonating: PropTypes.bool,
    logoutUser: PropTypes.func
  };

  componentDidMount = () => {
    document.addEventListener("click", this.handleOutsideClick);
  };
  componenWillUnmount = () => {
    document.removeEventListener("click", this.handleOutsideClick);
  };
  toggleNav = () => {
    this.setState({ navExpanded: !this.state.navExpanded });
  };

  toggleUserNav = () => {
    this.setState({ userNavExpanded: !this.state.userNavExpanded });
  };

  trackAnalytics = evt => {
    logger.event({
      category: AnalyticCategories.navigation,
      action: evt.target.textContent
    });
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleOutsideClick = e => {
    if (
      !this.wrapperRef ||
      this.wrapperRef.contains(e.target) ||
      !this.state.userNavExpanded
    ) {
      return;
    }

    this.toggleUserNav();
  };

  handleNavClick = evt => {
    this.trackAnalytics(evt);
    this.toggleUserNav();
  };

  handleLogout = evt => {
    evt.preventDefault();
    this.props.logoutUser(this.props.user);
  };

  render() {
    const { employeeUsername, isImpersonating } = this.props;

    const identity = {
      username: this.props.user.profile.name,
      lastLoginDateUtc: this.props.lastLoginDate
    };
    const lastLoggedIn =
      identity && identity.lastLoginDateUtc
        ? moment(identity.lastLoginDateUtc)
        : new Date();
    const inout = this.state.navExpanded ? "in" : "out";
    const openclosed = this.state.userNavExpanded ? "open" : "closed";
    return (
      <nav className="header">
        <div className="header__navbar-container">
          <div>
            <button
              type="button"
              className="header__navbar-button navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
              onClick={this.toggleNav}
            >
              <i className="fa fa-bars" aria-hidden="true">
                <span className="sr-only">Toggle navigation</span>
              </i>
            </button>
            <Link to={routes.ROOT_ROUTE} className="logo">
              <img src={headerLogo} alt="UFG Insurance Logo" />
            </Link>
          </div>
        </div>
        <div className={`main-nav main-nav--${inout}`}>
          <div className="header__navbar-container">
            <ul className="main-nav__nav-list">
              <RouteNavItem
                to={routes.ACCOUNTS_ROUTE}
                activeAtRoot
                closeMenu={this.toggleNav}
              >
                {Translations.navigation.account}
              </RouteNavItem>
              <RouteNavItem
                to={routes.POLICY_ROUTE}
                tabRoot={routes.POLICY_ROUTE}
                closeMenu={this.toggleNav}
              >
                {Translations.navigation.my_policy}
              </RouteNavItem>
              <RouteNavItem
                to={routes.DEFAULT_CLAIMS_ROUTE}
                tabRoot={routes.CLAIMS_ROUTE_ROOT}
                closeMenu={this.toggleNav}
              >
                {Translations.navigation.claims}
              </RouteNavItem>
              <RouteNavItem
                to={routes.AUTOMATIC_PAYMENTS_ELIGIBLE_ACCOUNTS_ROUTE}
                tabRoot={routes.PAYMENTS_AND_ALERTS_TAB_ROOT_ROUTE}
                closeMenu={this.toggleNav}
              >
                {Translations.navigation.automatic_payments}
              </RouteNavItem>
            </ul>
            <div id="hdr-tools">
              <div className="hdr-search" />
              <div className="user-welcome user-tools">
                <div
                  className={`dropdown ${openclosed}`}
                  ref={this.setWrapperRef}
                >
                  <p>
                    {isImpersonating
                      ? `${employeeUsername} logged in as [${identity.username}]`
                      : `${Translations.navigation.welcome} ${identity.username}`}
                  </p>
                  <p>
                    <small>
                      {Translations.navigation.last_login}
                      &nbsp;
                      {DateFormat(lastLoggedIn, "dddd, MMMM DD, YYYY")}
                    </small>
                  </p>
                  <button
                    className="user-menu__toggle"
                    onClick={this.toggleUserNav}
                    style={
                      !this.state.userNavExpanded
                        ? { transform: "rotate(0deg)" }
                        : { transform: "rotate(180deg)" }
                    }
                  >
                    <i className="fa fa-chevron-down" />
                  </button>
                  <div
                    className={`user-menu user-menu--${openclosed}`}
                    aria-labelledby="user-dropdown"
                  >
                    <ul className="user-menu__list">
                      <li>
                        <Link
                          to={routes.MY_PROFILE_ROUTE}
                          onClick={this.handleNavClick}
                        >
                          My Profile
                        </Link>
                      </li>
                      <li>
                        <Link to={""} onClick={this.handleLogout}>
                          {Translations.header.logout}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="header__contact">
                <p>
                  {Translations.navigation.help_title}
                  <span>{Translations.navigation.help_phone}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => ({
  user: userSelector.getOpenIDUser(state),
  lastLoginDate: sessionSelector.getLastLoginDate(state),
  isImpersonating: sessionSelector.isImpersonatingAPolicyholder(state),
  employeeUsername: sessionSelector.getEmployeeUserName(state)
});

const mapDispatchToProps = {
  logoutUser: ssoActions.logoutAndRedirect
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
