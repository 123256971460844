import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchSubmitEditProfileStarted = () => ({
  type: types.SUBMIT_EDIT_PROFILE_STARTED
});

export const fetchSubmitEditProfileSucceeded = data => ({
  type: types.SUBMIT_EDIT_PROFILE_SUCCEEDED,
  payload: data
});

const fetchSubmitEditProfileFailed = error => ({
  type: types.SUBMIT_EDIT_PROFILE_FAILED,
  payload: error,
  error: true
});

export const clearEditProfileSubmission = () => ({
  type: types.CLEAR_EDIT_PROFILE_SUBMISSION
});

const clearRedirect = () => ({
  type: types.CLEAR_MY_PROFILE_REDIRECT
});

// Async action creator using thunk middleware
export const submitEditProfile =
  (
    userProfile,
    { submissionSuccessMessage, showAutopayEmailModalAfterSubmit }
  ) =>
  dispatch => {
    dispatch(fetchSubmitEditProfileStarted());

    return policyholderService.submitEditProfile(userProfile).then(
      response => {
        return dispatch(
          fetchSubmitEditProfileSucceeded({
            userProfile: response.data,
            submissionMessage: submissionSuccessMessage,
            showAutopayEmailModalAfterSubmit
          })
        );
      },
      error => {
        // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
        dispatch(fetchSubmitEditProfileFailed(error));
        return Promise.reject(error);
      }
    );
  };

export function clearSubmissionNotification() {
  return dispatch => {
    dispatch(clearEditProfileSubmission());
  };
}

export function clearMyProfileRedirectNotification() {
  return dispatch => {
    dispatch(clearRedirect());
  };
}
