import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const myPolicyFormsReducer = (
  state = initialState.myPolicyFormsData,
  action
) => {
  switch (action.type) {
    case types.FETCH_POLICY_DECS_SUCCEEDED:
      return {
        ...state,
        error: null
      };
    case types.FETCH_POLICY_FORMS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_POLICY_FORMS_SUCCEEDED: {
      const { data, policyNumber, coverageOption } = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [policyNumber]: {
            ...state.forms[policyNumber],
            [coverageOption]: data
          }
        },
        isLoading: false,
        error: null
      };
    }
    case types.FETCH_POLICY_FORMS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default myPolicyFormsReducer;
