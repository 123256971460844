import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  formatDate,
  Icon,
  Panel,
  ToolTip,
  Button,
  ButtonDropNav,
  LoadingIndicator
} from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import Currency from "../Currency";
import { isPayGo } from "../../utils";
import { iconPolicyStatusFactory } from "../../constants/policyStatusIcons";
import { getPdf } from "../../services/policyholderService";
import DocumentService from "../../services/documentService";
import InfoModal from "../../components/modals/InfoModal";
import {
  LOADING_DOCUMENT_ROUTE,
  POLICY_ROUTE,
  BILLING_ACTIVITY_ROUTE,
  INSTALLMENT_ROUTE
} from "../../constants/routes";
import HelpDeskMessage from "../../help-desk/HelpDeskMessage";
import { useHistory } from "react-router-dom";
import BillStatement from "./BillStatement.js";
import { logger } from "../../logging";
import { AnalyticCategories } from "../../constants/analytics";

const PolicySummary = ({
  policy,
  isOasis,
  fetchCancellationNotice,
  accountNumber
}) => {
  const history = useHistory();

  const effectiveDate = Translations.formatString(
    Translations.account_summary.policy_list.effective_date_detail,
    formatDate(policy?.effective_date, "MM/DD/YYYY"),
    formatDate(policy?.expiration_date, "MM/DD/YYYY")
  );

  const timeout = parseInt(window.env.REACT_APP_API_TIMEOUT, 10);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.account,
      action: "Pay bill Clicked"
    });
  };

  const openPaymentUrl = useCallback(
    event => {
      event?.stopPropagation();
      trackAnalytics();
      window.location = `${
        policy?.billing?.online_payment?.payment_link
      }&CancellationUrl=${encodeURIComponent(window.location.href)}`;
    },
    [policy?.billing?.online_payment?.payment_link]
  );

  const handleGetPdf = useCallback(
    (filename, pdfUri) => {
      setIsLoading(true);

      const documentRenderer = new DocumentService();
      documentRenderer.initializeRenderer(LOADING_DOCUMENT_ROUTE);

      const key = "get:" + pdfUri;
      sessionStorage.setItem(key, Date.now());

      getPdf(pdfUri, { timeout }).then(
        response => {
          documentRenderer.saveOrOpenDocument(response.data, filename);
          setIsLoading(false);
        },
        e => {
          documentRenderer.clearRenderer();
          setIsLoading(false);
          setIsError(e);
        }
      );
    },
    [timeout]
  );

  const renderErrorDisplay = () => {
    return (
      <InfoModal
        hideModal={() => setIsError(false)}
        title="Error"
        message={
          <HelpDeskMessage before="There was an error loading your document. For further assistance," />
        }
      />
    );
  };

  const policyTypeMessage = useCallback(() => {
    if (isPayGo(policy?.billing?.payment_type))
      return "Workers' Comp (Pay as you go)";

    if (isOasis) {
      return policy.type;
    }

    const types = Translations.policy_types;
    return !types.hasOwnProperty(policy.type)
      ? policy.number
      : types[policy.type];
  }, [policy, isOasis]);

  const {
    //showInfoIcon,
    policyStatus,
    policyHeader,
    linesOfBusiness,
    showPendingCancellationNotice,
    showAutoIdCardsButton,
    isPaymentPastDue
  } = useMemo(() => {
    const showInfoIcon = policy?.policy_lines?.length > 1;

    const policyHeader = policyTypeMessage();
    const linesOfBusiness = policy?.policy_lines?.join(", ") ?? "";

    const showPendingCancellationNotice =
      policy?.status === "pending_cancellation";

    const policyStatus =
      policy?.status && Translations.policy_statuses[policy?.status];

    const showAutoIdCardsButton =
      policy?.auto_id_link && policy?.status !== "expired";

    const isPaymentPastDue =
      policy?.billing?.due_date && policy?.billing?.is_past_due;

    return {
      linesOfBusiness,
      policyHeader,
      showInfoIcon,
      showPendingCancellationNotice,
      policyStatus,
      showAutoIdCardsButton,
      isPaymentPastDue
    };
  }, [
    policy?.auto_id_link,
    policy?.policy_lines,
    policy?.status,
    policyTypeMessage,
    policy?.billing
  ]);

  const policyLinks = useMemo(() => {
    const links = [
      {
        text: "My Policy",
        url: `${POLICY_ROUTE}/${policy.number}`
      },
      {
        text: "Billing Activity",
        url: `${BILLING_ACTIVITY_ROUTE}/${accountNumber}/${policy.number}`
      },
      {
        text: "Installment Schedule",
        url: `${INSTALLMENT_ROUTE}/${accountNumber}`
      }
    ];

    if (showAutoIdCardsButton) {
      links.push({
        text: "Auto ID Cards",
        pdfUri: policy.auto_id_link,
        filename: "AutoId_Cards.pdf"
      });
    }

    if (!!policy?.rx_card_link) {
      links.push({
        text: "RX Card",
        pdfUri: policy.rx_card_link,
        filename: "rxcard.pdf"
      });
    }
    return links;
  }, [showAutoIdCardsButton, policy, accountNumber]);

  const { iconPolicyStatus, iconPolicyStatusColor } = iconPolicyStatusFactory(
    policyStatus ?? ""
  );
  const policySummaryClass = showPendingCancellationNotice
    ? ` policy-summary-pending-cancellation${isOasis ? "" : " policy-summary-pending-cancellation-legacy"}`
    : "";

  const PolicyStatusIfCancellation = ({ condition, wrapper, children }) => {
    return condition ? wrapper(children) : children;
  };

  const getCancellationNotice = () => {
    fetchCancellationNotice(policy.number);
  };

  const handlePolicyLinkChange = useCallback(
    link => {
      if (!!link?.url) {
        history.push(link.url);
      } else if (!!link?.filename) {
        handleGetPdf(link.filename, link.pdfUri);
      }
    },
    [history, handleGetPdf]
  );

  return (
    <Panel shadow className={`policy-summary-main-panel${policySummaryClass}`}>
      {isError && renderErrorDisplay()}

      <p className="panel-row">
        <span>
          <b>{policyHeader}&nbsp;</b>
          {policy.type === "UNI-PAK" || policy.type === "Commercial Package" ? (
            <>
              <ToolTip
                position="top"
                variant="white"
                type="hover"
                content={<div className="tooltip_hover">{linesOfBusiness}</div>}
                trigger={<Icon size="sm" icon="fasInfoCircle" />}
              />
              &nbsp;
            </>
          ) : null}
          <b>- {policy?.number || ""}</b>
        </span>
        <span></span>
      </p>
      <p className="panel-row">
        <span>Status</span>
        <span>
          <PolicyStatusIfCancellation
            condition={showPendingCancellationNotice}
            wrapper={children => (
              <Button
                isLink
                variant="tertiary"
                onClick={getCancellationNotice}
                inline
              >
                {children}
              </Button>
            )}
          >
            <>
              {!!iconPolicyStatus && (
                <Icon
                  icon={iconPolicyStatus}
                  style={{
                    fontSize: "14px",
                    color: iconPolicyStatusColor,
                    marginRight: "5px"
                  }}
                />
              )}
              {policyStatus}
            </>
          </PolicyStatusIfCancellation>
        </span>
      </p>
      <p className="panel-row">
        <span>Effective Date</span>
        <span>{effectiveDate}</span>
      </p>

      <p className="panel-row">
        <span>Named Insured</span>
        <span>{policy?.name || ""}</span>
      </p>

      <p className="panel-row">
        <span>Premium</span>
        <span>
          {policy?.total_premium ? (
            <Currency quantity={policy?.total_premium} />
          ) : (
            "-"
          )}
        </span>
      </p>

      {!isOasis && showPendingCancellationNotice && (
        <>
          <p className="panel-row">
            <span>Account Balance</span>
            <span>
              <Currency quantity={policy?.billing?.balance} currency="USD" />
            </span>
          </p>
          <p className="panel-row">
            <span>Minimum Payment Due</span>
            <span className={isPaymentPastDue ? "ufg-alert-v2" : ""}>
              <Currency
                quantity={
                  policy?.billing?.account_minimum_due_with_fees ||
                  policy?.billing?.minimum_due
                }
              />
            </span>
          </p>
          <p className="detail">The amount due includes any applicable fees</p>
          <p className="panel-row">
            <span>Due Date</span>
            <span className={isPaymentPastDue ? "ufg-alert-v2" : ""}>
              {policy?.billing?.due_date ? (
                formatDate(policy?.billing?.due_date, "MM/DD/YYYY")
              ) : (
                <span>N/A</span>
              )}
            </span>
          </p>
        </>
      )}
      <p className={`panel-row view-policy-row${showPendingCancellationNotice ? "-cancellation" : ""}`}>
        <span>
          <ButtonDropNav
            id={`policy_links_button_drop_nav_${policy?.number ?? ""}`}
            variant="link"
            buttonText="Policy Links"
            links={policyLinks}
            onClick={handlePolicyLinkChange}
            className="policy_links_button_drop_nav"
            wrapperClassName="policy_links_button_drop_nav_wrapper"
          />
        </span>

        {!isOasis && (
          <span>
            {isOasis && policy?.billing?.last_statement_link && (
              <BillStatement
                className="legacy-cancel-view-bill"
                billStatementLink={policy?.billing?.last_statement_link ?? ""}
              />
            )}

            {policy?.billing?.online_payment && (
              <Button
                variant="tertiary"
                onClick={openPaymentUrl}
                data-js-id="pay-button"
              >
                Pay
              </Button>
            )}
          </span>
        )}
        {isLoading && <LoadingIndicator message="Loading your document..." />}
      </p>
    </Panel>
  );
};

PolicySummary.propTypes = {
  policy: PropTypes.object.isRequired,
  isOasis: PropTypes.bool.isRequired,
  fetchCancellationNotice: PropTypes.func.isRequired,
  accountNumber: PropTypes.string.isRequired
};

export default PolicySummary;
