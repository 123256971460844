import * as policyHolderService from "../services/policyholderService";
import * as types from "../constants/actionTypes";

export const fetchCompanyNotificationsStarted = () => ({
  type: types.FETCH_COMPANY_NOTIFICATIONS_STARTED
});

export const fetchCompanyNotificationsSucceeded = data => ({
  type: types.FETCH_COMPANY_NOTIFICATIONS_SUCCEEDED,
  payload: data
});

export const fetchCompanyNotificationsFailed = error => ({
  type: types.FETCH_COMPANY_NOTIFICATIONS_FAILED,
  payload: error,
  error: true
});

export const fetchCompanyNotifications = () => dispatch => {
  dispatch(fetchCompanyNotificationsStarted());

  return policyHolderService.fetchCompanyNotifications().then(
    response => {
      dispatch(fetchCompanyNotificationsSucceeded(response.data));
    },
    error => {
      // Do not use catch - see https://github.com/facebook/react/issues/7617#issuecomment-247710003
      dispatch(fetchCompanyNotificationsFailed(error));
    }
  );
};
