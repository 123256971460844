export const PaymentTypes = {
  unknown: "unknown",
  notEnrolled: "notsignedup",
  eft: "eft",
  rbp: "rbp",
  payGo: "paygo"
};

export const PaymentSources = {
  checking: "checking",
  savings: "savings",
  master_card: "master_card",
  master_card_debit: "master_card_debit",
  visa: "visa",
  visa_debit: "visa_debit",
  accel: "accel",
  discover: "discover",
  star: "star",
  american_express: "american_express",
  american_express_debit: "american_express_debit",
  nyce: "nyce",
  pulse: "pulse",
  unknown: "unknown"
};

export const MigrationOptions = {
  migrationStarted: "migration_started",
  migrationCompleted: "migration_completed",
  noMigrationNeeded: "no_migration_needed"
};
