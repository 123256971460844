import React, { Component } from "react";
import PropTypes from "prop-types";
import { Translations } from "./translations";
import ErrorView from "../components/ErrorView";
import ErrorLevel from "../constants/errorLevel";
import { ErrorCodes } from "../constants/errorCodes";
import { SYSTEMS_UNAVAILABLE_ROUTE } from "../constants/routes";
import { logger } from "../logging";
import { DirectBillEmailLink, HelpDeskEmailLink } from "./email";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const isCustomError = error => error.data && error.status;

const getCustomErrorStatus = error => parseInt(error.status, 10);

const getHttpStatus = error => {
  const value = error && error.status ? parseInt(error.status, 10) : 500;

  return value;
};

const getErrorStatus = error => {
  if (isCustomError(error)) {
    return getCustomErrorStatus(error);
  }

  const httpStatus = getHttpStatus(error);
  return httpStatus ? httpStatus : ErrorCodes.ServiceUnavailable;
};

class PlainErrorView extends Component {
  componentDidMount() {
    if (!this.wrapper) {
      return;
    }

    window.scrollTo({ top: this.wrapper.offsetTop, behavior: "smooth" });
  }

  setWrapper = el => (this.wrapper = el);

  getTranslatedMessage = errorCode => {
    const {
      errorLocationInTranslation,
      helpDeskErrorCodes,
      hideDirectBillLink
    } = this.props;
    if (helpDeskErrorCodes?.includes(errorCode?.toString()))
      return <HelpDeskMessage before={errorLocationInTranslation[errorCode]} />;
    return Translations.formatString(
      errorLocationInTranslation?.[errorCode] ?? "",
      <>
        <HelpDeskEmailLink />
        {!hideDirectBillLink && (
          <>
            {", "} <DirectBillEmailLink />
          </>
        )}
      </>
    );
  };

  renderCustomError = (errorCodes, useEmTag) => {
    return useEmTag === true ? (
      <em>
        {errorCodes.map(errorCode => this.getTranslatedMessage(errorCode))}
      </em>
    ) : (
      <div>
        {errorCodes.map(errorCode => this.getTranslatedMessage(errorCode))}
      </div>
    );
  };

  render() {
    const { error, useEmTag } = this.props;
    if (!error) return null;

    logger.error({
      description: `Error fetching: ${JSON.stringify(error)}`,
      fatal: false
    });

    const errorStatus = getErrorStatus(error);
    // Possibly unnecessary, but including this check and redirect as catch-all
    if (
      errorStatus === ErrorCodes.ServiceUnavailable ||
      errorStatus === ErrorCodes.GatewayTimeout
    ) {
      window.location.replace(SYSTEMS_UNAVAILABLE_ROUTE);
      return null;
    }

    const errorCodes = Array.isArray(error.data)
      ? error.data?.map(item => item.error_code)
      : [error.data?.error_code];

    return (
      <div ref={this.setWrapper}>
        {errorCodes ? (
          this.renderCustomError(errorCodes, useEmTag)
        ) : (
          <ErrorView statusCode={errorStatus} level={ErrorLevel.danger} />
        )}
      </div>
    );
  }
}

PlainErrorView.propTypes = {
  error: PropTypes.object,
  errorLocationInTranslation: PropTypes.object.isRequired,
  useEmTag: PropTypes.bool,
  hideDirectBillLink: PropTypes.bool,
  helpDeskErrorCodes: PropTypes.arrayOf(PropTypes.string)
};

export default PlainErrorView;
