import React from "react";
import { ContentHeader } from "@ufginsurance/ui-kit";

export const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <ContentHeader>Electronic Terms and Conditions</ContentHeader>
      <p>
        These Electronic Terms and Conditions apply to your United Fire Group
        (UFG) account. The words &quot;United Fire Group&quot;, &quot;we&quot;,
        &quot;us&quot; and &quot;our&quot; refer to United Fire and Casualty
        Company, its subsidiaries and affiliates, your creditor with whom you
        have your account. The word &quot;you&quot; and &quot;your&quot; mean
        you, the individual(s) or entity identified on the account. As used in
        these Electronic Terms and Conditions, &quot;communication&quot; means
        any account periodic statement, or associated authorization, agreement,
        disclosure, notice or other information that would accompany your
        account&apos;s periodic statement including, but not limited to,
        information that we are required by law to provide to you in writing.
      </p>
      <ContentHeader>
        Acceptance of Electronic Terms and Conditions
      </ContentHeader>
      <p>
        By clicking Accept, and registering to use UFG&apos;s electronic billing
        and payment alert options, you are confirming that you have read and
        accept all of the terms and conditions stated in this document, all
        terms and conditions applicable to ufgPolicyholder.com and all terms and
        conditions governing ufginsurance.com.
      </p>
      <ContentHeader>Terms of Use</ContentHeader>
      <p>
        Customers are not obligated to use electronic options. If you do not
        agree with the Electronic Terms and Conditions, you may not use these
        online features. To the extent there is a conflict between any
        requirements posted on our website pages and these Electronic Terms and
        Conditions, the requirements posted on our website pages will govern.
      </p>
      <p>
        By registering for UFG&apos;s electronic billing and payment alert
        options, you agree to provide us with valid, current and correct
        information as requested at ufgPolicyholder.com. You also agree to
        promptly provide us with any updated information. UFG takes the
        fraudulent or unauthorized use of personal, financial or other customer
        information seriously. If we become aware of any fraudulent or
        unauthorized use of such data, we will pursue, and/or disclose such
        activity to the proper authorities, consistent with applicable law.
      </p>
      <p>
        By registering for UFG&apos;s electronic billing and payment alert
        options, you agree to indemnify and hold UFG harmless for any damage,
        claim, loss, action, expense or liability arising from the use of
        electronic account communication by anyone who obtains your username
        and/or password either directly from you or as a result of your failure
        to adequately secure such information. If you believe that your user ID,
        password or other security information has been lost or stolen, you must
        notify us immediately.
      </p>
      <p>
        All communications in either electronic or paper format from us to you
        will be considered &quot;in writing.&quot; You should print or download
        for your records, a copy of your electronic statements, this Electronic
        Terms and Conditions disclosure, your initial authorization to utilize
        UFG&apos;s electronic billing and payment alert options, and any other
        communication that is important to you.
      </p>
      <p>
        By enrolling in UFG&apos;s electronic billing and payment alert options
        and providing your email address, you agree to receive information and
        authorize UFG to communicate with you concerning your billing
        electronically at the email address you provide.
      </p>
      <p>
        UFG&apos;s electronic billing and payment alert options are solely for
        the use of viewing your billing statements online and/or receiving an
        alert when a payment is due soon. By enrolling in UFG&apos;s electronic
        billing options and providing your email address, you agree that UFG
        will no longer send you paper billing statements via postal mail for the
        account(s) you have registered on ufgPolicyholder.com. Instead, UFG will
        send you an email when each new billing statement for the account(s) you
        have registered is made available on ufgPolicyholder.com.
      </p>
      <p>
        Your enrollment in UFG&apos;s electronic billing options will continue
        unless and until you withdraw your consent by changing your electronic
        billing option status. The payment alert option available through
        UFG&apos;s electronic billing options will send an automated email
        within five (5) days of the due date of any outstanding bill. Your
        enrollment in electronic billing option(s) does not limit your
        obligation to pay the minimum amount due by the due date as shown on any
        billing statement or Policy Cancellation Notice you receive. Failure to
        pay the minimum amount due by 7:30 p.m. CT on the due date will result
        in a late fee being assessed to your account and could subject you to
        policy cancellation or termination.
      </p>
      <ContentHeader>Electronic Communication</ContentHeader>
      <p>
        If you register to use UFG&apos;s electronic billing and payment alert
        options, you will receive future notification(s), via email, confirming
        your online account activity, informing you about changes or updates to
        the services available, and/or providing other disclosures that may be
        authorized or required by law.
      </p>
      <ContentHeader>
        How to Change Your Electronic Billing Options
      </ContentHeader>
      <p>
        You may withdraw your consent to electronic billing and payment alert
        options at any time by going to ufgPolicyholder.com and changing your
        electronic billing and payment alert option status. At our option, we
        may treat an invalid email address, or the subsequent malfunction of a
        previously valid email address, as a withdrawal of your consent to use
        our electronic billing and payment alert options. Any withdrawal of your
        consent to use our electronic billing and payment alert options will be
        effective only after we have a reasonable period of time to process your
        withdrawal. In order to use UFG&apos;s electronic billing and payment
        alert options, it is your responsibility to provide us with and maintain
        a true, accurate and complete email address.
      </p>
      <ContentHeader>Requesting Paper Copies</ContentHeader>
      <p>
        Once enrolled in UFG&apos;s electronic billing option, we will not send
        you a paper copy of any communication unless you specifically request it
        or we otherwise deem it appropriate to do so. You may obtain paper
        copies of your billing statements and other communication by printing
        them yourself at ufgPolicyholder.com. To request a paper copy, you may
        contact us by telephone using the number listed on your billing
        statement. We may charge you a reasonable service charge for the
        delivery of paper copies of any communication provided to you
        electronically pursuant to this authorization. We reserve the right, but
        assume no obligation, to provide a paper (instead of electronic) copy of
        any communication that you have previously authorized us to provide
        electronically.
      </p>
      <ContentHeader>Termination/Changes</ContentHeader>
      <p>
        We reserve the right, at our sole discretion, to terminate or modify the
        terms and conditions on which we provide UFG&apos;s electronic billing
        and payment alert options. We will provide you notice of such
        termination or change as required by applicable law.
      </p>
      <ContentHeader>Federal Law</ContentHeader>
      <p>
        You acknowledge and agree that your consent to UFG&apos;s electronic
        billing and payment alert options is being provided in connection with a
        transaction affecting interstate commerce that is subject to the federal
        Electronic Signatures in Global and National Commerce Act, and that you
        and we both intend that the Act apply to the fullest extent possible to
        validate our ability to conduct business through electronic means.
      </p>
      <ContentHeader>Further Questions</ContentHeader>
      <p>
        Please contact us if you have any questions about these Electronic Terms
        and Conditions or electronic billing and email alert options. Questions
        can be directed to UFG Help Desk at 800-895-6253 or to UFG Billing
        Customer Service at 800-637-6309 between 7 a.m. and 6 p.m. CT M-F.
      </p>
      <p>
        Featured words and symbols used to identify the source of goods and
        services may be the trademarks of their respective owners.
      </p>
    </div>
  );
};
