import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@ufginsurance/ui-kit";

const InfoModal = ({
  title,
  message,
  children,
  hideCross,
  hideModal,
  showModal,
  altCloseMethod = true
}) => {
  const modalContent = () => (children ? children : message);

  return (
    <Modal
      title={title}
      show={showModal}
      onHide={hideModal}
      closeIcon={!hideCross}
      body={modalContent()}
      altCloseMethod={altCloseMethod}
    />
  );
};

InfoModal.propTypes = {
  hideCross: PropTypes.bool,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.string
  ]),
  showModal: PropTypes.bool,
  altCloseMethod: PropTypes.bool
};

InfoModal.defaultProps = {
  showModal: true
};

export default InfoModal;
