import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BillingAndAlerts from "../components/payments-and-alerts/BillingAndAlerts";
import LoadingIndicator from "../components/loading-indicator";
import { fetchBillingAndAlerts } from "../actions/billingAndAlertsAction";
import { fetchViewProfile } from "../actions/viewProfileActions";

const BillingAndAlertsContainer = ({
  fetchViewProfile,
  fetchBillingAndAlerts,
  billingAndAlertsData,
  profileData
}) => {
  useEffect(() => {
    fetchBillingAndAlerts();
    fetchViewProfile();
  }, [fetchBillingAndAlerts, fetchViewProfile]);

  const { userProfile } = profileData;
  const { alertsData } = billingAndAlertsData;
  return alertsData && userProfile?.email ? (
    <BillingAndAlerts
      accounts={alertsData}
      email={userProfile?.email}
      userProfile={userProfile}
    />
  ) : (
    <LoadingIndicator />
  );
};

const mapStateToProps = state => ({
  billingAndAlertsData: state.billingAndAlertsData,
  profileData: state.profileData
});

const mapDispatchToProps = {
  fetchBillingAndAlerts,
  fetchViewProfile
};

BillingAndAlertsContainer.propTypes = {
  fetchViewProfile: PropTypes.func.isRequired,
  fetchBillingAndAlerts: PropTypes.func.isRequired,
  billingAndAlertsData: PropTypes.object.isRequired,
  profileData: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingAndAlertsContainer);
