import * as types from "../constants/actionTypes";
import * as policyHolderService from "../services/policyholderService";

export const fetchClaimsHistoryDataStarted = () => ({
  type: types.FETCH_CLAIMS_HISTORY_STARTED
});

export const fetchClaimsHistoryDataSucceeded = data => ({
  type: types.FETCH_CLAIMS_HISTORY_SUCCEEDED,
  payload: data
});

export const fetchClaimsHistoryDataFailed = error => ({
  type: types.FETCH_CLAIMS_HISTORY_FAILED,
  payload: error,
  error: true
});

export const fetchClaimsHistoryData = (number, status) => {
  return dispatch => {
    dispatch(fetchClaimsHistoryDataStarted());

    return policyHolderService.fetchHistoryClaimsData(number, status).then(
      response =>
        dispatch(
          fetchClaimsHistoryDataSucceeded({
            number,
            status,
            data: response.data
          })
        ),
      error => dispatch(fetchClaimsHistoryDataFailed(error))
    );
  };
};
