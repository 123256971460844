import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import AddAccount from "../components/my-profile/AddAccount";
import {
  submitAddAccount,
  reregisterAccountConfirm,
  clearAddAccountSubmission
} from "../actions/addAccountActions";
import { logger } from "../logging";
import LoadingIndicator from "../components/loading-indicator";
import { Translations } from "../components/translations";
import ErrorView from "../components/ErrorView";
import AddPrevRegisteredContainer from "./AddPrevRegisteredContainer";
import ErrorLevel from "../constants/errorLevel";
import { AnalyticCategories } from "../constants/analytics";
import * as routes from "../constants/routes";
import { HelpDeskEmailLink } from "../components/email";
import { sessionSelector } from "@ufginsurance/sso-oidc-client-react";
import { rehydrateSession } from "@ufginsurance/sso-oidc-client-react/lib/actions/ssoActions";

class AddAccountContainer extends Component {
  static propTypes = {
    addAccountData: PropTypes.object.isRequired,
    submitAddAccount: PropTypes.func.isRequired,
    reregistrationData: PropTypes.object,
    reregisterAccountConfirm: PropTypes.func.isRequired,
    isEditableSession: PropTypes.bool,
    rehydrateSession: PropTypes.func.isRequired,
    clearAddAccountSubmission: PropTypes.func.isRequired
  };

  state = {
    willRefresh: false,
    redirectToMyProfile: false,
    isRenderPrevReg: false
  };

  trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.profile,
      action: "An account was successfully added"
    });
  };

  AnyOptionsSelected = item => {
    if (
      item.paperless_bill === "yes" ||
      item.bill_email_alert === "yes" ||
      item.policy_email_alert === "yes"
    ) {
      return true;
    }

    return false;
  };

  AnyRepreviousElectionsRequireConfirmation = () => {
    const {
      reregistrationData: { data }
    } = this.props;

    if (!data.previously_registered) return false;

    if (this.AnyOptionsSelected(data.account_elections)) return true;

    if (
      data.policy_elections.filter(policy => this.AnyOptionsSelected(policy))
        .length >= 1
    )
      return true;

    return false;
  };

  componentDidUpdate = prevProps => {
    if (prevProps.reregistrationData !== this.props.reregistrationData) {
      this.renderPrevReg();
    }
  };

  renderPrevReg = () => {
    const {
      reregistrationData: { data }
    } = this.props;
    if (data && this.AnyRepreviousElectionsRequireConfirmation()) {
      return this.setState({ isRenderPrevReg: true });
    }

    if (data) this.setState({ willRefresh: true });
    return (
      data &&
      this.props
        .reregisterAccountConfirm({
          register_token: data.register_token,
          accepted_previous_elections: true
        })
        .then(
          () => {
            this.props.rehydrateSession("/account?accountAddedSuccess=true");
          },
          () => {
            this.setState({ willRefresh: false });
          }
        )
    );
  };

  handleSubmit = values => {
    this.props.submitAddAccount(values);
  };

  handleCancel = () => {
    this.setState({ redirectToMyProfile: true });
    this.props.clearAddAccountSubmission();
  };

  getErrorDisplay = ErrorResponse => {
    const error_code = ErrorResponse.data.hasOwnProperty("error_code")
      ? ErrorResponse.data.error_code
      : "500";
    if (error_code === 400) {
      return (
        <p className={`alert alert-danger`}>
          {ErrorResponse?.data?.detail?.message ||
            Translations.formatString(
              Translations.add_account.error_codes[error_code],
              <HelpDeskEmailLink />
            )}
        </p>
      );
    }
    return (
      <p className={`alert alert-danger`}>
        {Translations.formatString(
          Translations.add_account.error_codes[error_code],
          <HelpDeskEmailLink />
        )}
      </p>
    );
  };

  render() {
    const { isLoading, error } = this.props.addAccountData;
    const { isEditableSession } = this.props;
    const { redirectToMyProfile, willRefresh } = this.state;

    if (redirectToMyProfile) {
      return <Redirect to={routes.MY_PROFILE_ROUTE} />;
    }

    let errorSummary = null;
    if (error) {
      const errorMessage = `Error add account post: ${JSON.stringify(error)}`;
      logger.error({ description: errorMessage, fatal: false });
      if (error.response.status === 400 || error.response.status === 404) {
        errorSummary = this.getErrorDisplay(error.response);
      } else {
        errorSummary = (
          <ErrorView
            level={ErrorLevel.danger}
            statusCode={error.response.status || "Something went wrong."}
          />
        );
      }
    }

    return (
      <div className="form-controls">
        {this.state.isRenderPrevReg &&
          this.props.reregistrationData.data &&
          this.props.reregistrationData.data.account_elections && (
            <AddPrevRegisteredContainer handleCancel={this.handleCancel} />
          )}
        {isLoading || willRefresh ? (
          <LoadingIndicator />
        ) : (
          <div>
            <AddAccount
              submitAddAccount={this.handleSubmit}
              handleCancel={this.handleCancel}
              errorSummary={errorSummary}
              isEditableSession={isEditableSession}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addAccountData: state.addAccountData,
  reregistrationData: state.reregistrationData,
  isEditableSession: sessionSelector.policyholderHasWriteAccess(state)
});

const mapDispatchToProps = {
  submitAddAccount,
  reregisterAccountConfirm,
  rehydrateSession,
  clearAddAccountSubmission
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAccountContainer);
