import * as types from "../constants/actionTypes";
import * as policyholdersService from "../services/policyholderService";

export const fetchClaimsAccountsStarted = () => ({
  type: types.FETCH_CLAIMS_ACCOUNTS_STARTED
});

export const fetchClaimsAccountsSucceeded = data => ({
  type: types.FETCH_CLAIMS_ACCOUNTS_SUCCEEDED,
  payload: data
});

export const fetchClaimsAccountsFailed = error => ({
  type: types.FETCH_CLAIMS_ACCOUNTS_FAILED,
  payload: error,
  error: true
});

export const fetchClaimsAccounts = () => dispatch => {
  dispatch(fetchClaimsAccountsStarted());

  return policyholdersService.fetchClaimsAccountsData().then(
    response => {
      dispatch(fetchClaimsAccountsSucceeded(response.data));
    },
    error => {
      dispatch(fetchClaimsAccountsFailed(error));
    }
  );
};
