import React from "react";
import PropTypes from "prop-types";
import { Translations } from "../translations";
import DocumentLink from "./DocumentLink";

const AutoId = ({ autoIdLink, className, btnSize }) => {
  return (
    <DocumentLink
      className={className}
      pdfUri={autoIdLink}
      filename={"AutoId_Cards.pdf"}
      btnSize={btnSize}
    >
      <span>{Translations.account_summary.auto_id}</span>
    </DocumentLink>
  );
};

AutoId.propTypes = {
  autoIdLink: PropTypes.string.isRequired,
  className: PropTypes.string,
  btnSize: PropTypes.string
};

export default AutoId;
