import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Translations } from "../components/translations";
import { PhoneLink } from "@ufginsurance/ui-kit";

const HelpDeskMessage = ({ before, after }) => {
  const IsNotLoaded = value =>
    !value || (typeof value === "object" && Object.keys(value).length === 0);

  let info = useSelector(state => state?.helpDeskState?.data);
  if (IsNotLoaded(info)) {
    info = {
      open_time: "7 a.m.",
      close_time: "5 p.m.",
      time_zone: "CTTTTT",
      phone: "800-895-6253",
      email: "helpdesk@unitedfiregroup.com"
    };
  }

  const defaultBefore =
    "We're sorry, but an error occurred while processing your request. Please try again. For further assistance,";
  if (!before) before = defaultBefore;
  const message =
    "please contact the UFG {0} at {4} between {1} and {2} {3} Monday-Friday or email {5}.";

  return (
    <span>
      {!!before && <>{before} </>}
      {Translations.formatString(
        message,
        <a href={"mailto:" + info?.email}>Help Desk</a>,
        info?.open_time,
        info?.close_time,
        info?.time_zone,
        <PhoneLink phoneNumber={`+1${info?.phone}`}>{info?.phone}</PhoneLink>,
        <a href={"mailto:" + info?.email}>{info?.email}</a>
      )}
      {!!after && <> {after}</>}
    </span>
  );
};

HelpDeskMessage.propTypes = {
  before: PropTypes.any,
  after: PropTypes.any
};

export default HelpDeskMessage;
