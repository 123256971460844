import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ContentHeader } from "@ufginsurance/ui-kit";
import { fetchAutomaticPayments } from "../../actions/automaticPaymentsAction";
import { fetchAccountData } from "../../actions/accountActions";
import { logger } from "../../logging";
import errorLevel from "../../constants/errorLevel";
import { ErrorCodes } from "../../constants/errorCodes";
import ErrorBoundary from "../ErrorBoundary";
import ErrorView from "../ErrorView";
import AutomaticPaymentsAccount from "./AutomaticPaymentsAccount";
import AutomaticPaymentsModal from "./AutomaticPaymentsModal";
import orderBy from "lodash.orderby";

const AutomaticPayments = ({ fetchAutomaticPayments, fetchAccountData }) => {
  const automaticPaymentsData =
    useSelector(state => state?.automaticPaymentsData) || {};
  const accountData = useSelector(state => state?.accountData) || {};

  const getError = () => {
    return accountData?.error || automaticPaymentsData?.error;
  };

  const getIsLoading = () => {
    return accountData?.isLoading || automaticPaymentsData?.isLoading;
  };

  useEffect(() => {
    const { accounts, error, isLoading } = automaticPaymentsData;
    if (error !== null || isLoading) return;
    if (!accounts?.length) fetchAutomaticPayments();
    // eslint-disable-next-line
  }, [automaticPaymentsData]);

  useEffect(() => {
    const { accounts, error, isLoading } = accountData;
    if (error !== null || isLoading) return;
    if (!accounts?.length) fetchAccountData();
    // eslint-disable-next-line
  }, [accountData]);

  const renderError = () => {
    const error = getError();
    if (!error) return null;
    if (error?.response && error?.response?.status === 404) return null;
    const errorMessage = `Error fetching automatic payments: ${JSON.stringify(
      error
    )}`;
    logger.error({
      description: errorMessage,
      fatal: false
    });
    const errStatus =
      error?.response && error?.response?.status
        ? parseInt(error?.response?.status, 10)
        : ErrorCodes.ServerError;
    return <ErrorView statusCode={errStatus} level={errorLevel.danger} />;
  };

  const { accounts } = automaticPaymentsData;
  return (
    <ErrorBoundary statusCode={ErrorCodes.ServerError}>
      {renderError()}
      <div className="automatic-payments">
        <div className="automatic-payments_header_container">
          <ContentHeader>
            Automatic Payments
            <AutomaticPaymentsModal />
          </ContentHeader>
        </div>
        <div className="automatic-payments__info-message">
          <i className="fa fa-info-circle" />
          <em>
            You can have payments automatically deducted from your checking or
            savings account each month, with no invoice fees and no worries.
          </em>
        </div>
        {accounts?.length !== 0 &&
          orderBy(accounts, account => account.number, "desc").map(account => (
            <AutomaticPaymentsAccount key={account.number} account={account} />
          ))}
        {accounts?.length === 0 && !getIsLoading() && (
          <p className="automatic-payments--no-accounts">
            There are no eligible policies available for automatic payments.
            Please contact Direct Bill Customer Service at 800-637-6309 for
            further questions.
          </p>
        )}
      </div>
    </ErrorBoundary>
  );
};

AutomaticPayments.propTypes = {
  fetchAutomaticPayments: PropTypes.func.isRequired,
  fetchAccountData: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  fetchAutomaticPayments,
  fetchAccountData
};

export default connect(null, mapDispatchToProps)(AutomaticPayments);
