import React from "react";
import PropTypes from "prop-types";
import PhoneNumber from "../PhoneNumber";
import Address from "./Address";
import { Translations } from "../translations";

const propTypes = {
  name: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  fax: PropTypes.string.isRequired
};

const Contact = ({ name, address, email, phone, fax }) => {
  const { contact } = Translations;
  return (
    <div>
      <div>{name}</div>
      <Address location={address} />
      <div>{email}</div>
      <div>
        {`${contact.phone}: `}
        <PhoneNumber number={phone} />
      </div>
      <div>{`${contact.fax}: ${fax}`}</div>
    </div>
  );
};

Contact.propTypes = propTypes;

export default Contact;
