import React from "react";
import PropTypes from "prop-types";

const isNegative = number => Math.sign(number) === -1;

const negStyle = {
  color: "#b7312c"
};

const Currency = ({ quantity = 0.0 }) => {
  const amount = Number(Math.abs(quantity)).toLocaleString("en", {
    style: "currency",
    currency: "USD"
  });
  return isNegative(quantity) ? (
    <span style={negStyle}>{`(${amount})`}</span>
  ) : (
    amount
  );
};

Currency.propTypes = {
  quantity: PropTypes.number
};

export default Currency;
