import React, { useState } from "react";
import {
  Modal,
  Button,
  Alert,
  Currency,
  Form,
  FormGroup,
  Checkbox,
  Panel,
  useForm
} from "@ufginsurance/ui-kit";
import { TermsAndConditions } from "./terms-and-conditions/AutoPayTermsAndConditions";
import { fetchPaymentVendorRoute } from "../services/policyholderService";
import { logger } from "../logging";
import { AnalyticCategories } from "../constants/analytics";
import "./_AutopayModal.scss";
import HelpDeskMessage from "../help-desk/HelpDeskMessage";

const AutopayModal = ({
  show,
  closeHandler,
  isEditing,
  isEnrolling,
  minimumDue,
  accountNumber
}) => {
  const [areTermsAccepted, setAreTermsAccepted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const payandenroll = window.env.REACT_APP_AUTOPAY_PAYANDENROLL === "true";

  const getActionText = () => {
    if (isEnrolling && minimumDue > 0)
      return payandenroll
        ? "Pay and Enroll in Autopay"
        : "Pay Outstanding Balance";
    if (isEnrolling) return "Enroll in Autopay";
    if (isEditing) return "Edit Autopay";
    return "";
  };

  const handleClose = () => {
    closeHandler();
    setShowError(false);
  };

  const trackAnalytics = () => {
    logger.event({
      category: AnalyticCategories.navigation,
      action: getActionText()
    });
  };

  const handleSubmit = () => {
    setShowError(false);
    trackAnalytics();
    setIsLoading(true);
    fetchPaymentVendorRoute(accountNumber, window.location.href)
      .then(({ data }) => {
        setIsLoading(false);
        window.location = data.url;
      })
      .catch(e => {
        console.log(e);
        setShowError(true);
      })
      .finally(() => setIsLoading(false));
  };

  const form = useForm({ values: {}, onSubmit: handleSubmit });
  const { handleOnBlur, handleOnChange, handleOnValidate } = form;

  const confirmHandler = event => {
    setAreTermsAccepted(!areTermsAccepted);
    handleOnChange(event);
  };

  const enrollText =
    "You can select to enroll in autopay while making your minimum payment on the next page. Autopay will begin on your next billing cycle.";

  const outstandingText = (
    <div>
      <br />
      <b>Note:</b> This payment will not enroll your account in autopay. Please
      wait one business day for your payment to be processed, and then return to
      complete your enrollment.
    </div>
  );

  return (
    <Modal
      id="autopay-enroll-modal"
      show={show}
      onHide={closeHandler}
      title={getActionText()}
      size="lg"
      body={
        <>
          {isEnrolling && minimumDue > 0 && (
            <Alert type="note" dismissible={false} className="autopay-alert">
              Our records indicate that you have a minimum payment due of{" "}
              <Currency amount={minimumDue} /> that has already been billed and
              must be paid first. {payandenroll ? enrollText : outstandingText}
            </Alert>
          )}
          {isEditing && (
            <Alert type="warning" dismissible={false} className="autopay-alert">
              Any edits to your automatic payments must be made at least one
              calendar day prior to the date the payment is scheduled to be
              withdrawn.
            </Alert>
          )}
          {showError && (
            <Alert type="error" className="autopay-alert">
              <HelpDeskMessage />
            </Alert>
          )}
          <Panel bgcolor="grey" className="scrollable-text-block">
            <TermsAndConditions />
          </Panel>
          <Form context={form}>
            <FormGroup>
              <Checkbox
                id="confirm"
                name="confirm"
                value={areTermsAccepted}
                label="I accept the Terms & Conditions outlined above, and I authorize UFG to initiate variable entries to my checking/savings account for my insurance premium payment. The financial institution I identify is authorized to charge insurance premiums to my account."
                onChange={confirmHandler}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                noLabel
              />
            </FormGroup>
            <Panel noBorder className="you-are-leaving">
              You are now leaving the ufgPolicyholder website and are being
              redirected to the site of Paymentus Corporation, our secure
              third-party payment provider. This site is not operated by UFG and
              we are not responsible for the content or availability of this
              site.
            </Panel>
            <FormGroup align="right">
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                type="submit"
                variant="primary"
                disabled={!areTermsAccepted || isLoading}
                spinner={isLoading}
              >
                {getActionText()}
              </Button>
            </FormGroup>
          </Form>
        </>
      }
    />
  );
};

export default AutopayModal;
