import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  fetchAccountData,
  fetchCancellationNotice
} from "../actions/accountActions";
import { fetchAutomaticPayments } from "../actions/automaticPaymentsAction";
import { fetchBillingAndAlerts } from "../actions/billingAndAlertsAction";
import { fetchViewProfile } from "../actions/viewProfileActions";
import LoadingIndicator from "../components/loading-indicator";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorLevel from "../constants/errorLevel";
import ErrorView from "../components/ErrorView";
import { ErrorCodes } from "../constants/errorCodes";
import { logger } from "../logging";
import AccountList from "../components/account-summary/AccountList";
import AccountListV2 from "../components/account-summary-v2/AccountList";
import { useFlags } from "launchdarkly-react-client-sdk";

const AccountListContainer = ({
  accountData,
  billingAndAlertsData,
  profileData,
  fetchCancellationNotice,
  fetchAccountData,
  fetchAutomaticPayments,
  fetchBillingAndAlerts,
  fetchViewProfile
}) => {
  const { customerPortalVersionTwoEnabled } = useFlags();

  const { accounts, isLoading, error } = accountData;
  const alertsData = billingAndAlertsData;
  const addAccountSuccess = new URLSearchParams(window.location.search).get(
    "accountAddedSuccess"
  );
  const linkedAccounts = new URLSearchParams(window.location.search).get(
    "accountsLinked"
  );
  const accountsLinked = linkedAccounts ? linkedAccounts.split(",") : null;

  useEffect(() => {
    fetchAccountData(addAccountSuccess);
    fetchViewProfile();
  }, [addAccountSuccess, fetchAccountData, fetchViewProfile]);

  useEffect(() => {
    if (!isLoading && accountData?.accounts?.length > 0) {
      fetchAutomaticPayments();
      fetchBillingAndAlerts();
    }
  }, [
    isLoading,
    accountData?.accounts,
    fetchAutomaticPayments,
    fetchBillingAndAlerts
  ]);

  if (error) {
    const errorMessage = `Error fetching accounts: ${JSON.stringify(error)}`;

    logger.error({
      description: errorMessage,
      fatal: false
    });

    const errStatus =
      error.response && error.response.status
        ? parseInt(error.response.status, 10)
        : ErrorCodes.ServerError;

    return <ErrorView statusCode={errStatus} level={ErrorLevel.danger} />;
  }

  return (
    <ErrorBoundary statusCode={ErrorCodes.ServerError}>
      <div>
        {isLoading || profileData?.isLoading ? (
          <LoadingIndicator />
        ) : customerPortalVersionTwoEnabled ? (
          <AccountListV2
            addAccountSuccess={addAccountSuccess}
            accounts={accounts}
            billingsAndAlerts={alertsData}
            accountsLinked={accountsLinked}
            fetchCancellationNotice={fetchCancellationNotice}
          />
        ) : (
          <AccountList
            addAccountSuccess={addAccountSuccess}
            accounts={accounts}
            billingsAndAlerts={alertsData}
            accountsLinked={accountsLinked}
            fetchCancellationNotice={fetchCancellationNotice}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

AccountListContainer.propTypes = {
  accountData: PropTypes.object.isRequired,
  billingAndAlertsData: PropTypes.object.isRequired,
  profileData: PropTypes.object.isRequired,
  fetchAccountData: PropTypes.func.isRequired,
  fetchCancellationNotice: PropTypes.func.isRequired,
  fetchAutomaticPayments: PropTypes.func.isRequired,
  fetchBillingAndAlerts: PropTypes.func.isRequired,
  fetchViewProfile: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  accountData: state.accountData,
  billingAndAlertsData: state.billingAndAlertsData,
  profileData: state.profileData
});

const mapDispatchToProps = {
  fetchAccountData,
  fetchAutomaticPayments,
  fetchCancellationNotice,
  fetchBillingAndAlerts,
  fetchViewProfile
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountListContainer);
