import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import Currency from "../Currency";
import Contact from "./Contact";
import Address from "./Address";
import { capitalizeFirstLetter } from "../../utils/stringExtensions";

const propTypes = {
  details: PropTypes.object.isRequired
};

const ClaimsDetail = ({ details }) => {
  const { detailLabels } = Translations.claims;
  return (
    <table className="claims-detail">
      <tbody>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.account_number}</td>
          <td>{details.account_number}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.policy_number}</td>
          <td>{details.policy_number}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.insured_name}</td>
          <td>{details.insured_name}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.line_of_business}</td>
          <td>{details.line_of_business}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.status}</td>
          <td>
            {capitalizeFirstLetter(
              Translations.claims[details.status] || details.status
            )}
          </td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.reported_date}</td>
          <td>{formatDate(details.reported_date, "MM/DD/YYYY")}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.loss_date}</td>
          <td>{formatDate(details.loss_date, "MM/DD/YYYY")}</td>
        </tr>
        {details.status === "closed" && (
          <tr className="claims-detail__table__row">
            <td>{detailLabels.closed_date}</td>
            <td>{formatDate(details.closed_date, "MM/DD/YYYY")}</td>
          </tr>
        )}
        <tr className="claims-detail__table__row">
          <td>{detailLabels.loss_cause}</td>
          <td>{details.loss_cause}</td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.loss_description}</td>
          <td>
            <textarea
              className="claims-detail__loss-description__textarea"
              value={details.loss_description}
              readOnly
            />
          </td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.loss_location}</td>
          <td>
            <Address location={details.loss_location} />
          </td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.loss_total_paid}</td>
          <td>
            <Currency quantity={details.loss_total_paid} />
          </td>
        </tr>
        <tr className="claims-detail__table__row">
          <td>{detailLabels.adjuster}</td>
          <td>
            <Contact
              name={details.adjuster.name}
              address={{
                street: "P.O. Box 73909",
                city: "Cedar Rapids",
                state: "IA",
                zip: "52407"
              }}
              email={window.env.REACT_APP_IR_EMAIL_CLAIM_STATUS}
              phone={details.adjuster.contact_info.phone}
              fax={"888\u2011514\u20119190"}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

ClaimsDetail.propTypes = propTypes;

export default ClaimsDetail;
