import React from "react";
import PropTypes from "prop-types";
import { Panel } from "@ufginsurance/ui-kit";
import { Translations } from "../translations";
import LinesOfBusinessFormsList from "./LinesOfBusinessFormsList";

const propTypes = {
  policyNumber: PropTypes.string.isRequired,
  coverageOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedPolicy: PropTypes.object
};

const LinesOfBusiness = ({ policyNumber, coverageOptions, selectedPolicy }) => {
  return (
    <div>
      {coverageOptions.map(coverageOption => {
        return (
          <div
            className="lines-of-business__forms-list-container"
            key={coverageOption}
          >
            <Panel
              id={`policy-${policyNumber}-${coverageOption}`}
              title={Translations.policy_dec_codes[coverageOption]}
              collapsible
              isOpen={false}
            >
              <LinesOfBusinessFormsList
                policyNumber={policyNumber}
                coverageOption={coverageOption}
                selectedPolicy={selectedPolicy}
              />
            </Panel>
          </div>
        );
      })}
    </div>
  );
};

LinesOfBusiness.propTypes = propTypes;

export default LinesOfBusiness;
