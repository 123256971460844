import React from "react";
import ErrorView from "../ErrorView";
import errorLevel from "../../constants/errorLevel";
import { ErrorCodes } from "../../constants/errorCodes";
import { ContentHeader } from "@ufginsurance/ui-kit";

const NotFound = () => (
  <div>
    <ContentHeader>Not Found</ContentHeader>
    <ErrorView statusCode={ErrorCodes.NotFound} level={errorLevel.danger} />
  </div>
);

export default NotFound;
