import React from "react";
import PropTypes from "prop-types";
import PaperlessDetail from "./PaperlessDetail";
import PaperlessToggle from "./PaperlessToggle";
import { LoadingIndicator, Panel } from "@ufginsurance/ui-kit";
import { useSelector } from "react-redux";

const PaperlessAccountList = ({ accounts, userProfile }) => {
  const paperlessAccountListIsLoading = useSelector(
    state => state?.billingAndAlertsData?.isLoading
  );

  if (paperlessAccountListIsLoading) return <LoadingIndicator color="blue" />;

  return (
    <>
      {accounts.map(account => {
        return (
          <Panel
            id={`paperless-account-${account.number}`}
            key={account.number}
            title={
              <>
                <div className="paperless-account-header">{`Account #${account.number}`}</div>
                <PaperlessToggle account={account} userProfile={userProfile} />
              </>
            }
            isOpen
          >
            <PaperlessDetail account={account} />
          </Panel>
        );
      })}
    </>
  );
};

PaperlessAccountList.propTypes = {
  accounts: PropTypes.array.isRequired,
  userProfile: PropTypes.object
};

export default PaperlessAccountList;
