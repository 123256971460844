import "./polyfills";
import "url-polyfill";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { unregister } from "./registerServiceWorker";
import initializeStore from "./store";
import { logger } from "./logging";
import ScrollToTop from "./components/ScrollToTop";
import LaunchDarklyContainer from "./LaunchDarklyContainer";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";

// Unregister the service worker from create-react-app
unregister();

  const store = initializeStore();

logger.initialize(
  window.env.REACT_APP_GA_TRACKING_ID,
  window.env.REACT_APP_GA_DEBUG,
  window.env.REACT_APP_ENABLE_DIAGNOSTICS
);

if (
  window.decodeURIComponent(window.location.pathname) === "/force-logout" ||
  window.decodeURIComponent(window.location.pathname) === "//force-logout"
) {
  const url = `${window.env.REACT_APP_SSO_BASEURL}/Login?ApplicationID=${window.env.REACT_APP_SSO_CLIENT_ID}&ReturnUrl=%2Fsso&RedirectUrl=%2F`;
  window.location.replace(url);
} else {

  (async () => {
    const launchDarklyConfig = {
      clientSideID: window.env.REACT_APP_LAUNCHDARKLY_KEY,
      user: {
        key: "customerPortalUser"
      },
      options: {}
    };
    const LaunchDarklyProvider = await asyncWithLDProvider(launchDarklyConfig);
    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(
      
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop>
            <LaunchDarklyProvider>
              <LaunchDarklyContainer store={store}/>
            </LaunchDarklyProvider>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  })();
}
