import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchAutoIdCards } from "../actions/fetchAutoIdCardsActions";
import AutoIdCards from "../components/AutoIdCards";
import LoadingIndicator from "../components/loading-indicator";
import CustomErrorView from "../components/CustomErrorView";

class AutoIdCardsContainer extends Component {
  static propTypes = {
    fetchAutoIdCards: PropTypes.func.isRequired,
    autoIdCardsData: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.props.fetchAutoIdCards();
  }

  render() {
    const { data, isLoading, error } = this.props.autoIdCardsData;

    if (isLoading) {
      return <LoadingIndicator />;
    }

    if (!data && error) {
      return <CustomErrorView error={error} />;
    }

    return <AutoIdCards autoIdCardAccounts={data} />;
  }
}

const mapStateToProps = state => ({
  autoIdCardsData: {
    data: state.autoIdCardsData.data,
    error: state.autoIdCardsData.error,
    isLoading: state.autoIdCardsData.isLoading
  }
});

const mapDispatchToProps = {
  fetchAutoIdCards
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AutoIdCardsContainer);
