import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const myPolicyReducer = (state = initialState.myPolicyData, action) => {
  switch (action.type) {
    case types.FETCH_MY_POLICY_INFO_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_MY_POLICY_INFO_SUCCEEDED:
      return {
        ...state,
        policies: action.payload,
        isLoading: false
      };
    case types.FETCH_MY_POLICY_INFO_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.FETCH_POLICY_DECS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_POLICY_DECS_SUCCEEDED:
      return {
        ...state,
        coverageOptionsData: {
          ...state.coverageOptionsData,
          [action.payload.policyNumber]: action.payload.data
        },
        isLoading: false,
        error: null
      };
    case types.FETCH_POLICY_DECS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default myPolicyReducer;
