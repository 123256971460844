import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const policyBalanceRemainingReducer = (
  state = initialState.policyBalanceRemainingData,
  action
) => {
  switch (action.type) {
    case types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_SUCCEEDED:
      return {
        ...state,
        accountData: {
          ...state.accounts,
          [action.payload.accountNumber]: action.payload.data
        },
        isLoading: false,
        error: null
      };
    case types.FETCH_POLICY_BALANCE_REMAINING_DETAILS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default policyBalanceRemainingReducer;
