import * as types from "../constants/actionTypes";
import * as policyholderService from "../services/policyholderService";

const fetchMyPoliciesStarted = () => ({
  type: types.FETCH_MY_POLICY_INFO_STARTED
});

const fetchMyPoliciesSucceeded = data => ({
  type: types.FETCH_MY_POLICY_INFO_SUCCEEDED,
  payload: data
});

const fetchMyPoliciesFailed = error => ({
  type: types.FETCH_MY_POLICY_INFO_FAILED,
  payload: error,
  error: true
});

const fetchPolicyDecsStarted = () => ({
  type: types.FETCH_POLICY_DECS_STARTED
});

const fetchPolicyDecsSucceeded = (data, policyNumber) => ({
  type: types.FETCH_POLICY_DECS_SUCCEEDED,
  payload: { data, policyNumber }
});

const fetchPolicyDecsFailed = error => ({
  type: types.FETCH_POLICY_DECS_FAILED,
  payload: error,
  error: true
});

export const fetchMyPoliciesList = () => {
  return dispatch => {
    dispatch(fetchMyPoliciesStarted());

    return policyholderService.fetchMyPoliciesList().then(
      res => dispatch(fetchMyPoliciesSucceeded(res.data)),
      error => dispatch(fetchMyPoliciesFailed(error.response))
    );
  };
};

export const fetchPolicyDecs = (
  policyNumber,
  company_branch,
  effective_date
) => {
  return dispatch => {
    dispatch(fetchPolicyDecsStarted());

    return policyholderService
      .fetchPolicyDecs(policyNumber, company_branch, effective_date)
      .then(
        res => {
          dispatch(fetchPolicyDecsSucceeded(res.data, policyNumber));
        },
        error => {
          dispatch(fetchPolicyDecsFailed(error.response));
        }
      );
  };
};
