import * as types from "../constants/actionTypes";
import initialState from "./initialState";

const openClaimsReducer = (state = initialState.openClaimsData, action) => {
  switch (action.type) {
    case types.FETCH_OPEN_CLAIMS_STARTED:
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case types.FETCH_OPEN_CLAIMS_SUCCEEDED:
      return {
        ...state,
        isLoading: false,
        claims: {
          ...state.claims,
          [action.payload.number]: action.payload.data
        },
        error: null
      };
    case types.FETCH_OPEN_CLAIMS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
};

export default openClaimsReducer;
