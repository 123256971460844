export const securityQuestionValue = (securityQuestions, index, property) => {
  const securityQuestionsCount = !!securityQuestions
    ? securityQuestions.length
    : 0;
  return securityQuestionsCount > index
    ? securityQuestions[index][property]
    : "";
};

export const createFormValues = userProfile => {
  return {
    id: userProfile.id,
    username: userProfile.username,
    email: userProfile.email,
    confirmEmail: userProfile.email,
    phone: userProfile.phone,
    firstQuestion: securityQuestionValue(
      userProfile.security_questions,
      0,
      "question"
    ),
    firstAnswer: securityQuestionValue(
      userProfile.security_questions,
      0,
      "answer"
    ),
    secondQuestion: securityQuestionValue(
      userProfile.security_questions,
      1,
      "question"
    ),
    secondAnswer: securityQuestionValue(
      userProfile.security_questions,
      1,
      "answer"
    ),
    thirdQuestion: securityQuestionValue(
      userProfile.security_questions,
      2,
      "question"
    ),
    thirdAnswer: securityQuestionValue(
      userProfile.security_questions,
      2,
      "answer"
    )
  };
};

export const createUserProfile = (
  values,
  firstUserSecurityQuestionId,
  secondUserSecurityQuestionId,
  thirdUserSecurityQuestionId
) => {
  return {
    id: values.id,
    username: values.username,
    email: values.email,
    phone: values.phone,
    security_questions: [
      {
        id: firstUserSecurityQuestionId,
        question: values.firstQuestion,
        answer: values.firstAnswer
      },
      {
        id: secondUserSecurityQuestionId,
        question: values.secondQuestion,
        answer: values.secondAnswer
      },
      {
        id: thirdUserSecurityQuestionId,
        question: values.thirdQuestion,
        answer: values.thirdAnswer
      }
    ]
  };
};
