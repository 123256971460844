import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Translations } from "../translations";
import {
  Button,
  FlexRow,
  Form,
  FormGroup,
  Modal,
  RadioList,
  useForm
} from "@ufginsurance/ui-kit";
import {
  patchAcknowledgePaperlessTerms,
  submitEditProfile
} from "../../services/policyholderService";
import {
  postBillingAndAlerts,
  fetchBillingAndAlerts
} from "../../actions/billingAndAlertsAction";
import { fetchViewProfile } from "../../actions/viewProfileActions";
import * as routes from "../../constants/routes";
import { AnalyticCategories, AnalyticActions } from "../../constants/analytics";
import { logger } from "../../logging";
import { HelpDeskEmailLink } from "../email";
import { TermsAndConditions } from "../terms-and-conditions/PaperlessTermsAndConditions";

const PaperlessUpdateModal = ({
  hideModal,
  isNewAccount,
  payload,
  setIsNewModal,
  embeddedIn,
  postBillingAndAlerts,
  fetchBillingAndAlerts,
  fetchViewProfile
}) => {
  const SETTINGS_ERROR_MESSAGE =
    Translations.payments_alerts.paperless_billing.settings.error_message;
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(null);
  const [error, setError] = useState(null);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);

  const modalType =
    payload.name === "policy_email_alert" || payload.option === "no"
      ? "confirm"
      : "terms";

  const alerts = useSelector(state => state?.billingAndAlertsData?.alertsData);
  const userProfile = useSelector(state => state.profileData?.userProfile);

  const calcPossibleUserEmails = () => {
    const emails = (alerts || []).map(_ => _?.alert_contact_email);
    emails.push(userProfile?.email);
    return [...new Set(emails.filter(_ => _))];
  };

  const path =
    embeddedIn === "summary"
      ? routes.ACCOUNTS_ROUTE
      : routes.BILLING_AND_ALERTS_ROUTE;

  const trackAnalytics = details => {
    logger.event({
      category: AnalyticCategories.termsAndConditions,
      action: AnalyticActions.acceptPaperlessBillingAndEmailAlerts,
      label: JSON.stringify(details)
    });
  };

  const acknowledgePaperless = () => {
    if (isNewAccount) {
      patchAcknowledgePaperlessTerms(payload.number)
        .then(() => setIsNewModal())
        .catch(() => setError(SETTINGS_ERROR_MESSAGE));
    }
  };

  const handleProceedWithAcceptance = (email, forceRefresh = false) => {
    setIsSubmitting(true);
    trackAnalytics(payload);

    const complete = () => {
      if (userProfile) hideModal();
      setIsSubmitting(false);
    };

    postBillingAndAlerts({
      ...payload,
      terms_conditions_accepted: true,
      primary_contact_email: email
    })
      .then(
        () => {
          if (!!payload?.handler) payload.handler();
          if (!isNewAccount && path !== routes.ACCOUNTS_ROUTE) {
            history.push(path);
          } else {
            acknowledgePaperless();
          }
          if (forceRefresh)
            Promise.allSettled([fetchBillingAndAlerts(), fetchViewProfile()]);
        },
        error => console.log(error)
      )
      .finally(() => complete());
  };

  const onAccept = event => {
    event.stopPropagation();
    const emails = calcPossibleUserEmails();
    const alertEmails = (alerts || []).map(_ => _?.alert_contact_email);
    if (emails.length > 1 && payload?.option !== "no") {
      setIsEmailModalVisible(true);
    } else if (!alertEmails?.length) {
      handleProceedWithAcceptance(userProfile?.email);
    } else {
      handleProceedWithAcceptance("noupdate");
    }
  };

  const handleEmailSelection = ({ values }) => {
    setIsSubmitting(true);
    const selectedEmail = values?.email_address;

    const request = {
      ...userProfile,
      email: selectedEmail
    };
    submitEditProfile(request)
      .then(() => {
        handleProceedWithAcceptance(selectedEmail, true);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    setIsEmailModalVisible(false);
  };

  const onCancel = event => {
    event.stopPropagation();
    if (!isNewAccount && path !== routes.ACCOUNTS_ROUTE) {
      history.push(path);
    }
    acknowledgePaperless();
    if (userProfile) hideModal();
  };

  const visitBilling = event => {
    event.stopPropagation();
    setIsNewModal();
    history.push(path);
  };

  const onClose = event => {
    event.stopPropagation();
    setIsNewModal();
  };

  const form = useForm({
    values: { email_address: "" },
    onSubmit: handleEmailSelection
  });
  const { values, handleOnChange, handleOnBlur, handleOnValidate } = form;

  return (
    <div className="terms-and-conditions__container">
      <div>
        {error && (
          <p className="alert alert-danger">
            {!isNewAccount && (
              <>
                {`${error} `}
                {Translations.formatString(
                  Translations.error_messages.common_help,
                  <HelpDeskEmailLink />
                )}
              </>
            )}
            {isNewAccount && (
              <p className="newAccountAlert">
                <p>We are unable to update your settings at this time.</p>
                <Button
                  variant="tertiary"
                  className="alertButton"
                  isLink
                  onClick={visitBilling}
                >
                  Manage your paperless preferences
                </Button>
              </p>
            )}
          </p>
        )}
        {modalType === "terms" ? (
          <TermsAndConditions />
        ) : (
          <p>Are you sure you want to change this option?</p>
        )}
      </div>

      {error && (
        <div className="cancelRow">
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </div>
      )}

      {!error && (
        <FlexRow>
          <Button
            variant="primary"
            onClick={onAccept}
            disabled={!!error || isSubmitting}
            spinner={isSubmitting}
          >
            Accept
          </Button>
          <Button variant="secondary" onClick={onCancel}>
            {isNewAccount ? "Decline" : "Cancel"}
          </Button>
        </FlexRow>
      )}

      <Modal
        title="Email Address for Alerts:"
        show={isEmailModalVisible}
        // This is needed to stop event bubbling to the Panel.
        onClick={event => {
          event.stopPropagation();
        }}
        onHide={() => {
          setIsEmailModalVisible(false);
        }}
        altCloseMethod={false}
        body={
          <Form context={form}>
            <FormGroup>
              <RadioList
                id="email_address"
                name="email_address"
                label="Select email address:"
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                onValidate={handleOnValidate}
                value={values.email_address}
                options={calcPossibleUserEmails().map(_ => ({
                  value: _,
                  label: _
                }))}
                required
                className="select-email-address__radio-list"
              />
            </FormGroup>

            <FormGroup>
              <Button
                type="submit"
                variant="primary"
                disabled={!values?.email_address}
                spinner={isSubmitting}
              >
                Confirm Email
              </Button>
            </FormGroup>
          </Form>
        }
      />
    </div>
  );
};

PaperlessUpdateModal.propTypes = {
  hideModal: PropTypes.func,
  isNewAccount: PropTypes.bool,
  payload: PropTypes.object.isRequired,
  setIsNewModal: PropTypes.func,
  embeddedIn: PropTypes.string,
  postBillingAndAlerts: PropTypes.func.isRequired,
  fetchBillingAndAlerts: PropTypes.func.isRequired,
  fetchViewProfile: PropTypes.func.isRequired
};

export default connect(null, {
  postBillingAndAlerts,
  fetchBillingAndAlerts,
  fetchViewProfile
})(PaperlessUpdateModal);
