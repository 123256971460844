import { useState, useEffect } from "react";
import * as FullStory from "@fullstory/browser";

const {
  REACT_APP_FULLSTORY_DISABLE_EMPLOYEE_WHITELIST,
  REACT_APP_FULLSTORY_DISABLE,
  REACT_APP_FULLSTORY_ORGANIZATION_ID,
  REACT_APP_FULLSTORY_ENABLE_DEBUG,
  REACT_APP_FULLSTORY_ENABLE_DEVMODE
} = window.env;

const isDisabledForEmployee = employeeUsername => {
  const disabledEmployeeUsernames =
    REACT_APP_FULLSTORY_DISABLE_EMPLOYEE_WHITELIST?.split(" ") || [];
  const match =
    disabledEmployeeUsernames?.find(
      username =>
        employeeUsername &&
        username.toLowerCase() === employeeUsername.toLowerCase()
    ) || false;
  return !!match;
};

export const useFullStory = (
  authenticationToken,
  policyholderEmail,
  policyholderName,
  employeeUsername
) => {
  const [fullStoryInitialized, setFullStoryInitialized] = useState(false);
  useEffect(() => {
    if (!fullStoryInitialized && authenticationToken && policyholderName) {
      const disableFullStory = REACT_APP_FULLSTORY_DISABLE === "true";
      const isDisabled =
        disableFullStory || isDisabledForEmployee(employeeUsername);
      setFullStoryInitialized(true);
      if (!isDisabled) {
        const organizationId = REACT_APP_FULLSTORY_ORGANIZATION_ID;
        const enableDebug = REACT_APP_FULLSTORY_ENABLE_DEBUG === "true";
        const enableDevMode = REACT_APP_FULLSTORY_ENABLE_DEVMODE === "true";
        FullStory.init({
          orgId: organizationId,
          debug: enableDebug,
          devMode: enableDevMode
        });
        FullStory.identify(authenticationToken, {
          displayName: policyholderName,
          email: policyholderEmail
        });
      }
    }
  }, [
    fullStoryInitialized,
    authenticationToken,
    policyholderEmail,
    policyholderName,
    employeeUsername
  ]);
  return null;
};
