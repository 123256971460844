import React from "react";
import { Route } from "react-router-dom";
import AdSliderContainer from "../containers/AdSliderContainer";
import ProfileNav from "./my-profile/ProfileNav";
import BillingActivityNav from "./billing-activity/BillingActivityNav";
import ClaimsNav from "./claims/ClaimsNav";
import * as routes from "../constants/routes";
import MyAgentContainer from "../containers/MyAgentContainer";
import { SystemsAvailable } from "./system-availability";
import QuickLinksContainer from "../containers/QuickLinksContainer";

const SideBar = () => {
  return (
    <div>
      <Route path={routes.MY_PROFILE_ROUTE_ROOT} component={ProfileNav} />
      <Route
        path={routes.BILLING_ACTIVITY_TAB_ROOT_ROUTE}
        component={BillingActivityNav}
      />
      <Route path={routes.CLAIMS_ROUTE_ROOT} component={ClaimsNav} />
      <QuickLinksContainer />
      <SystemsAvailable>
        <MyAgentContainer />
      </SystemsAvailable>
      <AdSliderContainer delay={6000} />
    </div>
  );
};

export default SideBar;
